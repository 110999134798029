(function () {
    angular.module('IMasterApp')
        .controller('User_controller', User_controller)
        .controller('editUser_controller', editUser_controller)
        .controller('changeOwnPasswordController', changeOwnPasswordController)
        .directive('pwCheck', pwCheck);

    User_controller.$inject = ['$http', '$scope', '$window', 'siteurl', 'toasterService', 'userService'];
    function User_controller($http, $scope, $window, siteurl, toasterService, userService) {
        $scope.users = [];
        $scope.userPageTitle = "";
        getAllUser();

        $scope.newUser = function () {
            location.href = siteurl.editUser + "/0";
        };

        $scope.editUser = function (user) {
            location.href = siteurl.editUser + "/" + user.UserId;
        };

        $scope.deleteUser = function (user, index) {
            if (confirm("Biztos benne, hogy törli a felhasználót?")) {
                userService.deleteUserById(user.UserId).success(function () {
                    toasterService.successToast("Törlés", "Sikeres törlés!");
                    $scope.users.splice(index, 1);
                });
            }
        };

        function getAllUser() {
            userService.getAllUser().success(function (data) {
                $scope.users = data;
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };
    }

    editUser_controller.$inject = ['$http', '$scope', '$window', '$filter', 'siteurl', 'toasterService', 'userService', 'cashRegisterService'];
    function editUser_controller($http, $scope, $window, $filter, siteurl, toasterService, userService, cashRegisterService) {

        var userId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);

        if (userId != 0) {
            userService.getUserById(userId).success(function (data) {
                $scope.user = data;

                for (var index in data.CashRegisters) {
                    var obj = data.CashRegisters[index];
                    if (obj.Id === data.DefaultCashRegister.Id) {
                        $scope.user.DefaultCashRegister = obj;
                    }
                }

                for (var index2 in data.Warehouses) {
                    var obj2 = data.Warehouses[index2];
                    if (obj2.Id === data.DefaultWarehouse.Id) {
                        $scope.user.DefaultWarehouse = obj2;
                    }
                }

                //$scope.user.tags = [];
                //angular.forEach(data.Tags, function (group) {
                //    var inHere = false;
                //    angular.forEach($scope.user.tags, function(ttag) {
                //        if (ttag.text == group.text) {
                //            inHere = true;
                //        }
                //    });
                //    if (!inHere) {
                //        $scope.user.tags.push(group);
                //    }
                //});

                $scope.userPageTitle = "Felhasználó szerkesztése";
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        } else {
            $scope.userPageTitle = "Új felhasználó létrehozása";
            $scope.user = {
                UserId: 0,
                Username: '',
                Password: '',
                PasswordAgain: '',
                UserFirstname: '',
                UserLastname: '',
                UserEmail: '',
                Tags: [],
                Warehouses: [],
                CashRegisters: [],
                DefaultWarehouse: null,
                DefaultCashRegister: null,
            }
        }

        $scope.save = function () {
            var userData = angular.copy($scope.user);
            if (confirm("Biztos benne, hogy menti a felhasználót?")) {

                if (userId == 0) {
                    userData.Password = Sha1.hash(userData.Password);
                    userService.createUser(userData).success(function () {
                        toasterService.saveSuccess();
                        goBack();
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data);
                    });
                } else {
                    if (angular.isDefined(userData.Password) && userData.Password != null) {
                        if (userData.Password.length > 3) {
                            userData.Password = Sha1.hash(userData.Password);
                        }
                    }
                    userService.updateUser(userData).success(function () {
                        toasterService.successToast("Sikeres Mentés! \n Bizonyos változtatások csak a következő bejelentkezéskor fognak életbe lépni!");
                        goBack();
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data);
                    });
                }
            }
        };

        $scope.back = function () {
            if (confirm("Biztos benne, hogy elveti a módosításokat és visszalép?")) {
                goBack();
            }
        };

        function goBack() {
            location.href = siteurl.user;
        };

        $scope.loadUserGroup = function (query) {
            return userService.getAllUserGroup(query);
        };

        $scope.loadWarehouses = function (query) {
            return userService.getWarehousesByName(query);
        };

        $scope.warehouseTagAdded = function (tag) {
            if ($scope.user.DefaultWarehouse == null) {
                $scope.user.DefaultWarehouse = tag;
            }
        };

        $scope.warehouseTagRemoved = function (tag) {
            if ($scope.user.Warehouses.length === 0) {
                $scope.user.DefaultWarehouse = null;
            } else {
                if ($scope.user.DefaultWarehouse.Id = tag.Id) {
                    $scope.user.DefaultWarehouse = $scope.user.Warehouses[0];
                }
            }
        };

        $scope.loadCashRegisters = function (query) {
            return cashRegisterService.getAllCashRegister(query);
        };

        $scope.cashRegistersTagAdded = function (tag) {
            if ($scope.user.DefaultCashRegister == null) {
                $scope.user.DefaultCashRegister = tag;
            }
        };

        $scope.cashRegistersTagRemoved = function (tag) {
            if ($scope.user.CashRegisters.length === 0) {
                $scope.user.DefaultCashRegister = null;
            } else {
                if ($scope.user.DefaultCashRegister.Id = tag.Id) {
                    $scope.user.DefaultCashRegister = $scope.user.CashRegisters[0];
                }
            }
        };

    }

    changeOwnPasswordController.$inject = ['$http', '$scope', '$window', 'siteurl', 'toasterService', 'userService'];
    function changeOwnPasswordController($http, $scope, $window, siteurl, toasterService, userService) {
        var pwData;
        var load = function () {
            $scope.data = {
                userId: $window.sessionStorage.getItem('userid'),
                newPassword: "",
                oldPassword: ""
            }
            $scope.newPasswordAgain = "";
        };
        load();

        $scope.save = function () {
            if (confirm("Biztos hogy menti az új jelszót?")) {
                if ($scope.data.newPassword == "" || $scope.data.oldPassword == "" || $scope.newPasswordAgain == "") {
                    toasterService.warningToast("Figyelem!", "Nem töltött ki minden mezőt!");
                    return;
                }
                if ($scope.newPasswordAgain == $scope.data.oldPassword) {
                    toasterService.errorToast("Hiba!", "A régi és új jelszó megegyezik!");
                }
                if ($scope.newPasswordAgain != $scope.data.newPassword) {
                    toasterService.errorToast("Hiba!", "A két jelszó nem egyezik meg!");
                } else {
                    pwData = angular.copy($scope.data);
                    pwData.newPassword = Sha1.hash($scope.data.newPassword);
                    pwData.oldPassword = Sha1.hash($scope.data.oldPassword);
                    userService.changeOwnPassword(pwData).success(function () {
                        toasterService.successToast("Siker", "Sikeresen megváltoztatta jelszavát!");
                        window.location = siteurl.home;
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data);
                    });
                }
            }
        }
    }

    function pwCheck() {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                var firstPassword = '#' + attrs.pwCheck;
                elem.add(firstPassword).on('keyup', function () {
                    scope.$apply(function () {
                        var v = elem.val() === $(firstPassword).val();
                        ctrl.$setValidity('pwmatch', v);
                    });
                });
            }
        }
    }

})();
