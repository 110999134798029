(function () {

    angular.module('IMasterApp')
        .controller('productCategoryCtrl', productCategoryCtrl)
        .controller("editProductCategoryCtrl", editProductCategoryCtrl);

    productCategoryCtrl.$inject = ['$scope', '$http', 'siteurl', '$location', '$anchorScroll', 'apiurl', 'toasterService'];
    function productCategoryCtrl($scope, $http, siteurl, $location, $anchorScroll, apiurl, toasterService) {

        //A fa inicializálása
        var tree;
        $scope.isVisibleTable = true;
        $scope.treeArray = [];

        //A lapozáshoz szükséges
        var pageIndex = 0;
        $scope.pageSize = 25;

        //A lekért összes termékkategória
        var allProductCategory = [];

        //A keresőmező tartalma
        $scope.query = '';

        //Az aktuális tömb
        var actualProductCategory = [];

        //Az összes kategória lekérése
        $http.get(apiurl.productCategory.getAll).success(function (data) {
            $scope.doing_async = true;
            allProductCategory = data;
            allProductCategory.length > 0 ? $scope.zeroCategories = false : $scope.zeroCategories = true;
            $scope.doing_async = false;
            actualProductCategory = allProductCategory;
            loadProductCategories(actualProductCategory);
        });

        //A fa "újrarajzolása"
        var loadProductCategories = function (array) {

            $scope.treeArray = [];
            var totalItems = 0;
            angular.forEach(array, function (product) {
                if (product.ParentProductCategoryId == null) {
                    totalItems++;
                }
                $scope.totalItems = totalItems;
            });

            for (var i = 0 + (pageIndex * $scope.pageSize) ; i < array.length; i++) {
                if (array[i].ParentProductCategoryId == null) {
                    $scope.treeArray.push({ label: array[i].Name, data: { description: array[i].Id }, children: [] });
                }
                if ($scope.treeArray.length >= $scope.pageSize) {
                    break;
                }
            }

            var tempArray = [];
            var tempArray2 = [];

            for (var i = 0; i < $scope.treeArray.length; i++) {
                for (var j = 0; j < allProductCategory.length; j++) {
                    if ($scope.treeArray[i].data.description === allProductCategory[j].ParentProductCategoryId) {
                        tempArray.push({ label: allProductCategory[j].Name, data: { description: allProductCategory[j].Id }, children: [] });
                        find($scope.treeArray, allProductCategory[j].ParentProductCategoryId, allProductCategory[j]);
                    }
                }
            }

            while (tempArray.length !== 0) {
                tempArray2 = [];
                for (var i = 0; i < tempArray.length; i++) {
                    for (var j = 0; j < allProductCategory.length; j++) {
                        if (tempArray[i].data.description === allProductCategory[j].ParentProductCategoryId) {
                            tempArray2.push({ label: allProductCategory[j].Name, data: { description: allProductCategory[j].Id }, children: [] });
                            find($scope.treeArray, allProductCategory[j].ParentProductCategoryId, allProductCategory[j]);
                        }
                    }
                }
                tempArray = [];
                tempArray = tempArray2;
            }
        };

        $scope.my_tree = tree = {};
        $scope.my_tree_handler = function (branch) {
            $scope.isVisibleTable = false;

            angular.forEach(allProductCategory, function (product) {
                if (product.Name === branch.label)
                    $scope.selectedCategory = product;
            });

            $location.hash('top');
            $anchorScroll();
        };

        var searchInAllProductCategory = function (query) {
            $http.get(apiurl.productCategory.getAll + '/' + query).success(function (data) {
                $scope.treeArray = [];
                actualProductCategory = data;
                loadProductCategories(actualProductCategory);
            });
        };

        //A szülőnek keresi a gyerekét
        function find(array, id, product) {
            if (typeof array != 'undefined') {
                for (var i = 0; i < array.length; i++) {
                    if (array[i].data.description == id) {
                        array[i].children.push({ label: product.Name, data: { description: product.Id }, children: [] });
                        return;
                    }
                    find(array[i].children, id, product);
                }
            }
        }

        //A keresőmező figyelése
        var index = 0;
        $scope.$watch("query", function () {
            index++;
            if ($scope.query.length > 2) {
                searchInAllProductCategory($scope.query);
            }
            else if (index !== 1 && $scope.query === '') {
                actualProductCategory = allProductCategory;
                loadProductCategories(actualProductCategory);
            }
        });

        //Új termékkategória hozzáadása
        $scope.newProductCategory = function () {
            location.href = siteurl.editProductCategory + "/0";
        };

        //Termékkategória szerkesztése
        $scope.editProductCategory = function () {
            location.href = siteurl.editProductCategory + "/" + $scope.selectedCategory.Id;
        };

        //Termékkategória törlése
        $scope.deleteProductCategory = function () {
            if (confirm("Biztos benne, hogy törli a termékkategóriát?")) {
                $http.delete(apiurl.productCategory.deleteProductCategory + '/' + $scope.selectedCategory.Id).success(function (data) {
                    location.href = siteurl.productCategories;
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            }
        }

        //Ha változott az oldal mérete, akkor újratöltés
        $scope.changeSelected = function () {
            loadProductCategories(actualProductCategory);
        };

        //Ha lapozás történt, akkor újratöltés
        $scope.pageChanged = function () {
            pageIndex = $scope.current - 1;
            loadProductCategories(actualProductCategory);
        };
    }

    editProductCategoryCtrl.$inject = ['$scope', '$http', '$window', 'siteurl', 'apiurl', 'toasterService'];
    function editProductCategoryCtrl($scope, $http, $window, siteurl, apiurl, toasterService) {
        var productCategoryId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);

        $scope.isVisibleAddProducts = true;

        if (productCategoryId != 0) {
            $scope.isVisibleAddProducts = false;
            $scope.pageTitle = "Termékkategória módosítása";

            $http.get(apiurl.productCategory.getProductCategoryById + '/' + productCategoryId).success(function (data) {
                $scope.actProductCategory = data;
                console.log($scope.actProductCategory);

                getAllProd();
            });
        } else {
            $scope.pageTitle = "Új termékkategória létrehozása";
            $scope.actProductCategory = { Name: '', ParentProductCategoryId: null, RetailMargin: '', WholesaleMargin: '' };
            getAllProd();
        }

        function getAllProd() {
            $http.get(apiurl.productCategory.getAll).success(function (data) {
                $scope.selectableCategories = data;

                $scope.selectedParentProductCategory = $scope.selectableCategories[0];

                if (productCategoryId != 0) {
                    for (var i = 0; i < $scope.selectableCategories.length; i++) {
                        if ($scope.selectableCategories[i].Id == $scope.actProductCategory.Id) {
                            $scope.selectableCategories.splice(i, 1);
                        }
                    }

                    for (var i = 0; i < $scope.selectableCategories.length; i++) {
                        if ($scope.selectableCategories[i].Id == $scope.actProductCategory.ParentProductCategoryId) {
                            $scope.selectedParentProductCategory = $scope.selectableCategories[i];
                            $scope.selectVisible = true;
                        }
                    }
                }
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        }


        $scope.categoryChanged = function () {
            console.log($scope.selectedParentProductCategory.Name);
            $scope.actProductCategory.RetailMargin = $scope.selectedParentProductCategory.RetailMargin;
            $scope.actProductCategory.WholesaleMargin = $scope.selectedParentProductCategory.WholesaleMargin;
        }

        $scope.isNewProdCat = function () {
            return productCategoryId > 0 || ($scope.selectableCategories != null && productCategoryId == 0 && $scope.selectableCategories.length == 0) ? true : false;
        };

        $scope.backProductCategories = function () {
            location.href = siteurl.productCategories;
        }

        $scope.saveProductCategory = function () {
            if ($scope.selectVisible)
                $scope.actProductCategory.ParentProductCategoryId = $scope.selectedParentProductCategory.Id;
            else
                $scope.actProductCategory.ParentProductCategoryId = null;

            console.log($scope.actProductCategory);

            if (productCategoryId != 0) {
                $http.put(apiurl.productCategory.updateProductCategory, $scope.actProductCategory).success(function () {
                    $scope.backProductCategories();
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            } else {
                $http.post(apiurl.productCategory.saveProductCategory, $scope.actProductCategory).success(function () {
                    $scope.backProductCategories();
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            }
        };

        $scope.updateWithProducts = function () {
            if (confirm("Biztos hogy menti és updateli a termékkategóriákhoz tartozó termékek haszonkulcsát?")) {
                $http.put(apiurl.productCategory.updateProductsByProductsCategory, $scope.actProductCategory).success(function () {
                    alert("Sikeres mentés!");
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            }
        };

        // Handle products for category
        $scope.bindToProducts = function () {
            location.href = siteurl.editProductsForCategory + "/" + productCategoryId;
        };
    }

})();
