(function () {
    angular.module("ApiServices", [])
        .service("OrderRequestService", OrderRequestService)
        .service("CostCenterService", CostCenterService)
        .service("warehouseService", warehouseService)
        .service("OrdersService", OrdersService)
        .service('userGroupService', userGroupService)
        .service('userService', userService)
        .service('cashRegisterService', cashRegisterService)
        .service('taxRateService', taxRateService)
        .service('manufacturingScheduleTweakService', manufacturingScheduleTweakService);

    OrderRequestService.$inject = ['$http', 'apiurl', '$window'];
    function OrderRequestService($http, apiurl, $window) {
        this.GetOrderRequests = function (filter) {
            return $http.post(apiurl.orderRequest.getOrderRequests + '/' + $window.sessionStorage.getItem("userid"), filter);
        };

        this.GetOrderRequest = function (orderRequestId) {
            return $http.get(apiurl.orderRequest.getOrderRequest + orderRequestId);
        };

        this.CreateOrderRequest = function (data) {
            return $http.post(apiurl.orderRequest.createOrderRequest, data);
        };

        this.UpdateOrderRequest = function (data) {
            return $http.put(apiurl.orderRequest.updateOrderRequest + "/" + data.userId, data.orderRequest);
        };

        this.GetActiveWarehouses = function (warehouseName) {
            return $http.get(apiurl.orderRequest.getWarehouses + $window.sessionStorage.getItem('userid') + "/" + warehouseName);
        };

        this.GetCustomers = function (companyName) {
            return $http.get(apiurl.stock.getCompanies + "/" + companyName + "/" + "false");
        };

        this.GetCostCenterByName = function (costCenterName) {
            return $http.get(apiurl.stock.getCostCenterByName + costCenterName);
        };

        this.GetStatuses = function () {
            return $http.get(apiurl.orderRequest.getStatuses);
        };

        this.GetProducts = function (productName) {
            var params = {
                params: { productName: productName }
            };
            return $http.get(apiurl.orderRequest.getProductsFromProductStock, params);
        };

        this.OrderFromOrderRequests = function (orderRequestList) {
            return $http.post(apiurl.orderRequest.orderFromOrderRequests + "/" + $window.sessionStorage.getItem('userid'), orderRequestList);
        };

        this.CountedLimit = function (limitParameter) {
            return $http.post(apiurl.orderRequest.countedLimit, limitParameter);
        };
    }

    CostCenterService.$inject = ['$http', 'apiurl'];
    function CostCenterService($http, apiurl) {
        this.GetCostCenterByName = function (costCenterName) {
            return $http.get(apiurl.stock.getCostCenterByName + costCenterName);
        };
    }

    warehouseService.$inject = ['$http', 'apiurl'];
    function warehouseService($http, apiurl) {
        //Összes raktár lekérése
        this.getAllWarehouse = function () {
            return $http.get(apiurl.warehouse.getAllWarehouse);
        };

        //Raktár lekérése id alapján
        this.getWarehouseById = function (warehouseId) {
            return $http.get(apiurl.warehouse.getWarehouseById + warehouseId);
        };

        //Raktár létrehozása
        this.createWarehouse = function (data) {
            return $http.post(apiurl.warehouse.createWarehouse, data);
        };

        //Raktár módosítása
        this.updateWarehouse = function (data) {
            return $http.post(apiurl.warehouse.updateWarehouse, data);
        };

        //raktár törlése
        this.deleteWarehouse = function (warehouseId) {
            return $http.delete(apiurl.warehouse.deleteWarehouse + warehouseId);
        };

        this.getOwnerCompanyAddresses = function () {
            return $http.get(apiurl.warehouse.getOwnerCompanySites);
        };
    }

    OrdersService.$inject = ['$http', 'apiurl'];
    function OrdersService($http, apiurl) {
        // Beszállítóra szűrés név alapján
        this.getCompaniesByName = function (companyName) {
            return $http.get(apiurl.order.getCompaniesByName + companyName);
        };

        // Raktárra szűrés név alapján
        this.getWarehousesByName = function (warehouseName) {
            return $http.get(apiurl.order.getWarehousesByName + warehouseName);
        };

        // Státuszok lekérése
        this.getStatuses = function () {
            return $http.get(apiurl.order.getStatuses);
        };

        // Beszállítóhoz tartozó termékek lekérése
        this.getProductsOfSupplier = function (searchObj) {
            return $http.post(apiurl.order.getProductsOfSupplier, searchObj);
        };

        // Megrendelési bizonylat visszanyitása
        this.reOpenOrderById = function (orderId) {
            return $http.get(apiurl.order.reOpenOrderById + '/' + orderId);
        };

        // Megrendelési bizonylat mentése
        this.saveOrder = function (order) {
            return $http.post(apiurl.order.saveOrder, order);
        };

        // Megrendelési bizonylat updatelése
        this.updateOrder = function (order) {
            return $http.put(apiurl.order.updateOrder, order);
        };

        // Megrendelési bizonylatok lekérése lapozóval
        this.getOrdersWithPager = function (filter) {
            return $http.post(apiurl.order.getOrdersWithPager, filter);
        };

        // Megrendelési bizonylat updatelése nyomtatáshoz
        this.updateOrderForPrint = function (orderId) {
            return $http.post(apiurl.order.updateOrderForPrint + '/' + orderId);
        };
    }

    userGroupService.$inject = ['$http', 'apiurl'];
    function userGroupService($http, apiurl) {
        // Összes felhasználói csoport lekérdezése
        this.getAllUserGroup = function () {
            return $http.get(apiurl.user.getAllUserGroup);
        };

        // Felhasználói csoport törlése
        this.deleteUserGroupById = function (userGroupId) {
            return $http.delete(apiurl.user.deleteUserGroupById + userGroupId);
        };

        // Új felhasználói csoport létrehozása
        this.createUserGroup = function (data) {
            return $http.post(apiurl.user.createUserGroup, data);
        };

        // Felhasználói csoport frissítése
        this.updateUserGroup = function (data) {
            return $http.post(apiurl.user.updateUserGroup, data);
        };

        // Felhasználói csoport lekérdezése Id alapján
        this.getUserGroupById = function (userGroupId) {
            return $http.get(apiurl.user.getUserGroupById + userGroupId);
        };

        // Felhasználói jogok lekérdezése megadott szöveg alapján
        this.getAllUserRole = function (query) {
            return $http.get(apiurl.user.getAllUserRole + query);
        };
    }

    userService.$inject = ['$http', 'apiurl'];
    function userService($http, apiurl) {
        // Felhasználó törlése Id alapján
        this.deleteUserById = function (userId) {
            return $http.delete(apiurl.user.deleteUserById + userId);
        };

        // Összes felhasználó lekérése
        this.getAllUser = function () {
            return $http.get(apiurl.user.getAllUser);
        };

        // Felhasználó létrehozása
        this.createUser = function (data) {
            return $http.post(apiurl.user.createUser, data);
        };

        // Felhasználó frissítése
        this.updateUser = function (data) {
            return $http.post(apiurl.user.updateUser, data);
        };

        // Felhasználó lekérése Id alapján
        this.getUserById = function (userId) {
            return $http.get(apiurl.user.getUserById + userId);
        };

        // Felhasználói jogok lekérdezése név alapján
        this.getAllUserGroup = function (query) {
            return $http.get(apiurl.user.getAllUserGroup + query);
        };

        this.getWarehousesByName = function (warehouseName) {
            var url = apiurl.user.getWarehousesByName;
            return $http.get(url + warehouseName);
        };

        // Jelszó megváltoztatása
        this.changeOwnPassword = function (data) {
            return $http.post(apiurl.user.changeOwnPassword, data);
        };

        // Felhasználók lekérdezése név alapján
        this.getUserListByUserName = function (query) {
            return $http.get(apiurl.user.getUserListByUserName + query);
        };

        // Felhasználók lekérdezése Id lista alapján
        this.getUserListByUserIdList = function (list) {
            return $http.post(apiurl.user.getUserListByUserIdList, list);
        };
    }

    cashRegisterService.$inject = ['$http', 'apiurl'];
    function cashRegisterService($http, apiurl) {
        // Pénztárak lekérdezése megadott szöveg alapján
        this.getAllCashRegister = function (query) {
            return $http.get(apiurl.cashRegister.getAllCashRegister + query);
        };

        // Pénztár törlése Id alapján
        this.deleteCashRegisterById = function (cashRegId) {
            return $http.delete(apiurl.cashRegister.deleteCashRegisterById + cashRegId);
        };

        // Pénztár lekérése Id alapján
        this.getCashRegisterById = function (cashRegId) {
            return $http.get(apiurl.cashRegister.getCashRegisterById + cashRegId);
        };

        // Pénztár létrehozása
        this.createCashRegister = function (data) {
            return $http.post(apiurl.cashRegister.createCashRegister, data);
        };

        // Pénztár frissítése
        this.updateCashRegister = function (data) {
            return $http.post(apiurl.cashRegister.updateCashRegister, data);
        };
    }

    taxRateService.$inject = ['$http', 'apiurl'];
    function taxRateService($http, apiurl) {
        // Összes áfakulcs lekérése
        this.getAllTaxRate = function () {
            return $http.get(apiurl.taxRateController.getAllTaxRate);
        };

        // Áfakulcs lekérése Id alapján
        this.getTaxRateById = function (taxId) {
            return $http.get(apiurl.taxRateController.getTaxRateById + taxId);
        };

        // Áfakulcs létrehozása
        this.createTaxRate = function (data) {
            return $http.post(apiurl.taxRateController.createTaxRate, data);
        };

        // Áfakulcs frissítése
        this.updateTaxRate = function (data) {
            return $http.post(apiurl.taxRateController.updateTaxRate, data);
        };

        // Áfakulcs törlése
        this.deleteTaxRate = function (taxId) {
            return $http.delete(apiurl.taxRateController.deleteTaxRateById + taxId);
        };
    }

    manufacturingScheduleTweakService.$inject = ['$http', 'apiurl'];
    function manufacturingScheduleTweakService($http, apiurl) {
        this.scheduleManufacturing = function (voucherId) {
            return $http.post(apiurl.manufacturingSchedule.scheduleManufacturing + '/' + voucherId);
        };

        this.deleteScheduledManufacturing = function (voucherId) {
            return $http.delete(apiurl.manufacturingSchedule.deleteScheduledManufacturing + '/' + voucherId);
        };

        this.getManufacturingProcessesByProductId = function (productId) {
            return $http.get(apiurl.manufacturingSchedule.getManufacturingProcessesByProductId + '/' + productId);
        }

        this.getProbableEndingDateOfManufacturing = function (voucherId) {
            return $http.get(apiurl.manufacturingSchedule.getProbableEndingDateOfManufacturing + '/' + voucherId);
        }
    }

})();
