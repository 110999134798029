(function () {

    angular.module('IMasterApp')
        .controller('remoteDeviceController', remoteDeviceController);

    remoteDeviceController.$inject = ['$scope', '$rootScope', '$http', '$filter', '$window', 'siteurl', 'apiurl', 'toasterService'];
    function remoteDeviceController($scope, $rootScope, $http, $filter, $window, siteurl, apiurl, toasterService) {
        var remoteDeviceId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1); //eszköz id-ja
        if (remoteDeviceId == "remoteDevice" || remoteDeviceId == 0) return;

        var backUrl = (/url=([^&=]+)/g).exec(location.search);
        backUrl = backUrl !== null ? backUrl[1] : siteurl.remoteDevice + '/' + remoteDeviceId;
        var today = new Date();
        $rootScope.isColor = true;

        //eszköz adatai
        $scope.actRemoteDevice = {
            Id: "",
            Name: "",
            SerialNumber: "",
            IDNR: "",
            BlackCounter: null,
            ColorCounter: null,
            Cyan: 0,
            Magenta: 0,
            Yellow: 0,
            Black: 0,
            CyanInterChange: null,
            MagentaInterchange: null,
            YellowInterchange: null,
            BlackInterchange: null,
            LastServiceDate: "",
            NextServiceDate: "",
            FamilyId: 0,
            FamilyName: ""
        };
        $scope.remoteServices = [];

        //Eszköz lekérés
        $http.get(apiurl.remoteDevice.getRemoteDeviceDetail + remoteDeviceId).success(function (data) {
            $scope.actRemoteDevice = data;
            if (data.CyanInterchange != null) {
                $scope.actRemoteDevice.CyanInterchange = today.setDate(today.getDate() + data.CyanInterchange);
                today = new Date();
            }
            if (data.MagentaInterchange != null) {
                $scope.actRemoteDevice.MagentaInterchange = today.setDate(today.getDate() + data.MagentaInterchange);
                today = new Date();
            }
            if (data.YellowInterchange != null) {
                $scope.actRemoteDevice.YellowInterchange = today.setDate(today.getDate() + data.YellowInterchange);
                today = new Date();
            }
            if (data.BlackInterchange != null) {
                $scope.actRemoteDevice.BlackInterchange = today.setDate(today.getDate() + data.BlackInterchange);
                today = new Date();
            }
            $scope.isColor = !(data.Cyan == null && data.Magenta == null && data.Yellow == null);
            $rootScope.isColor = $scope.isColor;
        });

        //aktuális eszközhöz tartozó szervizek lekérdezése
        $http.get(apiurl.remoteDevice.getRemoteServicesToDevice + remoteDeviceId).success(function (data) {
            $scope.remoteServices = data;
        });

        $scope.AddRemoteService = function () {
            location.href = siteurl.editRemoteService + '/?IDNR=' + $scope.actRemoteDevice.IDNR + '?url=' + backUrl + '/0';
        };

        $scope.editRemoteService = function (remoteServiceId) {
            location.href = siteurl.editRemoteService + '?url=' + backUrl + '/' + remoteServiceId;
        };

        //Visszalépés
        $scope.backPage = function () {
            location.href = siteurl.remoteDevices;
        };

        // New DatePicker settings
        $scope.today = function () {
            $scope.prdate2 = new Date();
            $scope.prdate2 = $filter('date')($scope.prdate2, 'yyyy.MM.dd');
        };
        $scope.today();

        $scope.clear = function () {
            $scope.prdate2 = null;
        };

        $scope.prdate2format = "yyyy.MM.dd";

        $scope.datePickers = {
            fromDateOfRemoteService: { isOpen: false, date: "" },
            toDateOfRemoteService: { isOpen: false, date: "" }
        };

        $scope.dtopen = function ($event, datePicker) {
            $event.preventDefault();
            $event.stopPropagation();

            if (!datePicker) {
                $scope.dtopened = true;

            } else {
                datePicker.isOpen = true;
            }
        };

        $scope.dtoptions = {
            startingDay: 1
        };
    }

})();
