(function () {

    angular.module('IMasterApp')
        .controller("ExcelReportController", ExcelReportController);

    ExcelReportController.$inject = ['$scope', '$http', 'toasterService', 'apiurl'];
    function ExcelReportController($scope, $http, toasterService, apiurl) {

        // Alapértelmezett rendezés
        $scope.predicate = 'ExcelReportCreationDate';
        $scope.reverse = true;

        var loadExcelReports = function () {
            $scope.ExcelReports = [];
            $http.get(apiurl.excelReport.getExcelReports).success(function (data) {
                $scope.ExcelReports = data;
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };
        loadExcelReports();

        $scope.generate = function (excelReport) {
            location.href = apiurl.excelReport.generateExcelReport + '/' + excelReport.Id;
        };
    }

})();
