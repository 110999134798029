(function () {

    angular.module('IMasterApp')
        .controller('LifePath_LifeOfProduct_controller', LifePath_LifeOfProduct_controller)
        .filter("DirectionFilter", DirectionFilter);

    LifePath_LifeOfProduct_controller.$inject = ['$scope', '$http', 'siteurl', 'apiurl', 'toasterService'];
    function LifePath_LifeOfProduct_controller($scope, $http, siteurl, apiurl, toasterService) {
        $scope.siteurl = siteurl;

        var backUrl = (/url=([^&=]+)/g).exec(location.search);
        backUrl = backUrl !== null ? backUrl[1] : siteurl.stock;

        $scope.actUrl = siteurl.lifeOfProduct;

        if (location.href.indexOf(siteurl.editPartner) !== -1) {
            $scope.actUrl = siteurl.editPartner;
            var partnerId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
            if (location.href.indexOf("fromIsSupplierOfPartner") !== -1) {
                var commonSupplierOfPartner = window.location.href.substring(window.location.href.indexOf("?fromIsSupplierOfPartner=") + 25, window.location.href.indexOf("/?productId=").valueOf()); //beszállító termékei fülről jövünk-e
            }
            var dataPl = window.location.href.substring(window.location.href.indexOf("?productId=") + 11, window.location.href.indexOf("?url=").valueOf());
        } else {
            var dataPl = window.location.href.substring(window.location.href.indexOf("?productId=") + 11, window.location.href.indexOf("/?warehouseId="));
            var warehouseId = window.location.href.substring(window.location.href.indexOf("?warehouseId=") + 13);
        }

        $scope.LifePath = [];
        $scope.actProductName = '';
        $scope.isStrict = false;
        $scope.Identifier = false;

        var filterParams = {
            ProductId: dataPl,
            WarehouseId: warehouseId,
            CompanyId: $scope.actUrl == siteurl.editPartner ? partnerId : null,
            IsSupplierOfCompany: $scope.actUrl == siteurl.editPartner && commonSupplierOfPartner == "true" ? true : false
        };

        $http.post(apiurl.lifeOfProduct.getLifeOfProduct, filterParams).success(function (data) {
            if (!data || !data.length) {
                return;
            }

            if (data[0].StrictList != null && data[0].StrictList.length > 0) {
                $scope.isStrict = true;
                $scope.SetStricts(data);
            }

            var i, len = data.length;
            for (i = 0; i < len; i++) {
                if (data[i].Identity != null) {
                    $scope.Identifier = true;
                    break;
                }
            }
            $scope.LifePath = data;
            $scope.actProductName = data[0].ProductName;
        }).error(function (data) {
            toasterService.errorFromServerToast(data);
        });

        $scope.backButtonClick = function () {
            window.location.href = backUrl;
        }

        $scope.SetStricts = function (data) {
            angular.forEach(data, function (prod) {
                var st = '';
                var prevEndNumber = 0;
                angular.forEach(prod.StrictList, function (str) {
                    if (st === '') {
                        st += str.Prefix + ": " + str.StartNumberOfRange + " - ";
                        prevEndNumber = str.EndNumberOfRange;
                    } else if (Number(prevEndNumber) + 1 == Number(str.StartNumberOfRange)) {
                        prevEndNumber = str.EndNumberOfRange;
                    } else {
                        st += prevEndNumber + '\n' + str.Prefix + ": " + str.StartNumberOfRange + " - ";
                        prevEndNumber = str.EndNumberOfRange;
                    }
                });
                st += prevEndNumber;
                prod.Stricts = st;
            });
        };
    }

    function DirectionFilter() {
        var warehouseId = parseInt(window.location.href.substring(window.location.href.indexOf("?warehouseId=") + 13));

        return function (input) {
            if (input.MovementNumber.slice(0, 2) === 'LT') {
                return 'Leltár';
            } else if (input.DirectionOfMovement && input.IsSendedInterWarehouse === null) {
                return 'Bevétel';
            } else {
                if (!input.DirectionOfMovement && input.IsSendedInterWarehouse === null) {
                    if (input.MovementNumber.slice(0, 2) === 'KF') {
                        return 'Kiadás Felhasználásra';
                    }
                    else {
                        return 'Kiadás';
                    }
                }
                else {
                    if (input.IsSendedInterWarehouse !== null && input.ReceiverWarehouseId === warehouseId) {
                        return 'Fogadott raktárközi mozgás';
                    }
                    else {
                        if (warehouseId === 0) {
                            return 'Raktárközi mozgás';
                        }
                        else {
                            return 'Elküldött raktárközi mozgás';
                        }
                    }
                }
            }
        }
    }

})();
