(function () {

    angular.module('IMasterApp')
        .controller('OwnerCompaniesListController', OwnerCompaniesListController)
        .controller('EditOwnerCompanyCtrl', EditOwnerCompanyCtrl);

    OwnerCompaniesListController.$inject = ['$http', '$scope', 'siteurl', 'apiurl', 'toasterService'];
    function OwnerCompaniesListController($http, $scope, siteurl, apiurl, toasterService) {
        $scope.hasCompany = false;

        $http.get(apiurl.ownerCompany.getOwnerCompanies).success(function (data) {
            $scope.owners = data;

            $scope.hasCompany = $scope.owners.length > 0 ? true : false;

        }).error(function (data, status) {
            toasterService.errorFromServerToast(data);
        });

        $scope.setSelectedOwner = function (owner) {
            location.href = siteurl.editOwnerCompany + "/" + owner.OwnerCompanyDatas.Id;
        };

        $scope.deleteOwnerCompany = function (index, owner) {
            if (confirm("Valóban törölni akarja?")) {
                $http.delete(apiurl.ownerCompany.delete + owner.OwnerCompanyDatas.Id).success(function () {
                    $scope.owners.splice(index, 1);
                }).error(function (data, status) {
                    toasterService.errorFromServerToast(data);
                });
            }
        };

        $scope.addOwnerCompany = function () {
            location.href = siteurl.editOwnerCompany + "/0";
        };

        $scope.isHaveHome = function (index) {
            if ($scope.owners[index].Home === null)
                return true;

            return false;
        }

        $scope.isAnySite = function (index) {
            if ($scope.owners[index].Sites === null || $scope.owners[index].Sites.length === 0)
                return true;

            return false;
        }
    }

    EditOwnerCompanyCtrl.$inject = ['$http', '$scope', 'siteurl', 'apiurl', 'FileUploader', 'toasterService'];
    function EditOwnerCompanyCtrl($http, $scope, siteurl, apiurl, FileUploader, toasterService) {
        var ownerId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
        $scope.isNewOwner = false;
        $scope.hasLogo = false;
        $scope.tabClick = 'ownerCompanyData';

        $scope.homeList = [];
        $scope.siteList = [];
        $scope.bankAccounts = [];
        $scope.fileObj = {
            ImageDto: []
        };

        if (ownerId != '0') {
            $http.get(apiurl.ownerCompany.edit + ownerId).success(function (data) {
                $scope.actOwnerCompany = data.OwnerCompanyDatas;
                $scope.bankAccounts = data.BankAccounts;
                $scope.OpeningHours = data.OpeningHours;
                //Saját céghez tartozó fileok beállítása
                if (data.Files.length > 0) {
                    angular.forEach(data.Files, function (value, key) {
                        value.FileName = generateGetFileUrl(ownerId, value.FileName);
                        $scope.fileObj.ImageDto.push(value);
                    });
                    $scope.hasLogo = true;
                }

                if (data.Home != null)
                    $scope.homeList.push(data.Home);

                $scope.siteList = data.Sites;

                $scope.PageTitle = $scope.actOwnerCompany.LongName;

            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });
        } else {
            $scope.isNewOwner = true;
            $scope.PageTitle = "Új saját cég";
            $scope.actOwnerCompany = {
                LongName: '',
                ShortName: '',
                RegistrationNumber: '',
                TaxNumber: '',
                EuTaxNumber: '',
                AnnualRevenue: 0,
                Website: ''
            };
        }
        // Székhely kezelése
        $scope.addHome = function () {
            var newHome = { ZipCode: '', City: '', Address: '', Comment: '' };
            $scope.homeList.push(newHome);
            newHome.Visibility = true;
        };

        $scope.delHome = function (index) {
            $scope.homeList.splice(index, 1);
        };

        $scope.editHome = function (home) {
            home.Visibility = true;
        };

        $scope.saveHome = function (home) {
            home.Visibility = false;
        };

        // Telephelyek kezelése
        $scope.addSite = function () {
            var newSite = { ZipCode: '', City: '', Address: '', Comment: '', Warehouses: [], CashRegisters: [] };
            $scope.siteList.push(newSite);
            newSite.Visibility = true;
        };

        $scope.delSite = function (index) {
            $scope.siteList.splice(index, 1);
        };

        $scope.editSite = function (site) {
            site.Visibility = true;
        };

        $scope.saveSite = function (site) {
            site.Visibility = false;
        };

        // Raktárak és telephelyek megjelenítéséhez függvények
        $scope.hasHome = function () {
            return $scope.homeList.length === 0;
        };

        $scope.hasCommentHome = function (index) {
            return ($scope.homeList[index].Comment === null || $scope.homeList[index].Comment === "") ? "-" : "";
        };

        $scope.hasCommentSite = function (index) {
            return ($scope.siteList[index].Comment === null || $scope.siteList[index].Comment === "") ? "-" : "";
        };

        $scope.isSiteListEmpty = function (index) {
            if ($scope.siteList[index].Warehouses.length === 0 && $scope.siteList[index].CashRegisters.length === 0 && $scope.siteList[index].Visibility === false) {
                return true;
            } else {
                return false;
            }
        };

        // Bankszámlák kezelése
        $scope.setBankAccount = function () {
            $scope.actBankName = '';
            $scope.actFirstEightNumber = '';
            $scope.actSecondEightNumber = '';
            $scope.actThirdEightNumber = '';
        };

        $scope.saveBankAccount = function () {
            var bankAccNum = $scope.actFirstEightNumber + "-" + $scope.actSecondEightNumber + "-" + $scope.actThirdEightNumber;

            if ($scope.actBankName === undefined || $scope.actBankName === '') {
                toasterService.warningToast("Figyelem", "A banknév megadása kötelező!");
            }
            else if (bankAccNum.match(/^[1-9][0-9]{7}-[0-9]{8}-[0-9]{8}$/i) === null) {
                toasterService.warningToast("Figyelem", "Hibás bankszámlaszám formátum!\nA bankszámlaszám csak számokat tartalmazhat és mindhárom blokk 8 számjegyből kell álljon!");
            }
            else {
                var newBankAccount = {
                    BankName: $scope.actBankName,
                    FirstEightNumber: $scope.actFirstEightNumber,
                    SecondEightNumber: $scope.actSecondEightNumber,
                    ThirdEightNumber: $scope.actThirdEightNumber
                };
                $scope.bankAccounts.push(newBankAccount);
                $scope.setBankAccount();
            }

        };

        $scope.deleteBankAccount = function (index) {
            $scope.bankAccounts.splice(index, 1);
        };

        // Raktárak kezeléses
        var reservedWarehouses = [];

        $scope.loadWarehouses = function (site) {
            console.log("load hívás!!!");

            var siteId = site.Id;
            if (site.Id === undefined)
                siteId = 0;

            reservedWarehouses = [];
            angular.forEach($scope.siteList, function (item) {
                for (var i = 0; i < item.Warehouses.length; i++) {
                    var index = reservedWarehouses.indexOf(item.Warehouses[i]);
                    if (index == -1) {
                        reservedWarehouses.push(item.Warehouses[i]);
                    }
                }
            });

            return $http.post(apiurl.ownerCompany.getFreeWarehouses, reservedWarehouses, { params: { siteId: siteId, deletableWarehouses: reservedWarehouses } }).success(function (data) {
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };

        // Pénztárak kezelése
        var reservedCashRegisters = [];

        $scope.loadCashRegisters = function (site) {
            console.log("CashRegister load hívás!!!");

            var siteId = site.Id;
            if (site.Id === undefined)
                siteId = 0;

            reservedCashRegisters = [];

            angular.forEach($scope.siteList, function (item) {
                for (var i = 0; i < item.CashRegisters.length; i++) {
                    var index = reservedCashRegisters.indexOf(item.CashRegisters[i]);

                    if (index == -1) {
                        reservedCashRegisters.push(item.CashRegisters[i]);
                    }
                }
            });

            return $http.post(apiurl.ownerCompany.getFreeCashRegisters, reservedCashRegisters, { params: { siteId: siteId, deletableCashRegisters: reservedCashRegisters } }).success(function (data) {
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };

        // Gombok kezelése
        $scope.backOwnerCompanies = function () {
            if ($scope.tabClick === 'logo') {
                location.href = siteurl.ownerCompanies;
            } else {
                if (confirm("Valóban ki akar lépni mentés nélkül?")) {
                    location.href = siteurl.ownerCompanies;
                }
            }
        };

        $scope.saveOwnerCompany = function () {
            console.log("Mentés előtt:");
            console.log($scope.actOwnerCompany);
            console.log($scope.bankAccounts);
            console.log($scope.homeList);
            console.log($scope.siteList);
            console.log($scope.fileObj.ImageDto);

            var ownerData;
            var homeData;

            if ($scope.homeList.length != 0) {
                homeData = $scope.homeList[0];
            } else {
                homeData = null;
            }

            ownerData = { OwnerCompanyDatas: $scope.actOwnerCompany, BankAccounts: $scope.bankAccounts, Home: homeData, Sites: $scope.siteList, Files: $scope.fileObj.ImageDto, OpeningHours: $scope.OpeningHours };

            if (!$scope.isNewOwner) {
                ownerData.OpeningHours.OwnerCompanyId = ownerData.OwnerCompanyDatas.Id;

                $http.put(apiurl.ownerCompany.update, ownerData).success(function () {
                    location.href = siteurl.ownerCompanies;
                }).error(function (data, status) {
                    toasterService.errorFromServerToast(data);
                });
            } else {
                console.log(ownerData);

                $http.post(apiurl.ownerCompany.save, ownerData).success(function () {
                    location.href = siteurl.ownerCompanies;
                }).error(function (data, status) {
                    toasterService.errorFromServerToast(data);
                });
            }
        };

        // LOGO FELTÖLTÉS //
        //Fájl lekérdezése a feltöltés után
        function generateGetFileUrl(ownerCompanyId, fileName) {
            return apiurl.ownerCompany.getFileUrl + '?ownerCompanyId=' + ownerCompanyId + '&fileName=' + fileName;
        }

        var uploadFilter = {
            name: 'imageFilter',
            fn: function (item /*{File|FileLikeObject}*/, options) {
                var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                return "jpg|png|jpeg|bmp|gif|".indexOf(type) !== -1;
            }
        };

        //A hozzáadott kép szélességének és magasságának ellenőrzése
        var CheckAddingFileDimensions = function (item, cb) {
            var width = 0;
            var height = 0;

            var reader = new FileReader();

            reader.onload = onLoadFile;

            if (item._file) {
                reader.readAsDataURL(item._file);
            } else {
                toasterService.warningToast("", "Váratlan hiba történt!");
                $scope.$apply(function () {
                    item.remove();
                });
            }

            function onLoadFile(event) {
                var img = new Image();
                img.onload = onLoadImage;
                img.src = event.target.result;
            }

            function onLoadImage() {
                width = this.width
                height = this.height;

                if (height > 50 || width > 180) {
                    toasterService.warningToast("Figyelem", "A hozzáadott kép magassága és szélessége nem megfelelő!\n180x50 pixel méretű képfilet tölthet fel!");
                    $scope.$apply(function () {
                        item.remove();
                    });
                }
            }
        };

        //Fájl feltöltés alapbeállítása
        function createUploader() {
            var uploader = new FileUploader({
                url: apiurl.ownerCompany.uploadFileUrl //url cím megadása
            });
            uploader.filters.push(uploadFilter);

            //Sikeresen hozzáadtuk fileokat az uploaderhez(callback metódus)
            uploader.onAfterAddingFile = function (item) {
                if ($scope.actOwnerCompany == null) {
                    toasterService.warningToast("Figyelem", "Hiba történt a saját cég betöltése közben!");
                    item.remove();
                }
                if ($scope.hasLogo == true) {
                    toasterService.warningToast("Figyelem", "Csak egy logo-t tölthet fel!");
                    item.remove();
                }
                if ($scope.fileObj.uploader.queue.length > 1) {
                    toasterService.warningToast("Figyelem", "Csak egy logo-t tölthet fel!");
                    item.remove();
                }
                CheckAddingFileDimensions(item);
                console.info('onAfterAddingFile', item);
                item.formData.push({
                    OwnerCompanyId: ownerId,
                    Name: "",
                    FileName: null
                });
            };

            //Sikertelenül hozzáadtuk fileokat az uploaderhez(callback metódus)
            uploader.onWhenAddingFileFailed = function (item, filter, options) {
                toasterService.warningToast("Figyelem", "Nem támogatott file típus!");
            }

            return uploader;
        }

        $scope.fileObj.uploader = createUploader();

        //Sikeres volt a feltöltés (callback metódus)
        $scope.fileObj.uploader.onSuccessItem = function (item, response, status, headers) {
            response.FileName = generateGetFileUrl(response.OwnerCompanyId, response.FileName);
            $scope.fileObj.ImageDto.push(response);
            $scope.hasLogo = true;
            item.remove();
        };
        //Sikertelen volt a feltöltés (callback metódus)
        $scope.fileObj.uploader.onErrorItem = function (item, response, status, headers) {
            toasterService.errorFromServerToast(response);
        };

        //File törlés
        $scope.DeleteFile = function (fileId) {
            if (!confirm("Biztos hogy törli a Logo-t?")) {
                return;
            }

            function success(data) {
                angular.forEach($scope.fileObj.ImageDto, function (value, key) {
                    if (value.Id === data.Id) {
                        $scope.fileObj.ImageDto.splice(key, 1);
                        $scope.hasLogo = false;
                    }
                });
            }
            function error(data, status) {
                toasterService.errorFromServerToast(data);
            }

            $http.delete(apiurl.ownerCompany.deleteFileUrl + fileId)
            .success(success).error(error);
        };
    }

})();
