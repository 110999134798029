var productTypes = [{
    "Id": 26,
    "Name": "Normál termék",
    "Short": "N"
}, {
    "Id": 27,
    "Name": "Szolgáltatás",
    "Short": "Sz"
}, {
    "Id": 28,
    "Name": "Szigorú számadású termék",
    "Short": "Szig"
}, {
    "Id": 39,
    "Name": "Egyedi azonosítójú termék",
    "Short": "EA"
}];

var getDefaultOrFirstIfAny = function (defaultObj, list) {
    for (var index in list) {
        var obj = list[index];
        if (!defaultObj || obj.Id === defaultObj.Id) {
            return obj;
        }
    }

    return null;
};