(function () {

    angular.module('IMasterApp')
        .controller("SalesCertificationCtrl", SalesCertificationCtrl);

    SalesCertificationCtrl.$inject = ['$scope', '$http', '$filter', '$location', '$window', 'siteurl', 'apiurl', 'toasterService'];
    function SalesCertificationCtrl($scope, $http, $filter, $location, $window, siteurl, apiurl, toasterService) {
        $scope.siteurl = siteurl; // kell a view-nak!

        var pdf = (/pdf=([^&=]+)/g).exec(location.search);
        if (pdf !== null) {
            window.location.href = apiurl.stock.generateDocument + pdf[1];
        }

        var partnerId = 0;
        $scope.actUrl = siteurl.salesCertification;

        if (location.href.indexOf(siteurl.debit) !== -1) {
            $scope.actUrl = siteurl.debit;
        }
        else if (location.href.indexOf(siteurl.editPartner) !== -1) {
            $scope.actUrl = siteurl.editPartner;
            partnerId = window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1);

            if (partnerId == 'EditPartner') {
                return;
            }
        }

        $scope.currentPage = 0;
        $scope.pageSize = 25;
        $scope.actualPage = 0;
        $scope.selectedPageSize = 2;
        $scope.RoundToDecimal = 2;

        //Datepickers definitions
        $scope.format = "yyyy.MM.dd";

        $scope.openedDp = [];
        $scope.openDatePicker = function ($event, index) {
            $event.preventDefault();

            $scope.openedDp[index] = true;
        };
        $scope.DateOptions = {
            startingDay: 1
        };

        $scope.pressEnter = function (ev) {
            if (ev.which == 13) {
                $scope.useFilter();
            }
        };

        $scope.filter = {
            buyerName: '',
            InvoiceNumber: '',
            fromNetPrice: '',
            toNetPrice: '',
            fromTaxAmount: '',
            toTaxAmount: '',
            fromGrossPrice: '',
            toGrossPrice: '',
            fromFulfillmentDate: '',
            toFulfillmentDate: '',
            fromDeadlineDate: '',
            toDeadlineDate: '',
            fromIssueDate: '',
            toIssueDate: '',
            statusWorkingVersion: false,
            statusRevenue: false,
            statusIssued: $scope.actUrl === siteurl.debit ? true : false,
            statusCancelled: false,
            typeInvoice: $scope.actUrl === siteurl.debit ? true : false,
            typeVoucher: false,
            typeDeliveryNote: false,
            typeVoucherCancellation: false,
            typeBid: false,
            typeCustomerOrder: false,
            outGoingDebitOfBilled: $scope.actUrl === siteurl.debit ? true : false, //kimenő tartozásos számlák
            isPaper: false
        };

        $scope.searchObj = { sortIndex: "", orderDirection: true };

        function createFilterData() {
            return {
                buyerName: $scope.filter.buyerName,
                buyerId: partnerId, //Partner id-ja
                InvoiceNumber: $scope.filter.InvoiceNumber, //Számla sorszám
                fromNetPrice: $scope.filter.fromNetPrice, //Nettó összeg-től
                toNetPrice: $scope.filter.toNetPrice, //Nettó összeg-ig
                fromTaxAmount: $scope.filter.fromTaxAmount, //Áfa összeg-től
                toTaxAmount: $scope.filter.toTaxAmount, //Áfa összeg-ig
                fromGrossPrice: $scope.filter.fromGrossPrice, //Bruttó összeg-től
                toGrossPrice: $scope.filter.toGrossPrice, //Bruttó összeg-ig
                fromFulfillmentDate: $filter('date')($scope.filter.fromFulfillmentDate, 'yyyy.MM.dd'), //Teljesítés dátumá-tól
                toFulfillmentDate: $filter('date')($scope.filter.toFulfillmentDate, 'yyyy.MM.dd'),  //Teljesítés dátumá-ig
                fromDeadlineDate: $filter('date')($scope.filter.fromDeadlineDate, 'yyyy.MM.dd'),  //Fizetési határidő-től
                toDeadlineDate: $filter('date')($scope.filter.toDeadlineDate, 'yyyy.MM.dd'), //Fizetési határidő-ig
                fromIssueDate: $filter('date')($scope.filter.fromIssueDate, 'yyyy.MM.dd'), //Kiállítás dátuma-tól
                toIssueDate: $filter('date')($scope.filter.toIssueDate, 'yyyy.MM.dd'), //Kiállítás dátuma-ig
                statusWorkingVersion: $scope.filter.statusWorkingVersion, // Munkaverzió
                statusRevenue: $scope.filter.statusRevenue, // Bevételi
                statusIssued: $scope.filter.statusIssued, // Kiállított
                statusCancelled: $scope.filter.statusCancelled, // Sztornózott
                typeInvoice: $scope.filter.typeInvoice, // Számla
                typeVoucher: $scope.filter.typeVoucher, // Nyugta
                typeDeliveryNote: $scope.filter.typeDeliveryNote, // Szállító levél
                typeVoucherCancellation: $scope.filter.typeVoucherCancellation, // Sztornó számla
                typeBid: $scope.filter.typeBid, // Árajánlat
                typeCustomerOrder: $scope.filter.typeCustomerOrder, // Vevői megrendelés
                outGoingDebitOfBilled: $scope.filter.outGoingDebitOfBilled, //kimenő tartozásos számlák
                sortIndex: $scope.searchObj.sortIndex, // A rendezéshez szükséges oszlop neve
                orderDirection: $scope.searchObj.orderDirection, // A rendezéshez szükséges irány
                isPaper: $scope.filter.isPaper //papír alapú számla
            };
        }

        //Adatlekérés és szűrés
        $scope.getVoucherList = function (sortIndex) {
            if (!angular.isUndefined(sortIndex)) {
                if (sortIndex === $scope.searchObj.sortIndex) {
                    $scope.searchObj.orderDirection = !$scope.searchObj.orderDirection;
                }
                else {
                    $scope.searchObj.orderDirection = true;
                    $scope.searchObj.sortIndex = sortIndex;
                }
            }
            var filter = createFilterData();

            $http.post(apiurl.voucher.getVoucherListWithPager + $scope.currentPage + '/' + $scope.pageSize, filter)
            .success(function (data) {
                $scope.Vouchers = data;
                $scope.totalItems = data.TotalItems;
                CheckInvoiceList(data.VoucherList);
                if ($scope.actUrl === siteurl.editPartner) {
                    $scope.filter.buyerName = $scope.$parent.$parent.PageTitle;
                }
            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });
        }

        function getVoucherListSum() {
            function success(data) {
                $scope.sumEqualizedNetValue = data.FullfilledItemsNetValue;
                $scope.sumEqualizedVatValue = data.FullfilledItemsVatValue;
                $scope.sumEqualizedGrosValue = data.FullfilledItemsGrossValue;

                $scope.sumNotEqualizedNetValue = data.NonFullfilledItemsNetValue;
                $scope.sumNotEqualizedVatValue = data.NonFullfilledItemsVatValue;
                $scope.sumNotEqualizedGrosValue = data.NonFullfilledItemsGrossValue;

                $scope.sumTotalNetValue = data.SumNetValue;
                $scope.sumTotalVatValue = data.SumVatValue;
                $scope.sumTotalGrosValue = data.SumGrossValue;
                $scope.sumTotalProfitValue = data.NetProfitContent;
                $scope.sumTotalDebitValue = data.ValueOfDebt;
            }

            function error(data, status) {
                toasterService.errorFromServerToast(data);
            }

            $http.post(apiurl.voucher.getVoucherListSum, createFilterData())
            .success(success)
            .error(error);
        }

        $scope.useFilter = function () {
            $scope.getVoucherList();
            getVoucherListSum();
        };

        angular.element(document).ready(function () {
            $scope.useFilter();
        });

        $scope.deleteSearch = function () {
            $scope.filter = { statusIssued: $scope.actUrl === siteurl.debit ? true : false, typeInvoice: $scope.actUrl === siteurl.debit ? true : false, outGoingDebitOfBilled: $scope.actUrl === siteurl.debit ? true : false };
            $scope.getVoucherList();
            getVoucherListSum();
        }

        //Lapozás 
        $scope.pageChanged = function () {
            $scope.currentPage = $scope.current - 1;
            $scope.getVoucherList();
        };

        //Számlák számának beállítása 1 oldalon
        $scope.changeSelected = function () {
            if ($scope.selectedPageSize > 0) {
                $scope.pageSize = $scope.selectedPageSize;
                $scope.currentPage = 0;
                $scope.getVoucherList();
            }
        };

        //Email sablon létrehozása
        $scope.CreateEmailTemplate = function () {
            window.location.href = siteurl.editEmailTemplate;
        };

        //Email küldése
        $scope.SendEmail = function () {
            window.location.href = siteurl.sendEmail;
        };

        $scope.openInvoice = function (index) {
            window.location.href = siteurl.tweak + "/" + index.VoucherId + '?url=' + $scope.actUrl;
        };

        //Számlák vizsgálata(kiegyenlített, nem kiegyenlített, lejárt árajánlat)
        function CheckInvoiceList(VoucherList) {
            var actDate = new Date();
            angular.forEach(VoucherList, function (invoice, key) {
                if ((invoice.VoucherStatus !== "Kiállított" && invoice.VoucherType !== 'Árajánlat') || (invoice.VoucherType === "Sztornó számla" && invoice.VoucherStatus === "Kiállított")) {
                    return;
                }
                //Kiállított számlák vizsgálata
                if (invoice.VoucherType == 'Számla' && invoice.VoucherStatus == 'Kiállított') {
                    //átutalásos számlák
                    if (invoice.PaymentTypeId === 21) {
                        var inPaymentsAmount = 0; //Befizetések
                        for (var i = 0; i < invoice.DepositAmountsFromInvoice.length; i++) {
                            inPaymentsAmount += invoice.DepositAmountsFromInvoice[i].Amount;
                        }
                        //ha egyezik, akkor kiegyenlített a számla
                        if (Number(inPaymentsAmount.toFixed(0)) === Number(invoice.GrossPrice.toFixed(0))) {
                            $scope.Vouchers.VoucherList[key].paymentStatusClass = "EqualizedInvoice"; //zöld szín, jelenleg nem kell(kiszedve a cssből)
                        } else {
                            //Aznap lejártak
                            if ($filter('date')(invoice.DeadlineDate, 'yyyy.MM.dd') === $filter('date')(actDate, 'yyyy.MM.dd')) {
                                $scope.Vouchers.VoucherList[key].paymentStatusClass = "ActDeadlineDate";
                                //lejárt számlák
                            } else if ($filter('date')(invoice.DeadlineDate, 'yyyy.MM.dd') < $filter('date')(actDate, 'yyyy.MM.dd')) {
                                $scope.Vouchers.VoucherList[key].paymentStatusClass = "notInsideDeadlineDate";
                            }
                        }
                    } else if (invoice.PaymentTypeId == 20) {   //készpénzes számlák
                        $scope.Vouchers.VoucherList[key].paymentStatusClass = "EqualizedInvoice"; //zöld szín, jelenleg nem kell(kiszedve a cssből)
                        //minden más fizetésü számla
                    }
                }

                //Ellenőrizzük árajánlatnál az érvényességi határidőt
                var toDay = $filter('date')(actDate, 'yyyy.MM.dd');
                invoice.ValidityDeadline = invoice.ValidityDeadline != null ? $filter('date')(invoice.ValidityDeadline, 'yyyy.MM.dd') : null;
                if (invoice.VoucherType === 'Árajánlat' && invoice.VoucherStatus !== 'Teljesített' && invoice.ValidityDeadline != null && invoice.ValidityDeadline < toDay) {
                    $scope.Vouchers.VoucherList[key].paymentStatusClass = "ExpiredBid";
                }
            });
        }
    }

})();
