(function () {

    angular.module('IMasterApp')
        .controller('PartnerListController', PartnerListController)
        .controller('EditPartnerController', EditPartnerController)
        .controller('EditDiscountController', EditDiscountController);

    PartnerListController.$inject = ['$http', '$scope', '$timeout', '$filter', 'siteurl', 'apiurl', 'toasterService'];
    function PartnerListController($http, $scope, $timeout, $filter, siteurl, apiurl, toasterService) {
        $scope.currentPage = 0;
        $scope.pageSize = 25;
        $scope.actualPage = 0;
        $scope.query = '';
        $scope.groupList = [];

        var searchObj = { SortIndex: '', OrderDirection: true };

        $scope.getAllPartnerWithPager = function (sortIndex) {
            if (!angular.isUndefined(sortIndex)) {
                if (sortIndex === searchObj.SortIndex) {
                    searchObj.OrderDirection = !searchObj.OrderDirection;
                }
                else {
                    searchObj.OrderDirection = true;
                    searchObj.SortIndex = sortIndex;
                }
            }

            var selectedPartnerGroupsIds = [];
            for (var i = 0, len = $scope.groupList.length; i < len; i++) {
                var item = $scope.groupList[i];

                if (item && item.IsSelected) {
                    selectedPartnerGroupsIds.push(item.Id);
                }
            }

            var filter = {
                PageSize: $scope.pageSize,
                PageIndex: $scope.currentPage,
                Query: $scope.query,
                OrderDirection: searchObj.OrderDirection,
                SortIndex: searchObj.SortIndex,
                SelectedPartnerGroups: selectedPartnerGroupsIds
            };

            $http.post(apiurl.partner.getAllCompanyWithPager, filter).success(function (data) {
                $scope.partners = data.PartnerList;
                angular.forEach($scope.partners, function (partner, key) {
                    //Ha van lejárt számlatartozása
                    if (partner.IsExpiredDebit == true) {
                        $scope.partners[key].TotalDebitAmount = "notInsideDeadlineDate";
                    }
                });
                $scope.pageSize = data.ItemsPerPage;
                $scope.totalPages = data.TotalPages;
                $scope.totalItems = data.TotalItems;
            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });
        };

        $scope.pageChanged = function () {
            $scope.currentPage = $scope.current - 1;
            $scope.getAllPartnerWithPager();
        };

        $scope.changeSelected = function () {
            $scope.currentPage = 0;
            $scope.current = 0;
            $scope.getAllPartnerWithPager();
        };

        $scope.changedQuery = function () {
            if ($scope.query.length > 2) {
                $scope.getAllPartnerWithPager();
            }
            else if ($scope.query === '') {
                $scope.getAllPartnerWithPager();
            }
        };


        $http.get(apiurl.partner.getGroups)
            .success(function (data) {
                $scope.groupList = data;

                // Visszaállítjuk a választógombok állapotát
                $scope.restoreSelectedGroups();

                // Betöltjük az alapadatokat
                $scope.changedQuery();
            })
            .error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });

        $scope.groupChange = function (selectedGroup) {
            // Betöltjük a szűrésnek megfelelő adatokat
            $scope.changedQuery();
        };

        // Csoportválasztó állapotának lementése
        $scope.saveSelectedGroups = function () {
            var selectedPartnerGroupsIds = [];
            for (var i = 0, len = $scope.groupList.length; i < len; i++) {
                var item = $scope.groupList[i];

                if (item && item.IsSelected) {
                    selectedPartnerGroupsIds.push(item.Id);
                }
            }

            sessionStorage.setItem('partnersSelectedGroupIdList', JSON.stringify(selectedPartnerGroupsIds));
        };

        // Csoportválasztó állapotának visszaállítása
        $scope.restoreSelectedGroups = function () {
            var jsonItems = sessionStorage.getItem('partnersSelectedGroupIdList'); // string

            if (jsonItems) {
                var selectedItems = JSON.parse(jsonItems);

                // Visszaállítjuk a választógombok állapotát
                for (var i = 0, len = selectedItems.length; i < len; i++) {
                    var item = $filter('filter')($scope.groupList, { Id: selectedItems[i] });

                    if (item && item[0]) {
                        item[0].IsSelected = true;
                    }
                }

                sessionStorage.removeItem('partnersSelectedGroupIdList');
            } else {
                // Ha nincsenek lementett értékek, akkor az összeset beállítjuk
                for (var j = 0, len2 = $scope.groupList.length; j < len2; j++) {
                    $scope.groupList[j].IsSelected = true;
                }
            }
        };

        //Functions
        $scope.AddPartner = function () {
            $scope.saveSelectedGroups();
            location.href = siteurl.editPartner + '/0';
        };

        $scope.setSelectedPartners = function (partner) {
            $scope.saveSelectedGroups();
            location.href = siteurl.editPartner + "/" + partner.Id;
        };

        $scope.editPartners = function (partner) {
            $scope.saveSelectedGroups();
            location.href = siteurl.editPartner + "/" + partner.Id;
        };

        $scope.deleteParnter = function (index, partner) {
            if (confirm("Valóban törölni akarja?")) {
                $http.delete(apiurl.partner.deleteCompany + '/' + partner.Id).success(function () {
                    $scope.partners.splice(index, 1);
                }).error(function (data, status) {
                    toasterService.errorFromServerToast(data);
                });
            }
        };
    }

    EditPartnerController.$inject = ['$http', '$rootScope', '$scope', 'CurrentCreditLineCounter', '$window', 'siteurl', '$filter', 'apiurl', 'toasterService', 'Contract.ListDataService', '$q'];
    function EditPartnerController($http, $rootScope, $scope, CurrentCreditLineCounter, $window, siteurl, $filter, apiurl, toasterService, contractListdataSrvc, $q) {
        var partnerId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
        var partnerPreviousState = '';

        //Alapértelmezett beállítások
        $scope.EuroCreditLineIsCollapsed = false; //Az euro biztosított hitelkeret elrejtése
        $scope.IsNewPartner = true;
        $scope.tabClick = { value: 'partners' };
        $scope.partnerStatuses = [];
        $scope.actpartner = {
            Id: 0,
            ShortName: '',
            LongName: '',
            TaxNumber: '',
            EUTaxNumber: '',
            RegistrationNumber: '',
            DaysOfDelivery: 0,
            PaymentName: 'Készpénz',
            TypeIdOfPayment: 20,
            PaymentDeadline: '',
            Currency: 'Ft',
            IsWholesale: false,
            HufCreditLine: '',
            EuroCreditLine: '',
            StatusId: 0,
            PartnerHomeAddress: '',
        };

        //Aktuáis hitelkeret kiszámolása
        $scope.currentCreditValue = 0;

        if (!isNaN(partnerId) && partnerId > 0) {
            $scope.IsNewPartner = false;
        } else {
            $scope.PageTitle = "Új partner";
        }

        //Csoportok
        $scope.supplierVisibility = false;
        $scope.clientVisibility = false;
        $scope.AddressList = [];
        $scope.BankAccuntList = [];
        $scope.ContactList = [];
        $scope.Comment = "";

        // Szerződések fül
        if (!$scope.IsNewPartner) {
            contractListdataSrvc.setCompanyId(partnerId);
            contractListdataSrvc.pagination.PageSize = 1000;
            contractListdataSrvc.setDataOrdering('EndDate');
        }


        setScopeFunctions();


        //Betöltődött az egész oldal
        $q.all(getDependences()).then(function () {
            savePartnerState();
        });

        //------------ FUNCION DEFINITIONS -----------
        function setScopeFunctions() {
            //Átszámolás
            $scope.Calculate = function (ISONAME) {
                if ($scope.exchangeRate == null) {
                    alert("Az aktuális árfolyam letöltése sikertelen!");
                    return;
                }
                if (ISONAME === "EUR") {
                    $scope.actpartner.HufCreditLine = Number($scope.actpartner.EuroCreditLine * $scope.exchangeRate).toFixed(0);
                } else {
                    $scope.actpartner.EuroCreditLine = Number($scope.actpartner.HufCreditLine / $scope.exchangeRate).toFixed(0);
                }
            };

            //Mentések
            $scope.saveAddress = function () {


                var district = $scope.actpartner.actAddress.District.length > 0 ? ' ' + $scope.actpartner.actAddress.District + ' ker. ' : '';
                var building = $scope.actpartner.actAddress.Building.length > 0 ? ' ' + $scope.actpartner.actAddress.Building + ' ép.' : '';
                var stairway = $scope.actpartner.actAddress.Stairway.length > 0 ? ' ' + $scope.actpartner.actAddress.Stairway + ' lép.' : '';
                var floor = $scope.actpartner.actAddress.Floor.length > 0 ? ' ' + $scope.actpartner.actAddress.Floor + ' em.' : '';
                var door = $scope.actpartner.actAddress.Door.length > 0 ? ' ' + $scope.actpartner.actAddress.Door : '';

                var newAddress = {
                    TypeId: $scope.actpartner.actAddressType.Id,
                    TypeName: $scope.actpartner.actAddressType.Name,
                    IsDeleted: false,
                    Zip: $scope.actpartner.actAddress.Zip,
                    City: $scope.actpartner.actAddress.City,
                    District: $scope.actpartner.actAddress.District,
                    PubliclyOwnedLandName: $scope.actpartner.actAddress.PubliclyOwnedLandName,
                    PubliclyOwnedLandType: $scope.actpartner.actAddress.PubliclyOwnedLandType,
                    HouseNumber: $scope.actpartner.actAddress.HouseNumber,
                    Building: $scope.actpartner.actAddress.Building,
                    Stairway: $scope.actpartner.actAddress.Stairway,
                    Floor: $scope.actpartner.actAddress.Floor,
                    Door: $scope.actpartner.actAddress.Door,

                    FullAddress: $scope.actpartner.actAddress.Zip + ' ' + $scope.actpartner.actAddress.City + ', ' + district + 
                        $scope.actpartner.actAddress.PubliclyOwnedLandName + ' ' + $scope.actpartner.actAddress.PubliclyOwnedLandType + ' ' + $scope.actpartner.actAddress.HouseNumber
                        +building + stairway + floor + door
            };

                $scope.AddressList.push(newAddress);
                setAddress();
            };

            $scope.saveBankAccount = function (name, first, second, third) {
                var bankAccNum = String(first) + "-" + String(second) + "-" + String(third);

                if (name === undefined || name === '') {
                    toasterService.warningToast("Figyelem", "A banknév megadása kötelező!");
                }
                else if (bankAccNum.match(/^[1-9][0-9]{7}-[0-9]{8}-[0-9]{8}$/i) === null) {
                    toasterService.warningToast("Figyelem", "Hibás bankszámlaszám formátum!\nA bankszámlaszám csak számokat tartalmazhat és mindhárom blokk 8 számjegyből kell álljon!");
                }
                else {
                    var newBankAccount = {
                        BankName: name, BankAccount: bankAccNum
                    };
                    $scope.BankAccuntList.push(newBankAccount);
                    setBackAccount();
                }
            };

            $scope.savePartner = function () {
                if (confirm("Valóban el akarja menteni?")) {
                    if (isSupplierOfPartner($scope.GroupList, $scope.actpartner.TaxNumber)) {
                        toasterService.errorToast("Csoport hiba", "Az adószám kitöltése kötelező!");
                        return;
                    }
                    if ($scope.actpartner.HufCreditLine < 0) {
                        toasterService.errorToast("Hitelkeret hiba", "A biztosított hitelkeret összege nem lehet negatív érték!");
                        return;
                    }

                    if ($scope.actpartner.HufCreditLine > 999999999999999) {
                        toasterService.errorToast("Hitelkeret hiba", "A biztosított hitelkeret összege már a csillagokban jár!");
                        return;
                    }

                    if (!angular.isUndefined($scope.actpartner.EuroCreditLine) && $scope.actpartner.EuroCreditLine != "") {
                        if ($scope.actpartner.EuroCreditLine < 0) {
                            toasterService.errorToast("Hitelkeret hiba", "Az euró hitelkeret összege nem lehet negatív érték!");
                            return;
                        }

                        if ($scope.actpartner.EuroCreditLine > 999999999999999) {
                            toasterService.errorToast("Hitelkeret hiba", "A euró hitelkeret összege már a csillagokban jár!");
                            return;
                        }
                    }

                    if ($scope.actpartner.PaymentDeadline == "" && $scope.actpartner.TypeIdOfPayment == 21) {
                        toasterService.errorToast("Fizetési határidő hiba", "Átutalásos fizetési módnál a fizetési határidő megadása kötelező!");
                        return;
                    }

                    if ($scope.actpartner.PaymentDeadline <= 0 && $scope.actpartner.PaymentDeadline != "" && $scope.actpartner.PaymentDeadline != null) {
                        toasterService.errorToast("Fizetési határidő hiba", "A fizetési határidő csak pozitív szám lehet!");
                        return;
                    }

                    // A székhely kitöltésének ellenőrzése
                    if (addressIsEmpty($scope)) {
                        toasterService.errorToast("Székhely hiba", "A székhely kitöltése kötelező!");
                        return;
                    }

                    if (!angular.isUndefined($scope.actpartner.TaxNumber) && $scope.actpartner.TaxNumber != '') {
                        $http.get(apiurl.partner.existTaxNumberOtherPartner + '/' + $scope.actpartner.TaxNumber + '/' + $scope.actpartner.Id).success(function (data) {
                            if (data.m_Item1) {
                                if (confirm("A '" + data.m_Item2 + "' nevű partner már tartalmazza a következő adószámot: " + $scope.actpartner.TaxNumber
                                        + "\nEnnek ellenére folytatja?")) {
                                    savePartnerMethodContinue();
                                }
                            } else {
                                savePartnerMethodContinue();
                            }
                        }).error(function (data) {
                            toasterService.errorFromServerToast(data);
                        });
                    } else {
                        savePartnerMethodContinue();
                    }
                }
            };

            $scope.BackPartners = function () {
                if (stateChangedOfPartner() && !confirm("Valóban ki akar lépni mentés nélkül?")) {
                    return;
                }
                if ($rootScope.isFromModal) {
                    $rootScope.isFromModal = false;
                    $scope.$parent.$uibModalInstance.close();
                } else {
                    location.href = siteurl.partners;
                }
            };

            //Törlések
            $scope.deleteAddress = function (index) {
                $scope.AddressList[index].IsDeleted = true;
            };
            $scope.deleteBankAccount = function (index) {
                $scope.BankAccuntList.splice(index, 1);
            };

            //Kapcsolat kezelés
            $scope.addContact = function () {
                var newContact = {
                    Name: '', EmailAddresses: [], PhoneNumbers: []
                };
                $scope.ContactList.push(newContact);
                newContact.Visibility = true;
            };
            $scope.delContact = function (contact, index) {
                if (!confirm('Biztosan törli a kapcsolatot?')) {
                    return;
                }
                var canDeleteContact = true;
                angular.forEach($scope.ContactInteractionList, function (value, key) {
                    if (value.ContactId == contact.Id) {
                        canDeleteContact = false;
                    }
                });
                angular.forEach($scope.ComplainList, function (value, key) {
                    if (value.Complain.ContactId == contact.Id) {
                        canDeleteContact = false;
                    }
                });
                if (canDeleteContact) {
                    $scope.ContactList.splice(index, 1);
                } else {
                    toasterService.warningToast("Figyelem", "A kapcsolattartó nem törölhető, mert tartozik hozzá kapcsolatfelvétel/reklamáció!");
                }
            };
            $scope.EditContacts = function (contact) {
                contact.Visibility = true;
            };
            $scope.SaveContacts = function (contact) {
                //Üres elemek törlése
                var telephoneSpliceList = [];
                for (var i = 0; i < contact.PhoneNumbers.length; i++) {
                    if (contact.PhoneNumbers[i] === '') {
                        telephoneSpliceList.push(i);
                    }
                }

                $scope.index = 0;
                for (var j = telephoneSpliceList.length; j > 0; j--) {
                    var t = telephoneSpliceList[j - 1];
                    contact.PhoneNumbers.splice(t, 1);
                    $scope.index++;
                }

                var emailSpliceList = [];
                for (var k = 0; k < contact.EmailAddresses.length; k++) {
                    if (contact.EmailAddresses[k] === '') {
                        emailSpliceList.push(k);
                    }
                }

                $scope.index = 0;
                for (var l = emailSpliceList.length; l > 0; l--) {
                    var e = emailSpliceList[l - 1];
                    contact.EmailAddresses.splice(e, 1);
                    $scope.index++;
                }

                contact.Visibility = false;
            };

            //Telefon hozzáadás
            $scope.addPhoneNumber = function (contact) {
                contact.PhoneNumbers.push('');
            };

            //Email hozzáadás
            $scope.addemail = function (contact) {
                contact.EmailAddresses.push('');
            };

            //Telefon törlése
            $scope.deletePhone = function (contact, index) {
                contact.PhoneNumbers.splice(index, 1);
            };

            //Email törlése
            $scope.deleteEmail = function (contact, index) {
                contact.EmailAddresses.splice(index, 1);
            };

            $scope.groupClick = function (selectedGroup) {
                if (selectedGroup.Name === 'Ügyfél') {
                    $scope.clientVisibility = (selectedGroup.IsSelected === true);
                }

                if (selectedGroup.Name === 'Beszállító') {
                    $scope.supplierVisibility = (selectedGroup.IsSelected === true);
                }

                angular.forEach($scope.GroupList, function (group) {
                    if (selectedGroup.Name === 'Ügyfél' || selectedGroup.Name === 'Beszállító') {
                        if (group.Name === 'Kapcsolat') {
                            group.IsSelected = false;
                        }
                    }
                    else if (selectedGroup.Name === 'Kapcsolat') {
                        if (group.Name === 'Ügyfél' || group.Name === 'Beszállító') {
                            group.IsSelected = false;
                        }

                        if (group.Name === 'Ügyfél') {
                            $scope.clientVisibility = false;
                        }
                        else if (group.Name === 'Beszállító') {
                            $scope.supplierVisibility = false;
                        }
                    }
                });
            };

            $scope.newDiscount = function () {
                location.href = siteurl.editDiscount + "/" + partnerId;
            };

            $scope.editDiscount = function (discount) {
                location.href = siteurl.editDiscount + "/" + partnerId + "/" + discount.Id;
            };

            $scope.deleteDiscount = function (discount) {
                if (confirm("Biztos benne, hogy törli a kedvezményt?")) {
                    $http.delete(apiurl.discount.deleteDiscount + '/' + discount.Id).success(function () {
                        loadDiscounts();
                    }).
                    error(function (data, status) {
                        toasterService.errorFromServerToast(data);
                    });
                }
            };
        }

        function loadStatuses() {
            return $http.get(apiurl.partner.getPartnerStatuses).then(function (response) {
                return response.data;
            });
        }

        function getDependences() {
            var deffers = [
                    getStatuses(),
                    getBaseData()
            ];
            if (!$scope.IsNewPartner) {
                deffers.push(getPartner());
                deffers.push(loadDiscounts());
            }

            deffers.push(getOptions());
            //Euró árfolyam letöltése
            deffers.push(getExchangeRate());

            return deffers;
        }

        function getStatuses() {
            return loadStatuses().then(function (response) {
                $scope.partnerStatuses = response;
                if (!$scope.IsNewPartner) {
                    return;
                }
                angular.forEach($scope.partnerStatuses, function (elem) {
                    if (elem.Define == "Aktív" && elem.Department == "CompanyStatus") {
                        $scope.actpartner.StatusId = elem.Id;
                    }
                });
            });
        }

        function getOptions() {
            var promise = $http.get(apiurl.partner.getOptionSettings).success(function (data) {
                angular.forEach(data, function (opt, key) {
                    if (opt.Name == 'EuroCreditLineIsCollapsed') {
                        if (opt.Value == '1') {
                            $scope.EuroCreditLineIsCollapsed = true;
                        } else {
                            $scope.EuroCreditLineIsCollapsed = false;
                        }
                    }
                });
            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });

            return promise;
        }

        function savePartnerState() {
            partnerPreviousState = angular.toJson($scope.actpartner)
                + angular.toJson($scope.AddressList)
                + angular.toJson($scope.BankAccuntList)
                + angular.toJson($scope.ContactList)
                + angular.toJson($scope.GroupList);
        }

        function stateChangedOfPartner() {
            return (angular.toJson($scope.actpartner) + angular.toJson($scope.AddressList)
                + angular.toJson($scope.BankAccuntList) + angular.toJson($scope.ContactList)
                + angular.toJson($scope.GroupList)) !== partnerPreviousState;
        }

        function getPartner() {
            var promise = $http.get(apiurl.partner.editPartner + '/' + partnerId).success(function (data) {
                $scope.actpartner = data.Partner;
                $scope.PageTitle = $scope.actpartner.ShortName;
                $scope.actpartner.PartnerHomeAddress = data.AddressHome;

                mapPartnerInfoDtoAddressToScope(data, $scope);

                $scope.AddressList = data.AddressSites;
                $scope.BankAccuntList = data.BankAccounts;
                $scope.ContactList = data.Contacts;
                $scope.ContactInteractionList = data.ContactInteractionList;
                $scope.ComplainList = data.ComplainList;
                $scope.GroupList = data.GroupList;

                setGroupVisible();
                setAddress();

                //DebitAmount - a céghez tartozó tartozások összege
                CurrentCreditAmountCount($scope.actpartner, $scope.actpartner.DebitAmount);
            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });

            return promise;
        }

        function getBaseData() {
            var promise = $http.get(apiurl.partner.getBaseData).success(function (data) {

                $scope.AddressTypeList = data.AddressTypes;
                $scope.PaymentTypeList = data.PaymentTypes;
                $scope.priceListGroups = data.PriceListGroups;

                setAddress();
                setBackAccount();

                if (!$scope.IsNewPartner) {
                    return;
                }
                $scope.GroupList = data.Groups;
                for (var i = 0; i < $scope.GroupList.length; i++) {
                    if ($scope.GroupList[i].Name === 'Ügyfél') {
                        $scope.GroupList[i].IsSelected = true;
                        $scope.clientVisibility = true;
                        $scope.isClientOfPartner = true; //Mentést követően lássan az egyedi áron értékesített termékeit
                    }
                }
            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });

            return promise;
        }

        function getExchangeRate() {
            var today = new Date();
            var yesterDay = new Date(today);
            yesterDay.setDate(today.getDate() - 1);
            var actDate = $filter('date')(today, 'yyyy.MM.dd.');
            yesterDay = $filter('date')(yesterDay, 'yyyy.MM.dd.');
            var param = { params: { ISO: "EUR", startDate: yesterDay, endDate: actDate } };

            var promise = $http.get(apiurl.partner.getExchangeRate, param).success(function (data) {
                $scope.exchangeRate = data;
            }).error(function (data, status) {
                toasterService.errorFromServerToast('A középárfolyam lekérdezése az MNB-től sikertelen volt!');
            });

            return promise;
        }

        function CurrentCreditAmountCount(actpartner, debitAmount) {
            if (actpartner.HufCreditLine != null) {
                $scope.currentCreditValue = CurrentCreditLineCounter.CurrentCredit(actpartner.HufCreditLine, debitAmount);
            }
        }

        //Beállítások
        function setAddress() {
            $scope.actpartner.actAddress = {
                Zip: '',
                City: '',
                District: '',
                PubliclyOwnedLandName: '',
                PubliclyOwnedLandType: '',
                HouseNumber: '',
                Building: '',
                Stairway: '',
                Floor: '',
                Door: '',

                FullAddress: ''
            };

            $scope.actpartner.actAddressType = $scope.AddressTypeList[0];
        }

        function setBackAccount() {
            $scope.actBankName = '';
            $scope.actFirstEightNumber = '';
            $scope.actSecondEightNumber = '';
            $scope.actThirdEightNumber = '';
        }

        function savePartnerMethodContinue() {
            if (!$scope.IsNewPartner) {
                var pdata = {
                    Partner: $scope.actpartner, AddressHome: $scope.actpartner.PartnerHomeAddress, AddressSites: $scope.AddressList, BankAccounts: $scope.BankAccuntList, Contacts: $scope.ContactList, GroupList: $scope.GroupList, ContactInteractionList: $scope.ContactInteractionList, ComplainList: $scope.ComplainList
                };
                mapAddressToPartnerInfoDto($scope, pdata);

                $http.put(apiurl.partner.updatePartner, pdata).success(function () {
                    location.href = siteurl.partners;
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            } else {
                var sdata = {
                    Partner: $scope.actpartner, AddressHome: $scope.actpartner.PartnerHomeAddress, AddressSites: $scope.AddressList, BankAccounts: $scope.BankAccuntList, Contacts: $scope.ContactList, GroupList: $scope.GroupList, ContactInteractionList: $scope.ContactInteractionList
                };
                mapAddressToPartnerInfoDto($scope, sdata);

                $http.post(apiurl.partner.savePartner, sdata).success(function () {
                    if ($rootScope.isFromModal) {
                        $rootScope.isFromModal = false;
                        $scope.$parent.$uibModalInstance.close();
                    } else {
                        location.href = siteurl.partners;
                    }
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            }
        }

        function setGroupVisible() {
            for (var i = 0; i < $scope.GroupList.length; i++) {
                if ($scope.GroupList[i].Name === 'Ügyfél' && $scope.GroupList[i].IsSelected === true) {
                    $scope.clientVisibility = true;
                    $scope.isClientOfPartner = true; //Mentést követően lássan az egyedi áron értékesített termékeit
                }
                if ($scope.GroupList[i].Name === 'Kapcsolat' && $scope.GroupList[i].IsSelected === true) {
                    $scope.clientVisibility = false;
                }
                if ($scope.GroupList[i].Name === 'Beszállító' && $scope.GroupList[i].IsSelected === true) {
                    $scope.supplierVisibility = true;
                    $scope.isSupplierOfPartner = true; //Mentést követően lássa a termékeit.
                }
            }
        }

        // Kedvezmények kezelése
        function loadDiscounts() {
            var promise = $http.get(apiurl.discount.getAllDiscount + '/' + partnerId).success(function (data) {
                $scope.discounts = data;
            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });;

            return promise;
        }
    }

    EditDiscountController.$inject = ['$http', '$scope', 'apiurl', 'toasterService'];
    function EditDiscountController($http, $scope, apiurl, toasterService) {
        var urlPieces = window.location.href.split("/");
        $scope.IsNewDiscount = urlPieces[urlPieces.length - 2] == "EditDiscount" ? true : false;

        $scope.discountType = '%';
        $scope.discountRadio = 1;
        $scope.rateForDiscount = '';
        $scope.selectedCategoryForDiscount = null;
        $scope.selectedProductForDiscount = null;

        var partnerId;

        if ($scope.IsNewDiscount) {
            $scope.discountTitle = "Új kedvezmény";
            partnerId = urlPieces[urlPieces.length - 1];
        }
        else {
            $scope.discountTitle = "Kedvezmény szerkesztése";
            partnerId = urlPieces[urlPieces.length - 2];
            var discountId = urlPieces[urlPieces.length - 1];

            $http.get(apiurl.discount.editDiscount + '/' + discountId).success(function (data) {
                $scope.editDiscountDatas = data;

                if ($scope.editDiscountDatas.Name === 'Összes termék') {
                    $scope.discountRadio = 3;
                    $scope.discountType = '%';
                }
                else if ($scope.editDiscountDatas.Type === 'kategória') {
                    $scope.discountRadio = 2;
                    $scope.discountType = '%';
                    $scope.selectedCategoryForDiscount = $scope.editDiscountDatas.Name;
                }
                else if ($scope.editDiscountDatas.Type === 'termék') {
                    $scope.discountRadio = 1;
                    $scope.discountType = $scope.editDiscountDatas.DiscountType;
                    $scope.selectedProductForDiscount = $scope.editDiscountDatas.Name;
                }

                $scope.rateForDiscount = $scope.editDiscountDatas.DiscountRate;
            });
        }

        $scope.getProductCategories = function (query) {
            return $http.get(apiurl.product.getProductCategories + '/' + query)
                .then(function (data) {
                    $scope.categoryList = [];
                    return data.data;
                });
        };

        $scope.getProducts = function (query) {
            return $http.get(apiurl.product.getProducts + '/' + query)
                .then(function (data) {
                    $scope.productList = [];
                    return data.data;
                });
        };

        $scope.setFixDiscountType = function () {
            $scope.discountType = 'Ft';
        };

        $scope.setPercentDiscountType = function () {
            $scope.discountType = '%';
        };

        $scope.BackToPartnerProfile = function () {
            if ($scope.IsNewDiscount)
                location.href = "../EditPartner/" + partnerId;
            else
                location.href = "../../EditPartner/" + partnerId;
        };

        $scope.saveDiscount = function () {
            if ($scope.IsNewDiscount) {
                var discountToSend;
                var urlToSend;

                if ($scope.discountRadio == 2) {
                    discountToSend = {
                        CompanyId: partnerId,
                        CategoryId: ($scope.selectedCategoryForDiscount === null) ? null : $scope.selectedCategoryForDiscount.Id,
                        DiscountRate: $scope.rateForDiscount,
                        DiscountType: '%'
                    };

                    urlToSend = apiurl.discount.saveDiscountForCategory;
                }
                if ($scope.discountRadio == 3) {
                    discountToSend = {
                        CompanyId: partnerId,
                        ProductId: -1,
                        DiscountRate: $scope.rateForDiscount,
                        DiscountType: '%'
                    };

                    urlToSend = apiurl.discount.saveDiscountForProduct;
                }
                if ($scope.discountRadio == 1) {
                    discountToSend = {
                        CompanyId: partnerId,
                        ProductId: ($scope.selectedProductForDiscount === null) ? null : $scope.selectedProductForDiscount.Id,
                        DiscountRate: $scope.rateForDiscount,
                        DiscountType: $scope.discountType
                    };

                    urlToSend = apiurl.discount.saveDiscountForProduct;
                }

                $http.post(urlToSend, discountToSend).success(function () {
                    location.href = "../EditPartner/" + partnerId;
                }).error(function (data, status) {
                    toasterService.errorFromServerToast(data);
                });
            }
            else {
                $scope.editDiscountDatas.DiscountRate = $scope.rateForDiscount;
                $scope.editDiscountDatas.DiscountType = $scope.discountType;

                $http.put(apiurl.discount.updateDiscount, $scope.editDiscountDatas).success(function () {
                    location.href = "../../EditPartner/" + partnerId;
                }).error(function (data, status) {
                    toasterService.errorFromServerToast(data);
                });
            }
        };
    }

    function mapPartnerInfoDtoAddressToScope(partnerInfoDto, scope) {
        scope.actpartner.Zip = partnerInfoDto.Zip;
        scope.actpartner.City = partnerInfoDto.City;
        scope.actpartner.District = partnerInfoDto.District;
        scope.actpartner.PubliclyOwnedLandName = partnerInfoDto.PubliclyOwnedLandName;
        scope.actpartner.PubliclyOwnedLandType = partnerInfoDto.PubliclyOwnedLandType;
        scope.actpartner.HouseNumber = partnerInfoDto.HouseNumber;
        scope.actpartner.Building = partnerInfoDto.Building;
        scope.actpartner.Stairway = partnerInfoDto.Stairway;
        scope.actpartner.Floor = partnerInfoDto.Floor;
        scope.actpartner.Door = partnerInfoDto.Door;
    }

    function mapAddressToPartnerInfoDto(scope, partnerInfoDto) {
        partnerInfoDto.Zip = scope.actpartner.Zip;
        partnerInfoDto.City = scope.actpartner.City;
        partnerInfoDto.District = scope.actpartner.District;
        partnerInfoDto.PubliclyOwnedLandName = scope.actpartner.PubliclyOwnedLandName;
        partnerInfoDto.PubliclyOwnedLandType = scope.actpartner.PubliclyOwnedLandType;
        partnerInfoDto.HouseNumber = scope.actpartner.HouseNumber;
        partnerInfoDto.Building = scope.actpartner.Building;
        partnerInfoDto.Stairway = scope.actpartner.Stairway;
        partnerInfoDto.Floor = scope.actpartner.Floor;
        partnerInfoDto.Door = scope.actpartner.Door;
    }

    function addressIsEmpty(scope) {
        var isEmpty = !scope.actpartner.Zip
            //|| !scope.actpartner.City
            || !scope.actpartner.PubliclyOwnedLandName;

        return isEmpty;
    }

    function isSupplierOfPartner(groupList, taxNumber) {
        if (angular.isUndefined(groupList))
            return false;

        var result = false;
        angular.forEach(groupList, function (group) {
            if (group.Name === 'Beszállító' && group.IsSelected === true && (angular.isUndefined(taxNumber) || taxNumber === '')) {
                result = true;
            }
        });

        return result;
    }

})();
