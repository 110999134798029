(function () {

    angular.module('IMasterApp')
        .service("CostCenterReportService", CostCenterReportService)
        .controller("CostCenterReportCtrl", CostCenterReportCtrl)
        .service("EditCostCenterReportService", EditCostCenterReportService)
        .controller("EditCostCenterReportCtrl", EditCostCenterReportCtrl);

    CostCenterReportService.$inject = ['$http', 'apiurl'];
    function CostCenterReportService($http, apiurl) {

        this.searchCostCenterReport = function (data) {
            return $http.post(apiurl.costCenter.costCenterReport, data);
        }
    }

    CostCenterReportCtrl.$inject = ['$scope', 'CostCenterReportService', 'toasterService', 'siteurl'];
    function CostCenterReportCtrl($scope, CostCenterReportService, toasterService, siteurl) {

        // init
        $scope.CostCenterReportList = [];
        $scope.current = 1;
        $scope.search = {
            PageSize: 25,
            PageIndex: $scope.current - 1,
            Name: '',
            SortNameByDesc: false
        }

        // Data 
        function init() {
            CostCenterReportService.searchCostCenterReport($scope.search).
                success(function (data) {
                    $scope.CostCenterReportList = [];
                    angular.forEach(data.Items, function (value) {
                        //ezres tagolás
                        value.Proceeds = value.Proceeds.toFixed(0).replace(/./g, function (c, i, a) {
                            return i && c !== "," && ((a.length - i) % 3 === 0) ? ' ' + c : c;
                        });
                        value.Expense = value.Expense.toFixed(0).replace(/./g, function (c, i, a) {
                            return i && c !== "," && ((a.length - i) % 3 === 0) ? ' ' + c : c;
                        });
                        value.Balance = value.Balance.toFixed(0).replace(/./g, function (c, i, a) {
                            return i && c !== "," && ((a.length - i) % 3 === 0) ? ' ' + c : c;
                        });

                        $scope.CostCenterReportList.push(value);
                    });
                    $scope.totalItems = data.TotalItems;
                }).
                error(function (error, status, data) {
                    toasterService.warningToast("Sikertelen adatlekérés");
                });
        }
        init();

        // Tree-grid  definitions
        $scope.my_tree = tree = {};
        $scope.expanding_property = "Name";
        $scope.col_def = [
            { displayName: "Bevétel", field: "Proceeds" },
            { displayName: "Kiadás", field: "Expense" },
            { displayName: "Egyenleg", field: "Balance" }
        ];
        $(document).ready(
        function () {
            // Azonosító felirat cseréje
            $('.tree-grid thead tr th:first-child').text('Azonosító');
            // Fejléc Click
            $('.tree-grid thead tr th:nth-child(1)')
                .click(function (data) {
                    $scope.search.SortNameByDesc = !$scope.search.SortNameByDesc;

                    //Lekérés
                    init();
                });
        });

        // Kereső szöveg beírása és adatlekérés
        $scope.searchQuery = function () {
            if ($scope.search.Name.length > 2) {
                //Lekérés
                init();
            }
            if ($scope.search.Name.length === 0) {
                init();
            }
        };

        //Pager változtatás
        $scope.changeSelected = function () {
            init();
        }

        $scope.pageChanged = function () {
            $scope.search.PageIndex = $scope.current - 1;
            init();
        }

        // On select function
        $scope.my_tree_handler = function (branch) {
            window.location.href = siteurl.editcostcenterreport + "/" + branch.Id;
        }
    }

    function EditCostCenterReportDTO(data) {
        this.CostCenter = data.Name || "Teszt";
        this.CurrentBalance = data.Balance || 0;
        this.Receipt = data.Proceeds || 0;
        this.Expense = data.Expense || 0;

        var _this = this;
        this.VoucherList = [];

        //Bizonylatok feltöltése
        if (angular.isDefined(data.VoucherList) && data.VoucherList.length !== 0) {
            angular.forEach(data.VoucherList, function (value) {
                _this.VoucherList.push(value);
            });
        }

        this.filter = {
            DateFrom: data.Filter.DateFrom === null || angular.isUndefined(data.filter.DateFrom) ? "" : data.filter.DateFrom,
            DateTo: data.Filter.DateTo === null || angular.isUndefined(data.filter.DateTo) ? "" : data.filter.DateTo,
            CostCenterId: data.Filter.costCenterId || 0,
            TotalItemCount: data.Filter.TotalItemCount || 0,
            PageIndex: data.Filter.PageIndex || 0,
            PageSize: data.Filter.PageSize || 0,
            IsTakeOut: data.Filter.IsTakeOut || true,
            IsTakeIn: data.Filter.IsTakeIn || true
        }

    }

    function VoucherOfCostCenterDto(data) {
        this.Items = data.Items || [];
        this.WarehouseName = data.WarehouseName || '';
        this.PartnerName = data.PartnerName || '';
    }

    EditCostCenterReportService.$inject = ['$http', 'apiurl'];
    function EditCostCenterReportService($http, apiurl) {

        this.getVouchersForCostCenter = function (filter) {
            return $http.post(apiurl.costCenter.getVouchersByCostCenterId, filter);
        }

        this.getProductsByVoucherId = function (voucherId, costCenterId) {
            return $http.get(apiurl.costCenter.getProductsByVoucherId + '/' + voucherId + '/' + costCenterId);
        }
    }

    EditCostCenterReportCtrl.$inject = ['$scope', 'toasterService', 'EditCostCenterReportService', 'siteurl'];
    function EditCostCenterReportCtrl($scope, toasterService, EditCostCenterReportService, siteurl) {

        //Datepickers definitions
        $scope.format = "yyyy.MM.dd";

        $scope.openedDp = [];
        $scope.openDatePicker = function ($event, index) {
            $event.preventDefault();

            $scope.openedDp[index] = true;
        };
        $scope.DateOptions = {
            startingDay: 1
        };

        //init
        var costCenterId = window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1);
        $scope.filter = {
            CostCenterId: costCenterId,
            TotalItemCount: 0,
            PageIndex: 0,
            PageSize: 25,
            IsTakeOut: true,
            IsTakeIn: true,
            DateFrom: null,
            DateTo: null
        };

        $scope.getCostCenterReports = function () {
            EditCostCenterReportService.getVouchersForCostCenter($scope.filter).success(function (data) {
                $scope.data = new EditCostCenterReportDTO(data);

                $scope.title = data.Name;
                $scope.filter = angular.copy(data.Filter);
            });
        };

        $scope.getCostCenterReports();

        $scope.pageChanged = function () {
            $scope.filter.PageIndex = $scope.current - 1;
            $scope.getCostCenterReports();
        }
        // táblázatban sor kijelölése
        function isSelected(selectedItem) {
            if (!angular.isUndefined($scope.data)) {
                angular.forEach($scope.data.VoucherList, function (value) {
                    if (value.isSelected !== undefined) {
                        if (value.Id === selectedItem.Id)
                            value.isSelected = "InsideDeadlineDate";
                        else
                            value.isSelected = "";
                    } else {
                        value.isSelected = "";
                    }
                });
            }
        }
        isSelected();

        // Egy bizonylat kiválasztása és a termék adatok lekérése
        $scope.VoucherClick = function (voucher) {
            isSelected(voucher);
            EditCostCenterReportService.getProductsByVoucherId(voucher.Id, costCenterId).success(function (data) {
                $scope.voucherOfCostCenterData = new VoucherOfCostCenterDto(data);
            });
        };

        $scope.backButtonClick = function () {
            window.location.href = siteurl.costcenterreport;
        };
    }

})();
