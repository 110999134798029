(function () {

    angular.module('IMasterApp')
        .controller('EditEmailTemplate', EditEmailTemplate);

    EditEmailTemplate.$inject = ['$scope', '$http', '$location', '$window', '$filter', 'siteurl', 'apiurl', '$localStorage', 'toasterService', 'usSpinnerService', 'userService'];
    function EditEmailTemplate($scope, $http, $location, $window, $filter, siteurl, apiurl, $localStorage, toasterService, usSpinnerService, userService) {
        $scope.siteurl = siteurl;

        var backUrl = (/url=([^&=]+)/g).exec(location.search);
        backUrl = backUrl !== null ? backUrl[1] : siteurl.debit;

        $scope.actUrl = siteurl.editEmailTemplate;
        var editEmailTemplate;
        var contactInteractionId;
        var complainId;

        if (location.href.indexOf(siteurl.editEmailTemplate) !== -1) {
            $scope.actUrl = siteurl.editEmailTemplate;
            editEmailTemplate = true;
        } else if (location.href.indexOf(siteurl.sendEmail) !== -1) {
            $scope.actUrl = siteurl.sendEmail;
            editEmailTemplate = false;
            if (backUrl.substring(0, backUrl.lastIndexOf("/")) == siteurl.editcomplain || backUrl == siteurl.editcomplain) { //adott reklamációból jövünk
                if (backUrl == siteurl.editcomplain) {
                    complainId = window.location.href.substring(window.location.href.indexOf("?complainId=") + 12, window.location.href.indexOf("?url=").valueOf());
                } else {
                    complainId = backUrl.substring(backUrl.lastIndexOf("/") + 1); //complainId kiszedése
                }
                var checkUserList = $localStorage.checkUserList; //kiolvasom a localStorageből a kiválasztott felhasználókat
                delete $localStorage.checkUserList; //kitörlöm a localStorageből a kiválasztott usereket.
                var commonEditComplain = true;
            }
            if (backUrl.substring(0, backUrl.lastIndexOf("/")) == siteurl.editContactInteraction) { //adott kapcsolatfelvételből jövünk
                var companyId;
                if (backUrl.substring(0, backUrl.lastIndexOf("PartnerId") + 9) == siteurl.editContactInteraction + "/PartnerId") {
                    contactInteractionId = window.location.href.substring(window.location.href.indexOf("?contactInteractionId=") + 22, window.location.href.indexOf("?url=").valueOf());
                    companyId = backUrl.substring(backUrl.lastIndexOf("PartnerId") + 9);

                    $http.get(apiurl.email.getEmailAddressByPartnerUrl + companyId).success(function (data) {
                        //Címzettek email címeinek eltárolása
                        var index = $scope.Recipients.length;
                        while (index > 0) {
                            index--;
                            if ($scope.Recipients[index].Id === tag.Id) {
                                $scope.Recipients.splice(index, 1);
                            }
                        }
                        angular.forEach(data, function (value, key) {
                            var emailAddress = { Id: parseInt(companyId), Name: value }; //itt az Id = CompanyId-val
                            $scope.Recipients.push(emailAddress);
                        });
                    }).error(function (data, status) {
                        toasterService.errorFromServerToast(data);
                    });
                } else {
                    contactInteractionId = backUrl.substring(backUrl.lastIndexOf("/") + 1); //contactInteractionId kiszedése
                    $http.get(apiurl.email.getEmailAddressByContactInteractionUrl + contactInteractionId).success(function (data) {
                        //Címzettek email címeinek eltárolása
                        var index = $scope.Recipients.length;
                        while (index > 0) {
                            index--;
                            if ($scope.Recipients[index].Id === tag.Id) {
                                $scope.Recipients.splice(index, 1);
                            }
                        }
                        angular.forEach(data, function (value, key) {
                            var emailAddress = { Id: parseInt(key), Name: value }; //itt az Id = CompanyId-val
                            $scope.Recipients.push(emailAddress);
                        });
                    }).error(function (data, status) {
                        toasterService.errorFromServerToast(data);
                    });
                }
                var commonEditContactInteraction = true;
            }
        }


        var emailTemplateId = window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1);
        //Email sablonok kezelése
        if (editEmailTemplate === true) {
            $scope.Title = "Email sablonok kezelése";
            $scope.disabledElement = false;
            //Új sablon
            if (emailTemplateId === 'EditEmailTemplate' || emailTemplateId === '') {
                var editTemplate = false;
                $scope.hideEditEmailTemplate = false;
                $http.get(apiurl.email.getEmailTypesUrl).success(function (data) {
                    //Email típusok
                    $scope.emailTypes = data;
                    $scope.emailType = $scope.emailTypes[0];
                    $scope.CheckDynamicHTMLElements($scope.emailType);
                });
            } else {
                $scope.hideEditEmailTemplate = true;

                var setDynamicHTMLElement = true;
                $http.get(apiurl.email.getEmailTemplateUrl + emailTemplateId + '/' + setDynamicHTMLElement).success(function (data) {
                    $scope.emailTemplateData = data.EmailTemplate;
                    //Email típus beállítása
                    $scope.emailTypes = data.EmailTemplateTypes;
                    angular.forEach($scope.emailTypes, function (value, key) {
                        if (value.Id === $scope.emailTemplateData.TypeId) {
                            $scope.emailType = value;
                            $scope.CheckDynamicHTMLElements($scope.emailType);
                        }
                    });
                }).error(function (data, status) {
                    toasterService.errorFromServerToast(data);
                });
            }
            //Email küldése
        } else {
            $scope.Title = "Email küldése";
            $scope.NavbarTitle = "Email alapadatok";
            $scope.showInvoiceDataGrid = false;
            $scope.EmailSubject = ''; //tárgy
            $scope.Recipients = []; //címzettek
            $scope.actEmailSablon = null; //kiválasztott email sablon
            var Partners = []; //partnerek
            var userId = $window.sessionStorage.getItem('userid'); //felhasználó id elérése
            var emailData = {
                EmailTemplateId: '',
                Sender: '',
                Recipients: [],
                Subject: '',
                Body: '',
                CheckedDebitOfInvoiceIdList: []
            };

            //A kijelölt tartozásos számlák
            var checkedDebitOfInvoiceList = [];

            userService.getUserById(userId).success(function (data) {
                $scope.user = data;

                //Reklamációból jövünk
                if (commonEditComplain == true) {
                    //feltöltjük a címzettek listát a reklamáció kezelésnél kiválasztott munkatársak email címeivel, 
                    //kivéve a bejelentkezett felhasználó email címét nem rakjuk bele(mert ő lesz a feladó)
                    if (checkUserList.length > 0) {
                        angular.forEach(checkUserList, function (value, key) {
                            if (value != null && $scope.user.UserId != value.UserId) {
                                var emailAddress = { UserId: value.UserId, Name: value.UserEmail, UserFullName: value.UserFirstname + " " + value.UserLastname };
                                $scope.Recipients.push(emailAddress);
                            }
                        });
                    }
                }
            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });
        }

        //Init
        $scope.emailTemplateData = {
            Name: '',
            Content: '',
            TypeId: ''
        };
        $scope.PanelTitle = "Sablon";

        //Új Sablon létrehozása
        $scope.AddNewEmailTemplate = function () {
            $scope.PanelTitle = "Új sablon";
            $scope.hideEditEmailTemplate = false;
            $scope.disabledElement = true;
            $scope.RefreshData();
        };

        //Email sablon mentése/módosítása
        $scope.save = function () {
            $scope.emailTemplateData.TypeId = $scope.emailType.Id;
            if ($scope.emailTemplateData.Content == null) {
                $scope.emailTemplateData.Content = "";
            }
            while ($scope.emailTemplateData.Content.indexOf("*") !== -1) {
                $scope.emailTemplateData.Content = $scope.emailTemplateData.Content.replace("*", "");
            }
            $scope.saveEmailTemplate($scope.emailTemplateData);
        }

        //Email sablon kezelésének visszavonása
        $scope.cancel = function () {
            $scope.disabledElement = false;
            $scope.Refresh();
        }

        //Validálás
        $scope.Validate = function (emailTemplateData) {
            if (editEmailTemplate === true) {
                if (emailTemplateData.Name == null || emailTemplateData.Name == '') {
                    toasterService.warningToast("Figyelem", "A sablon nevének kitöltése kötelező!");
                    return false;
                }
                if (emailTemplateData.TypeId == null || emailTemplateData.TypeId == '') {
                    toasterService.warningToast("Figyelem", "A sablon típusának kiválasztása kötelező!");
                    return false;
                }

                return true;
            } else {
                if ($scope.user.UserEmail == null || $scope.user.UserEmail == '') {
                    toasterService.warningToast("Figyelem", "Nincsen beállítva Önnek e-mail cím!");
                    return false;
                }
                if (isEmail($scope.user.UserEmail) == false) {
                    toasterService.warningToast("Figyelem", "Önnél beállított e-mail cím érvénytelen!");
                    return false;
                }
                if ($scope.actEmailSablon.Name == null || $scope.actEmailSablon.Name == '') {
                    toasterService.warningToast("Figyelem", "Nem választott ki sablont!");
                    return false;
                }
                if ($scope.EmailSubject == null || $scope.EmailSubject == '') {
                    if (!confirm("A levélnek nincs tárgya. Ennek ellenére folytatja?")) {
                        return false;
                    }
                }
                if ($scope.Recipients.length == 0) {
                    toasterService.warningToast("Figyelem", "A levélnek nincs beállítva címzett!");
                    return false;
                }
                if (isEmailList($scope.Recipients) == true) {
                    var errorString = '';
                    angular.forEach($scope.Recipients, function (value, key) {
                        if (value.IsValid === false) {
                            errorString += value.Name;
                            errorString += "\n";
                        }
                    });
                    toasterService.warningToast("Figyelem", "A következő e-mail címek érvénytelenek:\n" + errorString);
                    return false;
                }

                return true;
            }
        }

        //Email sablon mentése
        $scope.saveEmailTemplate = function (emailTemplateData) {
            if (editTemplate === true) {
                if (!confirm('Valóban menteni akarja a módosításokat?')) {
                    $scope.disabledElement = false;
                    $scope.Refresh();
                    return;
                }
                var modifiedSablon = true;
            } else {
                if (!confirm('Valóban menteni akarja a sablont?')) {
                    $scope.disabledElement = false;
                    $scope.Refresh();
                    return;
                }
                var modifiedSablon = false;
            }

            var result = $scope.Validate(emailTemplateData);
            if (!result) {
                return;
            }

            $scope.saveEmailTemplatePost(emailTemplateData, modifiedSablon);
        }

        //Email sablon mentésének befejezése
        $scope.saveEmailTemplatePost = function (data, modifiedSablon) {
            if (modifiedSablon === true) {
                $http.put(apiurl.email.updateEmailTemplateUrl, data).success(function (data) {
                    //alert("Sablon sikeresen módosítva!");
                    toasterService.successToast("Módosítás", "Sablon sikeresen módosítva!");
                    $scope.searchFunction();
                    $scope.disabledElement = false;
                    $scope.Refresh();
                }).error(function (data, status) {
                    toasterService.errorFromServer(data);
                });
            } else {
                $http.post(apiurl.email.saveEmailTemplateUrl, data).success(function (data) {
                    //alert("Sablon sikeresen létrehozva!");
                    toasterService.successToast("Mentés", "Sablon sikeresen létrehozva!");
                    $scope.searchFunction();
                    $scope.disabledElement = false;
                    $scope.Refresh();
                }).error(function (data, status) {
                    toasterService.errorFromServer(data);
                });
            }
        };

        //Email sablonok lekérdezése
        if (editEmailTemplate === true) {

            $scope.SearchObj = { sortIndex: "", orderDirection: true };
            $scope.currentPage = 0;
            $scope.pageSize = 25;
            $scope.actualPage = 0;
            $scope.selectedPageSize = 25;

            //Email sablonok lekérdezése
            $scope.searchFunction = function (sortIndex) {
                if (!angular.isUndefined(sortIndex)) {
                    if (sortIndex === $scope.SearchObj.sortIndex) {
                        $scope.SearchObj.orderDirection = !$scope.SearchObj.orderDirection;
                    }
                    else {
                        $scope.SearchObj.orderDirection = true;
                        $scope.SearchObj.sortIndex = sortIndex;
                    }
                }

                //Szűrési Dto összeállítása
                var filterEmailTemplates = createFilterEmailTemplatesData();
                searchFunctionFinish(filterEmailTemplates);
            }

            //Email sablonok lekérdezésének befejezése
            var searchFunctionFinish = function (filterEmailTemplates) {
                $http.post(apiurl.email.getEmailTemplatesUrl + $scope.currentPage + '/' + $scope.pageSize, filterEmailTemplates).success(function (data) {
                    $scope.EmailTemplates = data.EmailTemplateList;
                })
                .error(function (data, status) {
                    toasterService.errorFromServer(data);
                });
            }

            //Email sablonok rendezése
            $scope.searchFunctionForSort = function (sortIndex) {
                $scope.searchFunction(sortIndex);
            }

            var createFilterEmailTemplatesData = function () {
                return {
                    sortIndex: $scope.SearchObj.sortIndex, // A rendezéshez szükséges oszlop neve
                    orderDirection: $scope.SearchObj.orderDirection // A rendezéshez szükséges irány
                };
            }

            //Lapozás
            $scope.pageChanged = function () {
                $scope.currentPage = $scope.current - 1;
                $scope.searchFunction();
            };

            //Email sablonok beállítása 1 oldalon
            $scope.changeSelected = function () {
                if ($scope.selectedPageSize > 0) {
                    $scope.pageSize = $scope.selectedPageSize;
                    $scope.currentPage = 0;
                    $scope.searchFunction();
                }
            };

            $scope.searchFunction();
        }

        $scope.companyCurrentPage = 0;
        $scope.companyPageSize = 25;
        $scope.companyActualPage = 0;
        $scope.companySelectedPageSize = 25;
        $scope.companySearchQuery = '';

        $scope.selectActEmailSablon = function () {
            //Tartozásos ügyfelek lekérdezése fizetési felszólítás sablon esetén
            if ($scope.actEmailSablon != null) {
                if ($scope.actEmailSablon.TypeName == 'Fizetési felszólítás') {
                    if (commonEditComplain == true || commonEditContactInteraction == true) {
                        $scope.actEmailSablon = null;
                    } else {
                        getDebitOfCompanies();
                    }
                }
            }
        }

        $scope.companySearchObj = { sortIndex: "", orderDirection: true };

        //Tartozásos ügyfelek lekérdezése
        var getDebitOfCompanies = function (sortIndex) {
            if (!angular.isUndefined(sortIndex)) {
                if (sortIndex === $scope.companySearchObj.sortIndex) {
                    $scope.companySearchObj.orderDirection = !$scope.companySearchObj.orderDirection;
                }
                else {
                    $scope.companySearchObj.orderDirection = true;
                    $scope.companySearchObj.sortIndex = sortIndex;
                }
            }

            //Szűrési Dto összeállítása
            var filterDebitOfCompanies = createFilterDebitOfCompaniesData();

            getDebitOfCompaniesFinish(filterDebitOfCompanies);
        }

        //Tartozásos ügyfelek rendezése
        $scope.getDebitOfCompaniesForSort = function (sortIndex) {
            getDebitOfCompanies(sortIndex);
        }

        //Tartozásos ügyfelek lekérdezésének befejezése
        var getDebitOfCompaniesFinish = function (filterDebitOfCompanies) {
            $http.post(apiurl.email.getDebitOfCompaniesUrl + $scope.companyCurrentPage + '/' + $scope.companyPageSize, filterDebitOfCompanies).success(function (data) {
                //Ez azért kellett, mert az Ügyfelek és a Számlák Dtoban is DebitAmount van létrehozva a tartozások eltárolására, és emiatt ha rendeztem az 
                //a számlákat tartozás szerint, akkor a cégeket is rendezte tartozás érték szerint.
                var debitOfCompanyList = [];
                angular.forEach(data.CompanyList, function (value, key) {
                    var debitOfCompany = { Id: value.Id, Name: value.Name, Address: value.Address, TotalDebitAmount: value.DebitAmount }; //itt van a hangsúly: TotalDebitAmount: value.DebitAmount
                    debitOfCompanyList.push(debitOfCompany);
                });

                $scope.DebitOfCompanies = debitOfCompanyList;
                $scope.companyTotalItems = data.TotalItems;
                if (debitOfCompanyList.length > 0) {
                    getDebitOfCompaniesSum();
                }

            }).error(function (data, status) {
                toasterService.errorFromServer(data);
            });
        };

        var createFilterDebitOfCompaniesData = function () {
            return {
                sortIndex: $scope.companySearchObj.sortIndex, // A rendezéshez szükséges oszlop neve
                orderDirection: $scope.companySearchObj.orderDirection, // A rendezéshez szükséges irány
                searchQuery: $scope.companySearchQuery // Kereséshez
            };
        }

        //Tartozásos ügyfelek kereső mezőjének figyelése
        $scope.$watch("companySearchQuery", function () {
            if ($scope.actEmailSablon != null) {
                if ($scope.actEmailSablon.TypeName == 'Fizetési felszólítás') {
                    if ($scope.companySearchQuery.length > 2) {
                        getDebitOfCompanies();
                    }
                    else if ($scope.companySearchQuery === '') {
                        getDebitOfCompanies();
                    }
                }
            }
        });

        //Tartozásos ügyfelek összegzősorának lekérdezése
        function getDebitOfCompaniesSum() {
            function success(data) {
                $scope.sumTotalDebitValueForCompanies = data;
            }

            function error(data, status) {
                toasterService.errorFromServer(data);
            }

            $http.get(apiurl.email.getDebitOfCompaniesSumUrl).success(success).error(error);
        }

        //Tartozásos ügyfelek lapozás
        $scope.companyPageChanged = function () {
            $scope.companyCurrentPage = $scope.companyCurrent - 1;
            getDebitOfCompanies();
        };

        //Tartozásos ügyfelek beállítása 1 oldalon
        $scope.companyChangeSelected = function () {
            if ($scope.companySelectedPageSize > 0) {
                $scope.companyPageSize = $scope.companySelectedPageSize;
                $scope.companyCurrentPage = 0;
                getDebitOfCompanies();
            }
        };

        //Email sablonok lekérdezése email küldéskor
        $scope.getTemplateList = function (templateName, index) {
            if (commonEditComplain == undefined && commonEditContactInteraction == undefined) {
                return $http.get(apiurl.email.getEmailTemplateListByTemplateNameUrl + templateName + '/' + true).then(function (data) {
                    return data.data;
                });
            } else {
                return $http.get(apiurl.email.getEmailTemplateListByTemplateNameUrl + templateName + '/' + false).then(function (data) {
                    return data.data;
                });
            }
        };

        var partnerName = '';

        $scope.debitOfInvoiceSearchObj = { sortIndex: "", orderDirection: true };

        $scope.debitOfInvoiceCurrentPage = 0;
        $scope.debitOfInvoicePageSize = 25;
        $scope.debitOfInvoiceActualPage = 0;
        $scope.debitOfInvoiceSelectedPageSize = 25;

        // Az adott ügyfélhez tartozó tartozásos számlák lekérdezése
        $scope.getDebitOfInvoiceList = function (index) {
            $scope.AllCheckedInvoice = false;
            //Ha változtatok ügyfelet, akkor el kell menteni az eddig kijelölt számlákat
            if ($scope.Vouchers != null) {
                angular.forEach($scope.Vouchers.VoucherList, function (value, key) {
                    if ($scope.Vouchers.VoucherList[key].IsChecked == true) {
                        var checkedDebitOfInvoice = { VoucherId: value.VoucherId, IsChecked: true };
                        if (checkedDebitOfInvoiceList.length > 0) {
                            angular.forEach(checkedDebitOfInvoiceList, function (value2, key2) {
                                if (value2.VoucherId == value.VoucherId && value2.IsChecked == true) {
                                    checkedDebitOfInvoiceList.splice(key2, 1);
                                    checkedDebitOfInvoiceList.push(checkedDebitOfInvoice);
                                }
                            });
                        } else {
                            checkedDebitOfInvoiceList.push(checkedDebitOfInvoice);
                        }
                    }
                });
            }

            $scope.showInvoiceDataGrid = true;
            partnerName = index.Name; //kiválasztott partner neve

            //Tartozásos számlák lekérdezésének folytatása
            getDebitOfInvoiceListContinue();
        };

        //Tartozásos számlák rendezése
        $scope.getDebitOfInvoiceListForSort = function (sortIndex) {
            getDebitOfInvoiceListContinue(sortIndex);
        };

        var getDebitOfInvoiceListContinue = function (sortIndex) {
            if (!angular.isUndefined(sortIndex)) {
                if (sortIndex === $scope.debitOfInvoiceSearchObj.sortIndex) {
                    $scope.debitOfInvoiceSearchObj.orderDirection = !$scope.debitOfInvoiceSearchObj.orderDirection;
                }
                else {
                    $scope.debitOfInvoiceSearchObj.orderDirection = true;
                    $scope.debitOfInvoiceSearchObj.sortIndex = sortIndex;
                }
            }

            //Szűrési Dto összeállítása
            var filterDebitOfInvoice = createFilterDebitOfInvoiceData();

            //Tartozásos számlák lekérdezésének befejezése
            getDebitOfInvoiceListFinish(filterDebitOfInvoice);
        };

        var getDebitOfInvoiceListFinish = function (filterDebitOfInvoice) {
            $http.post(apiurl.email.getDebitOfVoucherListUrl + $scope.debitOfInvoiceCurrentPage + '/' + $scope.debitOfInvoicePageSize, filterDebitOfInvoice).success(function (data) {
                $scope.Vouchers = data;
                $scope.debitOfInvoiceTotalItems = data.TotalItems;

                if (checkedDebitOfInvoiceList.length > 0) {
                    var addedNewBill = 0;
                    angular.forEach(checkedDebitOfInvoiceList, function (value, key) {
                        angular.forEach($scope.Vouchers.VoucherList, function (value2, key2) {
                            if (value.VoucherId == value2.VoucherId && value.IsChecked == true) {
                                $scope.Vouchers.VoucherList[key2].IsChecked = true;
                                addedNewBill++;
                            }
                        });
                    });

                    if (addedNewBill == $scope.Vouchers.VoucherList.length) {
                        $scope.AllCheckedInvoice = true;
                    }
                }

                getVoucherListSum();
            })
            .error(function (data, status) {
                toasterService.errorFromServer(data);
            });
        };

        //Tartozásos számlák összegzősorának lekérdezése
        var getVoucherListSum = function () {
            function success(data) {
                $scope.sumTotalDebitValue = data.ValueOfDebt;
            }

            function error(data, status) {
                toasterService.errorFromServer(data);
            }

            $http.post(apiurl.email.getDebitOfVoucherListSumUrl, createFilterDebitOfInvoiceData())
            .success(success)
            .error(error);
        }

        //A tartozásos számlák szűrése
        $scope.debitOfInvoiceFilter = {
            InvoiceNumber: '',
            fromDebitPrice: '',
            toDebitPrice: '',
            fromDeadlineDate: '',
            toDeadlineDate: '',
        };

        var createFilterDebitOfInvoiceData = function () {
            return {
                buyerName: partnerName, //Partner neve
                outGoingDebitOfBilled: true, //tartozásos
                statusIssued: true, //kiállított
                typeInvoice: true, //számla
                sortIndex: $scope.debitOfInvoiceSearchObj.sortIndex, // A rendezéshez szükséges oszlop neve
                orderDirection: $scope.debitOfInvoiceSearchObj.orderDirection, // A rendezéshez szükséges irány
                InvoiceNumber: $scope.debitOfInvoiceFilter.InvoiceNumber, //Számla sorszám
                fromDebitPrice: $scope.debitOfInvoiceFilter.fromDebitPrice, //Tartozás összeg-től
                toDebitPrice: $scope.debitOfInvoiceFilter.toDebitPrice, //Tartozás összeg-ig
                fromDeadlineDate: $filter('date')($scope.debitOfInvoiceFilter.fromDeadlineDate, 'yyyy.MM.dd'),  //Fizetési határidő-től
                toDeadlineDate: $filter('date')($scope.debitOfInvoiceFilter.toDeadlineDate, 'yyyy.MM.dd') //Fizetési határidő-ig
            };
        }

        //Tartozásos számlák szűrése
        $scope.useDebitOfInvoiceFilter = function () {
            getDebitOfInvoiceListContinue();
        };

        //Tartozásos számlák szűrésének törlése
        $scope.deleteDebitOfInvoiceSearch = function () {
            $scope.debitOfInvoiceFilter = {};
            getDebitOfInvoiceListContinue();
        }

        //Datepickers definitions
        $scope.format = "yyyy.MM.dd";

        $scope.openedDp = [];
        $scope.openDatePicker = function ($event, index) {
            $event.preventDefault();

            $scope.openedDp[index] = true;
        };
        $scope.DateOptions = {
            startingDay: 1
        };

        //Tartozásos számlák lapozása
        $scope.debitOfInvoicePageChanged = function () {
            $scope.debitOfInvoiceCurrentPage = $scope.debitOfInvoiceCurrent - 1;
            getDebitOfInvoiceListContinue();
        };

        //Tartozásos számlák számának beállítása 1 oldalon
        $scope.debitOfInvoiceChangeSelected = function () {
            if ($scope.debitOfInvoiceSelectedPageSize > 0) {
                $scope.debitOfInvoicePageSize = $scope.debitOfInvoiceSelectedPageSize;
                $scope.debitOfInvoiceCurrentPage = 0;
                getDebitOfInvoiceListContinue();
            }
        };

        //Az összes tartozásos számla kiválasztása
        $scope.AllCheckedDebitOfInvoice = function () {
            if ($scope.AllCheckedInvoice == true) {
                angular.forEach($scope.Vouchers.VoucherList, function (value, key) {
                    $scope.Vouchers.VoucherList[key].IsChecked = true;
                    var checkedDebitOfInvoice = { VoucherId: value.VoucherId, IsChecked: true };
                    var addedNewBill = 0;
                    if (checkedDebitOfInvoiceList.length > 0) {
                        angular.forEach(checkedDebitOfInvoiceList, function (value2, key2) {
                            if (value2.VoucherId == value.VoucherId && value2.IsChecked == true) {
                                checkedDebitOfInvoiceList.splice(key2, 1);
                                checkedDebitOfInvoiceList.push(checkedDebitOfInvoice);
                            } else {
                                addedNewBill++;
                                if (addedNewBill < 2) {
                                    checkedDebitOfInvoiceList.push(checkedDebitOfInvoice);
                                }
                            }
                        });
                    } else {
                        checkedDebitOfInvoiceList.push(checkedDebitOfInvoice);
                    }
                });
            } else {
                angular.forEach($scope.Vouchers.VoucherList, function (value, key) {
                    $scope.Vouchers.VoucherList[key].IsChecked = false;
                    if (checkedDebitOfInvoiceList.length > 0) {
                        angular.forEach(checkedDebitOfInvoiceList, function (value2, key2) {
                            if (value2.VoucherId == value.VoucherId) {
                                checkedDebitOfInvoiceList.splice(key2, 1);
                            }
                        });
                    }
                });
            }
        };

        //Aktuális számla kiválasztása
        $scope.ActCheckedDebitOfInvoice = function (index) {
            if (index.IsChecked == true) {
                var checkedDebitOfInvoice = { VoucherId: index.VoucherId, IsChecked: true };
                var addedNewBill = 0;
                if (checkedDebitOfInvoiceList.length > 0) {
                    angular.forEach(checkedDebitOfInvoiceList, function (value, key) {
                        if (value.VoucherId == index.VoucherId && value.IsChecked == true) {
                            checkedDebitOfInvoiceList.splice(key, 1);
                            checkedDebitOfInvoiceList.push(checkedDebitOfInvoice);
                        } else {
                            addedNewBill++;
                            if (addedNewBill < 2) {
                                checkedDebitOfInvoiceList.push(checkedDebitOfInvoice);
                            }
                        }
                    });
                } else {
                    checkedDebitOfInvoiceList.push(checkedDebitOfInvoice);
                }
            } else {
                if (checkedDebitOfInvoiceList.length > 0) {
                    angular.forEach(checkedDebitOfInvoiceList, function (value, key) {
                        if (value.VoucherId == index.VoucherId) {
                            checkedDebitOfInvoiceList.splice(key, 1);
                            $scope.AllCheckedInvoice = false;
                        }
                    });
                }
            }

            if (checkedDebitOfInvoiceList.length > 0) {
                var addedNewBill = 0;
                angular.forEach(checkedDebitOfInvoiceList, function (value, key) {
                    angular.forEach($scope.Vouchers.VoucherList, function (value2, key2) {
                        if (value.VoucherId == value2.VoucherId && value.IsChecked == true) {
                            $scope.Vouchers.VoucherList[key2].IsChecked = true;
                            addedNewBill++;
                        }
                    });
                });

                if (addedNewBill == $scope.Vouchers.VoucherList.length) {
                    $scope.AllCheckedInvoice = true;
                }
            }
        };

        $scope.AllCheckedInvoice = false;

        //Email sablon törlése
        $scope.deleteEmailTemplate = function (index) {
            if (confirm("Valóban törölni akarja a sablont?")) {
                $http.delete(apiurl.email.deleteEmailTemplateUrl + index.Id).success(function () {
                    toasterService.successToast("Törlés", "Az email sablon sikeresen törölve!");
                    $scope.EmailTemplates.splice(index, 1);
                    $scope.searchFunction();
                    $scope.Refresh();
                }).error(function (data, status) {
                    toasterService.errorFromServerToast(data);
                });
            }
        };

        //Email sablon visszanyitása
        $scope.getEmailTemplate = function (index) {
            if (editEmailTemplate === true) {
                if ($scope.disabledElement === true) {
                    return;
                } else {
                    $scope.PanelTitle = "Sablon kezelése";
                    var setDynamicHTMLElement = true;
                    $http.get(apiurl.email.getEmailTemplateUrl + index.Id + '/' + setDynamicHTMLElement).success(function (data) {
                        $scope.emailTemplateData = data.EmailTemplate;
                        $scope.hideEditEmailTemplate = true;
                        //Email típus beállítása
                        $scope.emailTypes = data.EmailTemplateTypes;
                        angular.forEach($scope.emailTypes, function (value, key) {
                            if (value.Id === $scope.emailTemplateData.TypeId) {
                                $scope.emailType = value;
                                $scope.CheckDynamicHTMLElements($scope.emailType);
                            }
                        });
                    }).error(function (data, status) {
                        toasterService.errorFromServerToast(data);
                    });
                }
            }
        };

        //Email sablon visszanyitása módosításra
        $scope.editEmailTemplate = function (index) {
            $scope.PanelTitle = "Sablon szerkesztése";
            var setDynamicHTMLElement = false;
            $http.get(apiurl.email.getEmailTemplateUrl + index.Id + '/' + setDynamicHTMLElement).success(function (data) {
                $scope.emailTemplateData = data.EmailTemplate;
                $scope.hideEditEmailTemplate = false;
                //Email típus beállítása
                $scope.emailTypes = data.EmailTemplateTypes;
                angular.forEach($scope.emailTypes, function (value, key) {
                    if (value.Id === $scope.emailTemplateData.TypeId) {
                        $scope.emailType = value;
                        $scope.CheckDynamicHTMLElements($scope.emailType);
                    }
                });
                $scope.disabledElement = true;
                editTemplate = true;
            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });
        };

        //Dinamikus elemek kezelése
        $scope.CheckDynamicHTMLElements = function (emailType) {
            switch (emailType.Id) {
                case DynamicHTMLElement.generalyType:
                    $scope.toContact = false;
                    $scope.table = true;
                    $scope.price = true;
                    break;
                case DynamicHTMLElement.demandForPaymentType:
                    $scope.toContact = false;
                    $scope.table = false;
                    $scope.price = false;
                    break;
            }
        };

        var DynamicHTMLElement =
        {
            //email típusok
            generalyType: 51, //általános
            demandForPaymentType: 52 //fizetési felszólítás
        }

        //Dinamikus elemek eseményei
        $scope.AddToContact = function () {
            if ($scope.emailTemplateData.Content == null) {
                $scope.emailTemplateData.Content = "";
            }
            $scope.emailTemplateData.Content += " [#Name#]";
        };

        $scope.AddToTable = function () {
            if ($scope.emailTemplateData.Content == null) {
                $scope.emailTemplateData.Content = "";
            }
            $scope.emailTemplateData.Content += " [#Table#]";
        };

        $scope.AddToPrice = function () {
            if ($scope.emailTemplateData.Content == null) {
                $scope.emailTemplateData.Content = "";
            }
            $scope.emailTemplateData.Content += " [#Price#]";
        };

        //oldal frissítése
        $scope.Refresh = function () {
            $scope.RefreshData();
            $scope.PanelTitle = "Sablon";
        };

        $scope.RefreshData = function () {
            editTemplate = false;
            $scope.emailTemplateData = null;
            $scope.emailType = $scope.emailTypes[0]; //alapértelmezett
            $scope.CheckDynamicHTMLElements($scope.emailType);
        };

        $scope.backPage = function () {
            if (editEmailTemplate === true) {
                if (editTemplate) {
                    if (!confirm("Elveti a módosításokat?")) {
                        return;
                    } else {
                        window.location.href = siteurl.editEmailTemplate;
                    }
                } else {
                    window.location.href = backUrl == siteurl.editcomplain ? backUrl + '/' + complainId : backUrl.substring(0, backUrl.lastIndexOf("/")) == siteurl.editContactInteraction ? backUrl.substring(0, backUrl.lastIndexOf("/")) + '/' + contactInteractionId : backUrl;
                }
            } else {
                window.location.href = backUrl == siteurl.editcomplain ? backUrl + '/' + complainId : backUrl.substring(0, backUrl.lastIndexOf("/")) == siteurl.editContactInteraction ? backUrl.substring(0, backUrl.lastIndexOf("/")) + '/' + contactInteractionId : backUrl;
            }
        };

        //Email összeállítása
        $scope.loadCompany = function (query) {
            if (commonEditComplain == true) { //ha a reklamációból jüvünk, akkor az ügyfeleket nem töltjük be
                return null;
            } else {
                return $http.get(apiurl.email.getPartnerNamesUrl + query);
            }
        }

        //Címzett hozzáadása
        $scope.companyTagAdded = function (tag) {
            if (tag.Id != null) {
                $http.get(apiurl.email.getEmailAddressByPartnerUrl + tag.Id).success(function (data) {
                    //Címzettek email címeinek eltárolása
                    var index = $scope.Recipients.length;
                    while (index > 0) {
                        index--;
                        if ($scope.Recipients[index].Id === tag.Id) {
                            $scope.Recipients.splice(index, 1);
                        }
                    }
                    angular.forEach(data, function (value, key) {
                        var emailAddress = { Id: tag.Id, Name: value }; //itt az Id = CompanyId-val
                        $scope.Recipients.push(emailAddress);
                    });

                    //Címzettek eltárolása
                    var index = Partners.length;
                    while (index > 0) {
                        index--;
                        if (Partners[index].Id === tag.Id && Partners[index].Name === tag.Name) {
                            Partners.splice(index, 1);
                        }
                    }
                    var partner = { Id: tag.Id, Name: tag.Name };
                    Partners.push(partner);
                }).error(function (data, status) {
                    toasterService.errorFromServerToast(data);
                });
            } else {
                //kötőjel kiszűrése a címzett nevéből
                var email = tag.Name.split("<")[1];
                var partner = tag.Name.split("<")[0]
                while (partner.indexOf('-') !== -1) {
                    partner = partner.replace('-', ' ');
                }
                tag.Name = partner + "<" + email;
                if (tag.Name.indexOf("<") === -1 || tag.Name.indexOf(">") === -1 || tag.Name.split("<")[1].split(">")[0] === "" || isEmail(tag.Name.split("<")[1].split(">")[0]) == false) {
                    alert("Érvénytelen email cím!\nA címzett neve mögé <> karakter között írja be az email címet!\nMinta formátum: Teszt Elek <teszt.elek@gmail.com>\n");
                    var index = $scope.Recipients.length;
                    while (index > 0) {
                        index--;
                        if ($scope.Recipients[index].Name === tag.Name) {
                            $scope.Recipients.splice(index, 1);
                        }
                    }
                    return;
                }

                //Címzett kinyerése(a neve)
                if (partner != null && partner != "") {
                    if (partner.lastIndexOf(" ") !== -1) {
                        partner = partner.substring(0, partner.length - 1); //utolsó szóköz karakter levágása
                    }
                    var partnerName = { Name: partner };
                    Partners.push(partnerName);
                }

                var emailAddress = { Name: tag.Name, Unique: true };
                var index = $scope.Recipients.length;
                while (index > 0) {
                    index--;
                    if (tag.Id == null && $scope.Recipients[index].Name === tag.Name) {
                        $scope.Recipients.splice(index, 1);
                    }
                }
                $scope.Recipients.push(emailAddress);
            }
        }

        //Címzett törlése
        $scope.companyTagRemoved = function (tag) {
            if (tag.Id != null) {
                //Címzettek törlése
                var index = Partners.length;
                while (index > 0) {
                    index--;
                    var hasEmailsToPartner = 0; // tartozik-e még mail cím a partnerhez
                    angular.forEach($scope.Recipients, function (value, key) {
                        if (value.Id != null && value.Id === tag.Id) {
                            hasEmailsToPartner++;
                        }
                    });
                    if (hasEmailsToPartner === 0 && Partners[index].Id != null && Partners[index].Id === tag.Id) {
                        Partners.splice(index, 1);
                    }
                }
            } else if (tag.UserId != null) {
                toasterService.warningToast("Figyelem", "A címzett nem törölhető!");
                var emailAddress = { UserId: tag.UserId, Name: tag.Name };
                $scope.Recipients.push(emailAddress);
            } else {
                //Címzetek törlése
                var index = Partners.length;
                while (index > 0) {
                    index--;
                    if (Partners[index].Id === undefined && Partners[index].Name === tag.Name.split(" <")[0]) {
                        Partners.splice(index, 1);
                    }
                }
            }
        }

        //Email cím ellenőrzése
        function isEmail(email) {
            var result = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(email);
            return result;
        }

        //Email cím lista ellenőrzése
        function isEmailList(emailList) {
            var error = false;
            angular.forEach(emailList, function (email, key) {
                if (email.Name.indexOf("<") !== -1 && email.Name.indexOf(">") !== -1) {
                    var result = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(email.Name.split("<")[1].split(">")[0]);
                } else {
                    var result = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(email.Name);
                }
                if (result === false) {
                    emailList[key].IsValid = false;
                    error = true;
                }
            });
            return error;
        }

        //A címzettek Dto összeállítása
        function GetRecipients(Recipients) {
            var recipient = [];
            angular.forEach(Recipients, function (value, key) {
                if (commonEditComplain == true) {
                    if (value.Unique == true) { //egyedi email cím
                        var actRecipient = { CompanyId: null, UserId: 0, recipients: value.Name };
                        recipient.push(actRecipient);
                    } else {
                        var actRecipient = { CompanyId: null, UserId: value.UserId, recipients: value.Name };
                        recipient.push(actRecipient);
                    }
                } else {
                    if (value.Unique == true) { //egyedi email cím
                        var actRecipient = { CompanyId: 0, UserId: null, recipients: value.Name };
                        recipient.push(actRecipient);
                    } else {
                        var actRecipient = { CompanyId: value.Id, UserId: null, recipients: value.Name };
                        recipient.push(actRecipient);
                    }
                }
            });
            return recipient;
        }

        $scope.emailPreviewCurrentPage = 0;
        $scope.emailPreviewPageSize = 1;
        var emailPreviewList = [];

        //Email küldésnél az oldalak lapozása
        $scope.$watch("emailPage", function () {
            if ($scope.emailPage == 1) {
                $scope.NavbarTitle = "Alapadatok megadása";
            } else if ($scope.emailPage == 2) {
                $scope.NavbarTitle = "Tartozásos számlák kiválasztása";
            } else {
                $scope.NavbarTitle = "Küldés befejezése";
                EmailPreviewView();
            }
        });

        //Email előnézeti kép
        EmailPreviewView = function () {
            emailData.Sender = $scope.user.UserEmail;
            emailData.EmailTemplateId = $scope.actEmailSablon.Id;
            emailData.Body = $scope.actEmailSablon.Content;
            emailData.Recipients = [];;
            emailData.Recipients = GetRecipients($scope.Recipients);
            emailData.Subject = $scope.EmailSubject;
            if (emailData.Body.indexOf("[#Table#]") != -1) {
                angular.forEach(checkedDebitOfInvoiceList, function (value, key) {
                    if (checkedDebitOfInvoiceList[key].IsChecked == true) {
                        emailData.CheckedDebitOfInvoiceIdList.push(value.VoucherId);
                    }
                });
            }

            $http.post(apiurl.email.getEmailPreviewViewUrl, emailData).success(function (data) {
                $scope.EmailPreviewShow = true;
                emailPreviewList = data;
                $scope.emailPreviewTotalItems = data.length;
                emailPreviewFinish(emailPreviewList);

                //Az összes számlaid-k kitörlése
                var index = emailData.CheckedDebitOfInvoiceIdList.length;
                while (index > 0) {
                    index--;
                    emailData.CheckedDebitOfInvoiceIdList.splice(index, 1);
                }
            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });
        };

        //email sablonok megjelenítése
        var emailPreviewFinish = function (data) {
            $scope.previewEmailList = [];
            angular.forEach(data, function (value, key) {
                if ($scope.emailPreviewCurrentPage == key) {
                    $scope.previewEmailList.push(value);
                }
            });
        };

        //Email előnézeti képek lapozása -> kliens oldalon történik
        $scope.emailPreviewPageChanged = function () {
            $scope.emailPreviewCurrentPage = $scope.emailPreviewCurrent - 1;
            emailPreviewFinish(emailPreviewList);
        };


        // Oldal léptetés email küldésnél
        $scope.emailPage = 1;
        $scope.emailPageFoward = function () {
            if ($scope.emailPage < 3) {
                if ($scope.actEmailSablon.TypeName == 'Fizetési felszólítás') {
                    $scope.emailPage = $scope.emailPage + 1;
                } else {
                    $scope.emailPage = $scope.emailPage + 2;
                }
            }
        };
        $scope.emailPageBack = function () {
            if ($scope.emailPage > 1) {
                if ($scope.actEmailSablon.TypeName == 'Fizetési felszólítás') {
                    $scope.emailPage = $scope.emailPage - 1;
                } else {
                    $scope.emailPage = $scope.emailPage - 2;
                }
            }
        };

        //Email küldése
        $scope.send = function () {
            if (editEmailTemplate === false) {
                if ($scope.Validate($scope.emailTemplateData) === true) {
                    var message = confirm("Biztos, hogy elküldi az emailt?");
                    if (!message) {
                        return;
                    }
                    if ($scope.EmailSubject == null) {
                        $scope.EmailSubject = "";
                    }
                    //EmailDto összeállítása
                    emailData.Sender = $scope.user.UserEmail;
                    emailData.EmailTemplateId = $scope.actEmailSablon.Id;
                    emailData.Recipients = GetRecipients($scope.Recipients);
                    emailData.Subject = $scope.EmailSubject;
                    emailData.Body = $scope.actEmailSablon.Content;
                    if (emailData.Body.indexOf("[#Table#]") != -1) {
                        angular.forEach(checkedDebitOfInvoiceList, function (value, key) {
                            if (checkedDebitOfInvoiceList[key].IsChecked == true) {
                                emailData.CheckedDebitOfInvoiceIdList.push(value.VoucherId);
                            }
                        });
                    }

                    //Header loader start
                    usSpinnerService.spin('headerLoader');
                    $http.post(apiurl.email.sendEmailUrl, emailData).success(function (data) {
                        //Header loader stop
                        usSpinnerService.stop('headerLoader');
                        alert("Email sikeresen elküldve!");
                        //Reklamációból jöttünk, akkor eltároljuk azokat a munkatársakat amelyeknek kiküldtük az emailt
                        if (commonEditComplain == true) {
                            var userIdList = [];
                            angular.forEach(emailData.Recipients, function (value, key) {
                                if (value.UserId != null && value.UserId != 0) {
                                    var data = { userId: value.UserId };
                                    userIdList.push(data);
                                }
                            });
                            $localStorage.confirmedUserIdList = userIdList;
                        }
                        //A localStoragebe való adat eltárolás lassabban fut meg, mint a visszanavigálás
                        setTimeout(function () {
                            $scope.backPage();
                        }, 1000); //várok 1mp-et

                    }).error(function (data, status) {
                        //Header loader stop
                        usSpinnerService.stop('headerLoader');
                        toasterService.errorFromServerToast(data);
                    });
                }
            }
        };
    }

})();
