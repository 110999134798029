(function () {

    angular.module('IMasterApp')
        .service("deliveryNoteService", deliveryNoteService)
        .controller("DeliveryNotesCtrl", DeliveryNotesCtrl);

    deliveryNoteService.$inject = ['$http', 'apiurl'];
    function deliveryNoteService($http, apiurl) {

        function getDeliveryNotesForPartner(id, isTakeIn) {
            return $http.get(apiurl.stock.deliveryByCompanyId + '/' + id + '/' + isTakeIn);
        }

        function getPartners(partner) {
            return data = $http.get(apiurl.stock.getCompanies + "/" + partner + "/" + "false");
        }

        return {
            getDeliveryNotesForPartner: getDeliveryNotesForPartner,
            getPartners: getPartners
        }
    }

    DeliveryNotesCtrl.$inject = ['$scope', 'deliveryNoteService', 'toasterService', 'siteurl'];
    function DeliveryNotesCtrl($scope, deliveryNoteService, toasterService, siteurl) {
        // inicializálás
        function constuctor() {
            $scope.GridVisibility = false;
            $scope.allDeliveryNoteChecked = false;
            $scope.selectPartner = [];
            $scope.deliveryNotesList = [];
            $scope.isTakeIn = false;
        }
        constuctor();

        // Partneradatok lekérése 
        function getPartners() {
            if (angular.isString($scope.selectedPartner) && $scope.selectedPartner.length > 2) {
                deliveryNoteService.getPartners($scope.selectedPartner).success(function (data) {
                    $scope.PartnerList = data;
                })
                .error(function () {
                    toasterService.errorToast("", "A partnerek lekérése sikertelen");
                });
            }
        }

        // Az ügyfél mező figyelése 
        // - valóban ki van-e választva valami
        // - Partneradatok lekérése, ha getPartners függvényben lévő feltételeknek megfelel
        $scope.$watch('selectedPartner', function () {
            getPartners();

            if (!angular.isObject($scope.selectedPartner))
                $scope.GridVisibility = false;
            else
                $scope.GridVisibility = true;
        });

        // Partner kiválasztása és a hozzátartozó szállítólevelek lekérése
        $scope.selectPartner = function (partner) {
            // Partnerhez tartozó szállítólevelek lekérése
            deliveryNoteService.getDeliveryNotesForPartner(partner.Id, $scope.isTakeIn).success(function (data) {
                $scope.deliveryNotesList = data;
            }).error(function (error) {
                toasterService.errorToast(error, "Az adatok lekérése sikertelen!");
            });
        }

        $scope.isTakeInChanged = function () {
            if (!angular.isString($scope.selectedPartner)) {
                $scope.selectPartner($scope.selectedPartner);
            }
        };

        // Szállítólevelek táblázat összesn checkbox kijelölése gomb
        $scope.allDeliveryNoteCheck = function () {
            if ($scope.allDeliveryNoteChecked === true) {
                angular.forEach($scope.deliveryNotesList, function (deliveryNote, key) {
                    $scope.deliveryNotesList[key].IsSelected = true;
                });
            }
            else {
                angular.forEach($scope.deliveryNotesList, function (deliveryNote, key) {
                    $scope.deliveryNotesList[key].IsSelected = false;
                });
            }
        }

        // Számla generálása gomb
        $scope.generateTweakButton = function () {
            $scope.sendNoteList = [];

            angular.forEach($scope.deliveryNotesList, function (deliveryNote) {
                if (!angular.isUndefined(deliveryNote.IsSelected) && deliveryNote.IsSelected === true) {
                    $scope.sendNoteList.push(deliveryNote.VoucherId);
                }
            });

            if (!angular.isUndefined($scope.sendNoteList) && $scope.sendNoteList.length !== 0) {
                localStorage.setItem('sendNoteList', JSON.stringify($scope.sendNoteList));
                localStorage.setItem('partnerId', JSON.stringify($scope.selectedPartner.Id));
                $scope.isTakeIn ? window.location.href = siteurl.receipt : window.location.href = siteurl.tweak;
            }
            else
                //alert("A számla generálásához nincs kijelölve elem!");
                toasterService.warningToast("", "A számla generálásához nincs kijelölve elem!");

        }
    }
})();
