(function () {

    angular.module('IMasterApp')
        .controller('RemoteDevicesController', RemoteDevicesController);

    RemoteDevicesController.$inject = ['$scope', '$http', '$filter', 'siteurl', 'apiurl', 'toasterService', 'usSpinnerService'];
    function RemoteDevicesController($scope, $http, $filter, siteurl, apiurl, toasterService, usSpinnerService) {

        $scope.remoteDevices = [];
        $scope.showRemoteDevices = [];
        $scope.searchedList = [];

        $scope.search = {
            TypeName: '',
            IDNR: '',
            CompanyName: '',
            Address: ''
        };

        $scope.warningLevel = 7;

        var spinnerActive = false;

        $scope.pageSize = 25;
        $scope.current = 1;
        $scope.totalItems = 0;
        var tempProperty = "";
        var orderDirection = false;

        var startSpin = function () {
            if (!spinnerActive) {
                usSpinnerService.spin('spinner-2');
                spinnerActive = true;
            }
        };

        var stopSpin = function () {
            if (spinnerActive) {
                usSpinnerService.stop('spinner-2');
                spinnerActive = false;
            }
        };

        var setStatus = function () {
            var today = new Date();
            var warningDate = today.setDate(today.getDate() + $scope.warningLevel);
            today = new Date();
            var dangerDate = today.setDate(today.getDate());
            for (var i = 0; i < $scope.remoteDevices.length; i++) {
                $scope.remoteDevices[i].statusColour = "Default";
                $scope.remoteDevices[i].statusLevel = 3;
                if (Date.parse($scope.remoteDevices[i].Cyan) <= warningDate || Date.parse($scope.remoteDevices[i].Magenta) <= warningDate || Date.parse($scope.remoteDevices[i].Yellow) <= warningDate || Date.parse($scope.remoteDevices[i].Black) <= warningDate) {
                    $scope.remoteDevices[i].statusColour = "Warning";
                    $scope.remoteDevices[i].statusLevel = 2;
                }
                if (Date.parse($scope.remoteDevices[i].Cyan) <= dangerDate || Date.parse($scope.remoteDevices[i].Magenta) <= dangerDate || Date.parse($scope.remoteDevices[i].Yellow) <= dangerDate || Date.parse($scope.remoteDevices[i].Black) <= dangerDate) {
                    $scope.remoteDevices[i].statusColour = "Delete";
                    $scope.remoteDevices[i].statusLevel = 1;
                }
            }

            $scope.remoteDevices = $filter('orderBy')($scope.remoteDevices, "statusLevel");
        };

        var loadRemoteDevices = function () {
            $(document).ready(function () {
                startSpin();
            });
            $http.get(apiurl.remoteDevice.getRemoteDevices).success(function (data) {
                $scope.remoteDevices = data;
                setStatus();
                $scope.totalItems = data.length;
                $scope.showRemoteDevices = Enumerable.From($scope.remoteDevices).Skip(($scope.current - 1) * $scope.pageSize).Take($scope.pageSize).ToArray();
                stopSpin();
            }).error(function () {
                toasterService.errorToast("Hiba történt!", "Sikertelen adatlekerés");
                stopSpin();
            });
        };

        loadRemoteDevices();

        $scope.searchChanged = function () {
            $scope.current = 1;
            $scope.filterSearch();
        };

        $scope.pageChanged = function () {
            $filter('filter')($scope.remoteDevices, $scope.search);
            $scope.showRemoteDevices = Enumerable.From($filter('filter')($scope.remoteDevices, $scope.search)).Skip(($scope.current - 1) * $scope.pageSize).Take($scope.pageSize).ToArray();
        };

        var sortNotDates = function (a, b) {
            return orderDirection === true ? a[tempProperty] > b[tempProperty] ? 1 : -1 : a[tempProperty] < b[tempProperty] ? 1 : -1;
        };
        var sortDates = function (a, b) {
            return orderDirection === true ? new Date(a[tempProperty]) > new Date(b[tempProperty]) ? 1 : -1 : new Date(a[tempProperty]) < new Date(b[tempProperty]) ? 1 : -1;
        };

        $scope.orderByItems = function (property, isFromSurface) {
            if (isFromSurface === true) {
                if (property === tempProperty) {
                    orderDirection = !orderDirection;
                } else {
                    orderDirection = true;
                    tempProperty = property;
                }
            }

            if (tempProperty !== "") {
                if (tempProperty === "Cyan" || tempProperty === "Magenta" || tempProperty === "Yellow" || tempProperty === "Black" || tempProperty === "LasModificationDate") {
                    $scope.remoteDevices.sort(sortDates);
                } else {
                    $scope.remoteDevices.sort(sortNotDates);
                }
            }

            $scope.pageChanged();
        };

        // New DatePicker settings
        $scope.today = function () {
            $scope.prdate2 = new Date();
            $scope.prdate2 = $filter('date')($scope.prdate2, 'yyyy.MM.dd');
        };
        $scope.today();

        $scope.clear = function () {
            $scope.prdate2 = null;
        };

        $scope.prdate2format = "yyyy.MM.dd";

        $scope.datePickers = {
            fromLastDate: { isOpen: false, date: "" },
            toLastDate: { isOpen: false, date: "" }
        };

        $scope.dtopen = function ($event, datePicker) {
            $event.preventDefault();
            $event.stopPropagation();

            if (!datePicker) {
                $scope.dtopened = true;

            } else {
                datePicker.isOpen = true;
            }
        };

        $scope.dtoptions = {
            startingDay: 1
        };

        //Dátum szűrő
        var dateFilter = function (lowerColors, fromLastDate, toLastDate) {

            if (fromLastDate !== '' && toLastDate !== '') {
                if (lowerColors.Cyan >= fromLastDate && lowerColors.Cyan <= toLastDate
                    || lowerColors.Magenta >= fromLastDate && lowerColors.Magenta <= toLastDate
                    || lowerColors.Yellow >= fromLastDate && lowerColors.Yellow <= toLastDate
                    || lowerColors.Black >= fromLastDate && lowerColors.Black <= toLastDate) {

                    return true;
                }
            } else if (fromLastDate !== '') {
                if (lowerColors.Cyan >= fromLastDate
                    || lowerColors.Magenta >= fromLastDate
                    || lowerColors.Yellow >= fromLastDate
                    || lowerColors.Black >= fromLastDate) {

                    return true;
                }
            } else if (toLastDate !== '') {
                if (lowerColors.Cyan <= toLastDate
                    || lowerColors.Magenta <= toLastDate
                    || lowerColors.Yellow <= toLastDate
                    || lowerColors.Black <= toLastDate) {

                    return true;
                }
            } else {

                return true;
            }

            return false;
        };

        //szűrő kereső
        $scope.filterSearch = function () {
            var searchedList = [];
            var fromLastDate = $scope.datePickers.fromLastDate.date != null && $scope.datePickers.fromLastDate.date !== '' ? $filter('date')($scope.datePickers.fromLastDate.date, 'yyyy.MM.dd') : '';
            var toLastDate = $scope.datePickers.toLastDate.date != null && $scope.datePickers.toLastDate.date !== '' ? $filter('date')($scope.datePickers.toLastDate.date, 'yyyy.MM.dd') : '';
            // Leszűrjük a listát az adott feltételeknek megfelelően
            if (!angular.isUndefined($scope.search.$) && $scope.search.$ != null && $scope.search.$ !== "") {
                // a kapott sztringet kisbetűsre alakítjuk
                var searchArray = $scope.search.$.toLowerCase();
                angular.forEach($scope.remoteDevices, function (item) {
                    //hozzá lett-e már adva az elem a listához
                    var addedElement = false;
                    // Kisbetűsre alakítjuk az adott típust is
                    var lowerCaseTypeName = item.TypeName.toLowerCase();
                    // Kisbetűsre alakítjuk az adott INDR számot is
                    var lowerCaseIdnr = item.IDNR.toLowerCase();
                    // Kisbetűsre alakítjuk az adott partner nevét is
                    var lowerCompanyName = item.CompanyName.toLowerCase();
                    // Kisbetűsre alakítjuk az adott címet is
                    var lowerAddress = item.Address.toLowerCase();

                    var lowerColors = {
                        Cyan: $filter('date')(item.Cyan, 'yyyy.MM.dd'),
                        Magenta: $filter('date')(item.Magenta, 'yyyy.MM.dd'),
                        Yellow: $filter('date')(item.Yellow, 'yyyy.MM.dd'),
                        Black: $filter('date')(item.Black, 'yyyy.MM.dd')
                    };

                    //Típus egyezésnél
                    if (lowerCaseTypeName.indexOf(searchArray) !== -1) {

                        if (dateFilter(lowerColors, fromLastDate, toLastDate)) {
                            addedElement = true;
                            searchedList.push(item);
                        }
                    }

                    //IDNR szám egyezésnél
                    if (lowerCaseIdnr.indexOf(searchArray) !== -1 && !addedElement) {
                        if (dateFilter(lowerColors, fromLastDate, toLastDate)) {
                            addedElement = true;
                            searchedList.push(item);
                        }
                    }

                    //Partner név egyezésnél
                    if (lowerCompanyName.indexOf(searchArray) !== -1 && !addedElement) {
                        if (dateFilter(lowerColors, fromLastDate, toLastDate)) {
                            addedElement = true;
                            searchedList.push(item);
                        }
                    }

                    //Cím egyezésnél
                    if (lowerAddress.indexOf(searchArray) !== -1 && !addedElement) {
                        if (dateFilter(lowerColors, fromLastDate, toLastDate)) {
                            searchedList.push(item);
                        }
                    }
                });
            } else {

                angular.forEach($scope.remoteDevices, function (item) {

                    var lowerColors = {
                        Cyan: $filter('date')(item.Cyan, 'yyyy.MM.dd'),
                        Magenta: $filter('date')(item.Magenta, 'yyyy.MM.dd'),
                        Yellow: $filter('date')(item.Yellow, 'yyyy.MM.dd'),
                        Black: $filter('date')(item.Black, 'yyyy.MM.dd')
                    };

                    if (dateFilter(lowerColors, fromLastDate, toLastDate)) {
                        searchedList.push(item);
                    }
                });
            }
            $scope.searchedList = searchedList;
            $scope.showRemoteDevices = Enumerable.From(searchedList).Skip(($scope.current - 1) * $scope.pageSize).Take($scope.pageSize).ToArray();
            $scope.totalItems = searchedList.length;
        };
        $scope.deleteSearch = function () {
            $scope.datePickers.fromLastDate.date = '';
            $scope.datePickers.toLastDate.date = '';
            $scope.filterSearch();
        };

        $scope.remoteDeviceDetails = function (id) {
            window.location = siteurl.remoteDevice + '/' + id;
        };

        $scope.newRemoteDevice = function () {
            window.location = siteurl.editRemoteDevice + '/0';
        };

        $scope.editRemoteDevice = function (id) {
            window.location = siteurl.editRemoteDevice + '/' + id;
        };

        $scope.downloadDataCollector = function () {
            window.location = apiurl.remoteDevice.downloadApp;
        };

        $scope.deleteRemoteDevice = function (remoteDevice) {
            if (confirm("Biztos, hogy törli a " + remoteDevice.TypeName + " eszközt?")) {
                $http.delete(apiurl.remoteDevice.deleteRemoteDevice + remoteDevice.Id).success(function () {
                    //Listákból való törlés
                    var removeElementIndex = $scope.remoteDevices.indexOf(remoteDevice);
                    $scope.remoteDevices.splice(removeElementIndex, 1);
                    $scope.showRemoteDevices.splice(removeElementIndex, 1);
                    toasterService.successToast("Törlés", "Sikeres törlés!");
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            }
        };

        //Exportálás excelbe
        $scope.exportTable = function () {
            startSpin();
            $http({
                method: 'POST',
                url: apiurl.remoteDevice.exportAllDevices,
                data: $scope.searchedList.length === 0 ? $scope.remoteDevices : $scope.searchedList,
                headers: {
                    'Content-type': 'application/json'
                },
                responseType: 'arraybuffer'
            }).success(function (dataPdf) {
                var blob = new Blob([dataPdf], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                var actDate2 = $filter('date')(new Date(), 'yyyy.MM.dd.');
                navigator.saveBlob = navigator.saveBlob || navigator.msSaveOrOpenBlob || navigator.mozSaveBlob || navigator.webkitSaveBlob;
                window.saveAs = window.saveAs || window.webkitSaveAs || window.mozSaveAs || window.msSaveAs;
                if (window.saveAs) {

                    window.saveAs(blob, actDate2 + "Eszkoz_allapot_lista.xlsx");
                } else if (navigator.saveBlob) {

                    navigator.saveBlob(blob, actDate2 + "Eszkoz_allapot_lista.xlsx");
                } else {

                    var url = URL.createObjectURL(blob);
                    var link = document.createElement("a");
                    link.setAttribute("href", url);
                    link.setAttribute("download", actDate2 + "Eszkoz_allapot_lista.xlsx");
                    var event = document.createEvent('MouseEvents');
                    event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                    link.dispatchEvent(event);
                }
                stopSpin();
            });
        };

        $scope.exportData = function () {

            var idList = [];
            for (var i = 0; i < $scope.remoteDevices.length; i++) {
                if ($scope.remoteDevices[i].isSelected) {
                    idList.push($scope.remoteDevices[i].Id);
                }
            }
            if (idList.length === 0) {
                toasterService.warningToast("Figyelem!", "Nem választott ki elemeket!");
                return;
            }

            var dataToSend = { toDate: $filter('date')($scope.datePickers.toLastDate.date, 'yyyy.MM.dd.'), fromDate: $filter('date')($scope.datePickers.fromLastDate.date, 'yyyy.MM.dd.'), remoteDeviceIdList: idList };
            $http({
                method: 'POST',
                url: apiurl.remoteDevice.exportRemoteDeviceDataWithSumColorCount,
                data: dataToSend,
                headers: {
                    'Content-type': 'application/json'
                },
                responseType: 'arraybuffer'
            }).success(function (dataPdf) {
                var blob = new Blob([dataPdf], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                var actDate2 = $filter('date')(new Date(), 'yyyy.MM.dd.');
                navigator.saveBlob = navigator.saveBlob || navigator.msSaveOrOpenBlob || navigator.mozSaveBlob || navigator.webkitSaveBlob;
                window.saveAs = window.saveAs || window.webkitSaveAs || window.mozSaveAs || window.msSaveAs;
                if (window.saveAs) {

                    window.saveAs(blob, actDate2 + "Eszkoz_allapot_lista.xlsx");

                } else if (navigator.saveBlob) {

                    navigator.saveBlob(blob, actDate2 + "Eszkoz_allapot_lista.xlsx");
                } else {
                    var url = URL.createObjectURL(blob);
                    var link = document.createElement("a");

                    link.setAttribute("href", url);
                    link.setAttribute("download", actDate2 + "Eszkoz_allapot_lista.xlsx");
                    var event = document.createEvent('MouseEvents');
                    event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                    link.dispatchEvent(event);
                }
            });
        };

        $scope.allSelectChanged = function () {
            for (var i = 0; i < $scope.remoteDevices.length; i++) {

                $scope.remoteDevices[i].isSelected = $scope.allSelected;
            }

        };

        $("[name='remoteDevicesCheckbox']").bootstrapSwitch({
            size: 'small'
        });
        $("[name='remoteDevicesCheckbox']").on('switchChange.bootstrapSwitch', function (event, state) {
            if (state === false) {
                $http.get(apiurl.remoteDevice.getRemoteDevices).success(function (data) {
                    $scope.remoteDevices = data;
                    setStatus();
                    $scope.totalItems = data.length;
                    $scope.showRemoteDevices = Enumerable.From($scope.remoteDevices).Skip(($scope.current - 1) * $scope.pageSize).Take($scope.pageSize).ToArray();
                });
            } else {
                $http.get(apiurl.remoteDevice.getRemoteDevicesUnderTenPercent).success(function (data) {
                    $scope.remoteDevices = data;
                    setStatus();
                    $scope.totalItems = data.length;
                    $scope.showRemoteDevices = Enumerable.From($scope.remoteDevices).Skip(($scope.current - 1) * $scope.pageSize).Take($scope.pageSize).ToArray();
                }).error(function () {
                });
            }
        });
    }

})();
