(function () {

    angular.module('IMasterApp')
        .controller("TakeOutController", TakeOutController);

    TakeOutController.$inject = ['$scope', '$uibModal', '$log', '$http', '$window', '$filter', 'siteurl', 'apiurl', 'PriceCounter', 'toasterService', 'userService', 'warehouseService', 'taxRateService'];
    function TakeOutController($scope, $uibModal, $log, $http, $window, $filter, siteurl, apiurl, PriceCounter, toasterService, userService, warehouseService, taxRateService) {

        $scope.items = ['item1', 'item2', 'item3'];

        $scope.open = function () {

            var modalInstance = $uibModal.open({
                templateUrl: 'receiptModalContent.html',
                controller: ModalInstanceCtrl,
                size: 'lg',
                windowClass: 'modal_extlg',
                resolve: {
                    items: function () {
                        return $scope.items;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
            });
        };

        //Load
        $scope.Title = "Felhasználás";

        //Init: User
        var userId = $window.sessionStorage.getItem('userid');

        userService.getUserById(userId).success(function (data) {
            $scope.voucherData.CreationOwner = data.UserFirstname + ' ' + data.UserLastname;
        }).error(function (data) {
            toasterService.errorFromServerToast(data);
        });


        //Init: raktár, warehouse
        var warehouseId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);

        warehouseService.getWarehouseById(warehouseId).success(function (data) {
            $scope.warehouseName = data.Name;
        }).error(function (data) {
            alert(data.Message);
        });

        //INit:Áfa TaxRate
        taxRateService.getAllTaxRate().success(function (data) {
            $scope.TaxRates = data;
        }).error(function (data) {
            toasterService.errorFromServerToast(data);
        });

        //voucher
        $scope.voucherData = {
            WarehouseId: warehouseId,
            VoucherNumber: '',
            CompanyId: '',
            CreatedDate: new Date(),
            Warehouse: '',
            CreationOwner: '',
            CostCenterIdList: [],
            Items: []
        };

        //Egyéb
        $scope.CostCenters = [];
        $scope.actCompany = "";

        $scope.$watch('actCompany', function () {
            $scope.CompanyList = [];
            if ($scope.actCompany != null && !angular.isObject($scope.actCompany) && $scope.actCompany.length > 2) {
                var companyName = $scope.actCompany.replace('.', '');
                $http.get(apiurl.stock.getCompanies + '/' + companyName + '/false').success(function (data) {
                    $scope.CompanyList = data;
                });
            }
        });


        $scope.selectActCompany = function (id) {
            $scope.voucherData.CompanyId = id;
        }

        //Költséghelyek
        $scope.addCostCenterRow = function () {
            $scope.CostCenters.push({
                Id: '',
                Name: ''
            });
        };

        $scope.searchCostCenters = function (costCenterName) {
            $http.get(apiurl.costCenter.getAllCostCenter + costCenterName).then(function (data) {
                return data;
            });
        };

        $scope.selectCostCenter = function (costCenter) {
            $scope.voucherData.CostCenterIdList.push(costCenter.Id);
        };

        $scope.deleteCostCenterRow = function (index) {
            $scope.CostCenters.splice(index, 1);
        };

        //Termékek, produtcts
        $scope.addProductRow = function () {
            $scope.product = {
                Type: $scope.productTypes[0],
                Name: '',
                Quantity: '',
                QuantityType: '',
                NetPrice: '',
                Vat: 27,
                DiscountType: 0,
                DiscountAmount: 0,
                Prefix: '',
                StartNumberOfRange: null,
                EndNumberOfRange: null,
                DiscountTypeName: '%',
                TaxRateId: null,
                PartnerDiscountAmount: null,
                PartnerDiscountAmountTypeId: null
            };

            $scope.voucherData.Items.push($scope.product);
        }

        $scope.deleteProductRow = function (index) {
            $scope.voucherData.Items.splice(index, 1);
        };

        $scope.getProducts = function (productName, index) {
            return $http.get(apiurl.stock.getProductsOfWarehouse + "/" + warehouseId + "/0" + '/' + productName)
                 .then(function (data) {
                     var model = data.data[0];
                     $scope.voucherData.Items[index].TaxRateId = model.TaxRateId;

                     var productCount = data.data.length;
                     if (productCount > 200) {
                         toasterService.warningToast("Figyelem", 'Kérem pontosítsa a termék nevét');
                         return [];
                     }
                     return data.data;
                 });
        };

        $scope.productTypes = productTypes;

        $scope.taOnSelect = function ($model, $index) {

            if ($model.TypeId != 26) {
                toasterService.warningToast("Figyelem", "Csak normál termékeket lehet felhasználásra lemozgatni raktárról.");
                $model.Name = '';
                return;
            }

            angular.forEach($scope.productTypes, function (pt) {
                if ($model.TypeId == pt.Id) {
                    $scope.voucherData.Items[$index].Type = pt;
                    $scope.voucherData.Items[$index].TypeId = pt.Id;
                }
            });

            angular.forEach($scope.TaxRates, function (value, key) {
                if ($scope.voucherData.Items[$index].TaxRateId == value.Id) {
                    $scope.voucherData.Items[$index].TaxRate = $scope.TaxRates[key];
                }
            });

            var netPrice = ($scope.actCompany != null && $scope.actCompany.IsWholesale) ? $model.NetWholesalePrice : $model.NetRetailPrice;

            if ($model.DiscountRate != null) {
                if ($model.DiscountType == 'Ft') {
                    netPrice -= $model.DiscountRate;
                }
                else if ($model.DiscountType == '%') {
                    netPrice *= ((100 - $model.DiscountRate) / 100);
                }
            }

            $scope.voucherData.Items[$index].Name = $model.Name;
            $scope.voucherData.Items[$index].ProductId = $model.Id;
            $scope.voucherData.Items[$index].NetPrice = Number(netPrice.toFixed(2));
            $scope.voucherData.Items[$index].Vat = $model.Vat;
            $scope.voucherData.Items[$index].QuantityTypeId = $model.QuantityTypeId;
            $scope.voucherData.Items[$index].QuantityType = $model.QuantityType;
            $scope.voucherData.Items[$index].TaxRateId = $model.TaxRateId;
            $scope.voucherData.Items[$index].PartnerDiscountAmount = $model.DiscountRate;
            $scope.voucherData.Items[$index].PartnerDiscountTypeId = $model.DiscountType == "%" ? 0 : 1;

            // A kiválasztott termékhez tartozó mennyiségi egységek lekérése
            $http.get(apiurl.stock.getQuantityTypesByProductId + '/' + $model.Id).success(function (data) {
                $scope.voucherData.Items[$index].QuantityTypes = data;

                // Ellenőrizzük, hogy a termék alapértelmezett mennyiségi egysége szerepel-e
                // a termék mennyiségi egységeinek listáján
                var defaults = $filter('filter')(data, { Id: $model.QuantityTypeId });

                // Ha nem szerepel, akkor...
                if (!defaults.length) {
                    // kiválasztjuk az első elemet a választhatóak listájáról
                    if (data.length) {
                        $scope.voucherData.Items[$index].QuantityTypeId = data[0].Id;
                        $scope.voucherData.Items[$index].QuantityType = data[0].Name;
                    }

                    // Ha a válaszható elemek listája üres, de van a terméknek
                    // alapértelmezett mennyiségi egysége, akkor jobb híján azt állítjuk be a comboboxban
                    if (!data.length && $model.QuantityTypeId && $model.QuantityType.length) {
                        var quantity = { Id: $model.QuantityTypeId, Name: $model.QuantityType };
                        $scope.voucherData.Items[$index].QuantityTypes.push(quantity);
                    }
                }
            });
        };

        $scope.quantityTypeOnSelect = function ($index, product) {
            if (product) {
                var selected = $filter('filter')(product.QuantityTypes, { Id: product.QuantityTypeId });

                if (selected.length === 1) {
                    $scope.voucherData.Items[$index].QuantityTypeId = selected[0].Id;
                    $scope.voucherData.Items[$index].QuantityType = selected[0].Name;
                }
            }
        };

        $scope.changeRow = function (index) {
            $scope.voucherData.Items[index].CalculatedNetPrice = $scope.voucherData.Items[index].NetPrice;

            //Alapsori számítások
            if ($scope.voucherData.Items[index].CalculatedNetPrice >= 0) {
                $scope.voucherData.Items[index].GrossPrice = PriceCounter.GrossPrice($scope.voucherData.Items[index].CalculatedNetPrice, $scope.voucherData.Items[index].TaxRate.TaxRateValue);
            }
            if ($scope.voucherData.Items[index].CalculatedNetPrice >= 0 && $scope.voucherData.Items[index].Quantity > 0) {
                $scope.voucherData.Items[index].NetValue = PriceCounter.NetValue($scope.voucherData.Items[index].CalculatedNetPrice, $scope.voucherData.Items[index].Quantity);
                $scope.voucherData.Items[index].GrossValue = PriceCounter.GrossValue($scope.voucherData.Items[index].CalculatedNetPrice, $scope.voucherData.Items[index].Quantity, $scope.voucherData.Items[index].TaxRate.TaxRateValue);
                $scope.voucherData.Items[index].VatValue = PriceCounter.VatValue($scope.voucherData.Items[index].CalculatedNetPrice, $scope.voucherData.Items[index].Quantity, $scope.voucherData.Items[index].TaxRate.TaxRateValue);
            }

            $scope.voucherData.Items[index].TaxRateId = $scope.voucherData.Items[index].TaxRate.Id;

            //Kerekítés és Összesen
            $scope.InvoiceRounding();

            //Áfaösszesítő
            $scope.InvoiceVatSum();
        };

        //ÁFA összesítő
        $scope.InvoiceVatSum = function () {
            $scope.vatSummList = [];
            angular.forEach($scope.products, function (product) {
                var vt = null;
                var vat = 0;
                if ($scope.vatSummList.length == 0 && !isNaN(product.NetValue) && !isNaN(product.VatValue) && !isNaN(product.GrossValue)) {
                    vat = !isNaN(product.TaxRate) || angular.isObject(product.TaxRate) ? product.TaxRate.TaxRateValue : product.Vat;
                    vt = {
                        Vat: vat, NetValue: product.NetValue, VatValue: product.VatValue, GrossValue: product.GrossValue
                    };
                    $scope.vatSummList.push(vt);
                } else {
                    var isIn = false;
                    angular.forEach($scope.vatSummList, function (vtValue) {
                        vat = !isNaN(product.TaxRate) || angular.isObject(product.TaxRate) ? product.TaxRate.TaxRateValue : product.Vat;
                        if (vat == vtValue.Vat && !isNaN(product.NetValue) && !isNaN(product.VatValue) && !isNaN(product.GrossValue)) {
                            vtValue.NetValue += product.NetValue;
                            vtValue.VatValue += product.VatValue;
                            vtValue.GrossValue += product.GrossValue;
                            isIn = true;
                        }
                    });
                    if (isIn === false && !isNaN(product.NetValue) && !isNaN(product.VatValue) && !isNaN(product.GrossValue)) {
                        vat = !isNaN(product.TaxRate) || angular.isObject(product.TaxRate) ? product.TaxRate.TaxRateValue : product.Vat;
                        vt = {
                            Vat: vat, NetValue: product.NetValue, VatValue: product.VatValue, GrossValue: product.GrossValue
                        };
                        $scope.vatSummList.push(vt);
                    }
                }
            });
        };

        //Kerekítés
        $scope.InvoiceRounding = function () {
            $scope.voucherData.RoundingDiff = 0;
            var sumGross = 0;
            angular.forEach($scope.products, function (product) {
                if (!isNaN(product.GrossValue)) {
                    sumGross += product.GrossValue;
                }
            });
            $scope.sumGrossValue = Number((sumGross + $scope.voucherData.RoundingDiff).toFixed(0));
        };

        //Navigálás , navigate
        $scope.saveTakeOut = function () {
            if (confirm("Biztos menteni szeretné?")) {

                if ($scope.voucherData.Items.length === 0) {
                    toasterService.warningToast("Hiba", "Nem menthet, amíg nem adott meg terméke(ke)t!");
                    return;
                }

                $scope.voucherData.CompanyName = $scope.actCompany;

                $scope.voucherData.CostCenterIdList = [];
                angular.forEach($scope.tags, function (tg) {
                    $scope.voucherData.CostCenterIdList.push(tg.Id);
                });

                angular.forEach($scope.voucherData.Items, function (item) {
                    item.LastModified = new Date();
                });

                $http.post(apiurl.expenditure.createExpenditure, $scope.voucherData).success(function () {
                    toasterService.saveSuccess();
                    window.location.href = siteurl.stock;
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            }
        };

        $scope.backButtonClick = function () {
            if (confirm("Biztos, hogy visszalép?")) {
                window.location.href = siteurl.stock;
            }
        };

        $scope.tags = [];
        $scope.loadCostCenters = function (query) {
            return $http.get(apiurl.stock.getCostCenterByName + '/' + query);
        };
    }

    ModalInstanceCtrl.$inject = ['$scope', '$uibModalInstance', 'items'];
    function ModalInstanceCtrl($scope, $uibModalInstance, items) {

        $scope.items = items;
        $scope.selected = {
            item: $scope.items[0]
        };

        $scope.ok = function () {
            $uibModalInstance.close($scope.selected.item);
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
