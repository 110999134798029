(function () {

    angular.module('IMasterApp')
        .controller('familyController', familyController);

    familyController.$inject = ['$scope', '$filter', '$timeout', '$http', 'siteurl', 'toasterService', 'apiurl'];
    function familyController($scope, $filter, $timeout, $http, siteurl, toasterService, apiurl) {
        var tree;
        $scope.isVisibleTable = false;
        $scope.title = "Új eszköz széria";

        var loadTree = function () {
            $scope.treeArray = [];
            $scope.treeArray = $scope.treeArray = [
                { label: 'Canon', isFamily: true, children: [] },
                { label: 'Epson', isFamily: true, children: [] },
                { label: 'HP', isFamily: true, children: [] },
                { label: 'Konica Minolta', isFamily: true, children: [] },
                { label: 'Kyocera', isFamily: true, children: [] },
                { label: 'OCE', isFamily: true, children: [] },
                { label: 'Oki', isFamily: true, children: [] },
                { label: 'Ricoh', isFamily: true, children: [] },
                { label: 'Sharp', isFamily: true, children: [] },
                { label: 'Toshiba', isFamily: true, children: [] },
                { label: 'Xerox', isFamily: true, children: [] },
                { label: 'Egyéb', isFamily: true, children: [] }
            ];
        }
        loadTree();
        $scope.oidDefinitions = [
            { Name: 'Fekete számláló', Definition: 'RemoteDeviceDataBlackCounter' },
            { Name: 'Színes számláló', Definition: 'RemoteDeviceDataColorCounter' },
            { Name: 'Cián', Definition: 'RemoteDeviceDataCyan' },
            { Name: 'Magenta', Definition: 'RemoteDeviceDataMagenta' },
            { Name: 'Sárga', Definition: 'RemoteDeviceDataYellow' },
            { Name: 'Fekete', Definition: 'RemoteDeviceDataBlack' }
        ];
        $scope.types = [
            { Id: 61, Name: 'Összeadás' },
            { Id: 62, Name: 'Kivonás' },
            { Id: 63, Name: 'Szorzás' },
            { Id: 64, Name: 'Osztás' }
        ];

        $scope.familys = [];

        var resetActFamily = function () {
            $scope.actFamily = {
                Id: 0,
                Name: '',
                Description: '',
                OIDList: [],
            };
        };

        resetActFamily();

        var getFamilys = function () {
            $http.get(apiurl.remoteDevice.getRemoteDeviceFamilyNames).success(function (data) {
                loadFamilyTree(data);
                $scope.familys = data;
            });
        }
        getFamilys();

        $scope.my_tree = tree = {};

        var loadFamilyTree = function (array) {
            loadTree();
            var hasParent = false;
            for (var i = 0; i < array.length; i++) {
                hasParent = false;
                for (var j = 0; j < $scope.treeArray.length; j++) {
                    for (var k = 0; k < array[i].Name.toLowerCase().split(' ').length; k++) {
                        if (array[i].Name.toLowerCase().split(' ')[k] === $scope.treeArray[j].label.split(' ')[0].toLowerCase()) {
                            hasParent = true;
                            $scope.treeArray[j].children.push({ label: array[i].Name, Id: array[i].Id });
                            $scope.treeArray[j].expanded = false;
                        }
                    }
                    if (array[i].Name.toLowerCase().indexOf($scope.treeArray[j].label.toLowerCase()) != -1 && !hasParent) {
                        hasParent = true;
                        $scope.treeArray[j].children.push({ label: array[i].Name, Id: array[i].Id });
                        $scope.treeArray[j].expanded = false;
                    }
                }
                if (!hasParent) {
                    $scope.treeArray[$scope.treeArray.length - 1].children.push({ label: array[i].Name, Id: array[i].Id });
                    $scope.treeArray[$scope.treeArray.length - 1].expanded = false;
                }
            }
        };


        var getFamily = function (id) {
            $http.get(apiurl.remoteDevice.getRemoteDeviceFamilyById + id).success(function (data) {
                $scope.actFamily = data;
                setOIDList($scope.actFamily.OIDList);
                $scope.title = $scope.actFamily.Name + " széria szerkesztése";
                $scope.isVisibleTable = true;
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };

        $scope.my_tree_handler = function (branch) {

            if (!branch.isFamily) {
                $scope.isVisibleTable = true;
                getFamily(branch.Id);
            } else {
                resetActFamily();
                $scope.isVisibleTable = false;
            }
        };

        $scope.newFamily = function () {
            resetActFamily();
            $scope.isVisibleTable = true;
        };

        $scope.checkOIDs = function (oidList) {
            var isOk = false;
            for (var i = 0; i < oidList.length; i++) {
                if (oidList[i].Name == "" || oidList[i].Definition == null || oidList[i].OIDString == "") {
                    toasterService.warningToast("Hiba", "Nem töltött ki minden mező!");
                    isOk = false;
                    break;
                } else {
                    if (!(oidList[i].OIDString[0] === "1" || oidList[i].OIDString[0] === "2" || oidList[i].OIDString[0] === "3")) {
                        toasterService.warningToast("Hiba", "Az OID számnak 1 vagy 2 vagy 3-as számmal kell kezdődnie! \n például: 1.3.6.1.2.1.1.5.0");
                        isOk = false;
                        break;
                    } else {
                        if (Enumerable.From(oidList).Where(function (x) {
                            return x.Definition.Definition == oidList[i].Definition.Definition;
                        }).ToArray().length > 1) {
                            isOk = angular.isDefined(oidList[i].Type);
                            if (!isOk) {
                                toasterService.warningToast("Hiba", "Egyezeő OID definiciók esetén a kapcsolat típusának kiválasztása kötelező! \n" + oidList[i].Name + ' Definició: ' + oidList[i].Definition.Name);
                                isOk = false;
                                break;
                            }
                        } else {
                            isOk = true;
                        }
                    }
                }

            }
            return isOk;
        };

        $scope.addNewOID = function () {
            $scope.actFamily.OIDList.push(angular.copy(angular.copy(emptyOID)));
        };

        $scope.deleteOID = function (oid) {
            $scope.actFamily.OIDList.splice($scope.actFamily.OIDList.indexOf(oid), 1);
        };

        $scope.saveFamily = function () {
            if (confirm("Biztos hogy menti az eszköz szériát?")) {
                if (!$scope.checkOIDs($scope.actFamily.OIDList)) {
                    return;
                }
                if ($scope.actFamily.Id == 0) {
                    $http.post(apiurl.remoteDevice.createFamily, $scope.actFamily).success(function (data) {
                        toasterService.saveSuccess();
                        $scope.isVisibleTable = false;
                        $scope.actFamily.Id = data;
                        $scope.familys.push($scope.actFamily);
                        loadFamilyTree($scope.familys);
                        resetActFamily();
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data);
                    });
                } else {
                    $http.post(apiurl.remoteDevice.updateFamily, $scope.actFamily).success(function () {
                        toasterService.saveSuccess();
                        $scope.isVisibleTable = false;
                        resetActFamily();
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data);
                    });
                }
            }
        };

        $scope.search = {
            Name: ''
        };

        $scope.searchChanged = function () {
            loadRemoteDeviceOIDs($filter('filter')(familys, $scope.search));
        };

        var setOIDList = function (list) {
            for (var i = 0; i < list.length; i++) {
                for (var j = 0; j < $scope.oidDefinitions.length; j++) {
                    if (list[i].Definition.Definition === $scope.oidDefinitions[j].Definition) {
                        list[i].Definition = $scope.oidDefinitions[j];
                        break;
                    }
                }
                if (angular.isDefined(list[i].Type) && list[i].Type != null) {
                    list[i].Type = Enumerable.From($scope.types).Where(function (data) { return data.Id == list[i].Type.Id; }).FirstOrDefault();
                }
            }
        };
        var emptyOID = {
            Id: 0,
            Name: "",
            Definition: null,
            Type: null,
            OIDString: ""
        };

        $scope.typeOfOIDChanged = function (oid) {
            for (var i = 0; i < $scope.actFamily.OIDList.length; i++) {
                if ($scope.actFamily.OIDList[i].Definition.Definition == oid.Definition.Definition) {
                    $scope.actFamily.OIDList[i].Type = oid.Type;
                }
            }
        };
    }

})();
