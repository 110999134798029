(function () {

    angular.module('IMasterApp')
        .factory('GetDocument', GetDocument)
        .factory('SaveTemporary', SaveTemporary)
        .factory('FinalizeTemporary', FinalizeTemporary)
        .factory('EditTemporary', EditTemporary)
        .factory('SetStricts', SetStricts)
        .controller('MovementController2', MovementController2)
        .controller('TemporaryMovementController', TemporaryMovementController)
        .controller('MovementController', MovementController);

    GetDocument.$inject = ['$http', 'apiurl'];
    function GetDocument($http, apiurl) {
        return {
            service: function (parameters) {
                return $http.get(apiurl.movement.getmovementById + parameters);
            }
        }
    }

    SaveTemporary.$inject = ['$http', 'apiurl'];
    function SaveTemporary($http, apiurl) {
        return {
            service: function (parameters) {
                return $http.put(apiurl.movement.resave, parameters, { params: {} });
            }
        }
    }

    FinalizeTemporary.$inject = ['$http', 'apiurl'];
    function FinalizeTemporary($http, apiurl) {
        return {
            service: function (parameters) {
                return $http.put(apiurl.movement.finalizeMovement, parameters, { params: {} });
            }
        }
    }

    EditTemporary.$inject = ['$http', 'apiurl'];
    function EditTemporary($http, apiurl) {
        return {
            service: function (parameters) {
                return $http.get(apiurl.movement.editMovement + parameters);
            }
        }
    }

    SetStricts.$inject = ['$http', 'apiurl'];
    function SetStricts() {
        return {
            service: function (items) {
                angular.forEach(items, function (item) {
                    var prefix = '';
                    var startNumber = '';
                    var endNumber = '';
                    angular.forEach(item.StrictList, function (str) {
                        prefix = str.Prefix;
                        if (startNumber === '' || startNumber > str.StartNumberOfRange) startNumber = str.StartNumberOfRange;
                        if (endNumber === '' || endNumber < str.EndNumberOfRange) endNumber = str.EndNumberOfRange;
                    });
                    item.Prefix = prefix;
                    item.StartNumberOfRange = startNumber;
                    item.EndNumberOfRange = endNumber;
                });
            }
        }
    }

    MovementController2.$inject = ['$scope', '$http', 'GetDocument', 'SetStricts', 'siteurl', 'apiurl', 'toasterService'];
    function MovementController2($scope, $http, GetDocument, SetStricts, siteurl, apiurl, toasterService) {
        var movementId = window.location.href.substring(window.location.href.indexOf("?movementId=") + 12);
        $scope.isInterWarehouse = false;
        $scope.isInterWarehouseAndArrived = false;
        $scope.isPricesVisible = true;

        var resultGet = GetDocument.service(movementId);


        if (resultGet != null) {
            resultGet.success(function (data) {
                switch (true) {
                    case data.Movement.MovementNumber.indexOf("B_") === 0:
                        $scope.title = "Bevételi bizonylat";
                        break;
                    case data.Movement.MovementNumber.indexOf("K_") === 0:
                        $scope.title = "Kiadási bizonylat";
                        break;
                    case data.Movement.MovementNumber.indexOf("RK_") === 0:
                        $scope.title = "Raktárközi mozgatási bizonylat";
                        break;
                    case data.Movement.MovementNumber.indexOf("KF_") === 0:
                        $scope.title = "Kimozgatás felhasználásra bizonylat";
                        break;
                    default:
                        $scope.title = "Bizonylatok";
                        break;
                }
                $scope.actualMovement = data.Movement;
                SetStricts.service(data.MovementItems);
                $scope.movementItems = data.MovementItems;
                $scope.CostCentersOfMovement = data.CostCentersOfMovement;

                if (data.Movement.IsSendedInterWarehouse != null) {
                    $scope.isInterWarehouse = false;
                    $scope.isInterWarehouseAndArrived = (data.Movement.IsSendedInterWarehouse) ? false : true;
                    $scope.isInterWarehouseStatus = (data.Movement.IsSendedInterWarehouse) ? 'Elküldött' : 'Fogadott';

                    //Lekérdezzük a mozgásbizonylatot
                    $http.get(apiurl.interWarehouse.getInterWarehouse + movementId).success(function (data) {
                        $scope.interWarehouse = data;
                        $http.get(apiurl.interWarehouse.getOwnerCompany + data.ReceiverWarehouse.Id).success(function (data) {
                            $scope.interWarehouse.ReceiverWarehouse.OwnerCompany = data.LongName;
                        });
                        //$scope.voucherItems = data.Items;
                    });

                } else {
                    $scope.isInterWarehouse = true;
                    $scope.isInterWarehouseAndArrived = true;
                }
            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });
        }

        $scope.InterWarehousePrint = function () {
            location.href = apiurl.interWarehouse.saveInterWarehouseDocument + movementId;
        };

        //Nyomtatás
        $scope.saveDocument = function () {
            if (!$scope.isInterWarehouse) {
                location.href = apiurl.movement.saveInterWarehouseDocument + movementId + '/' + $scope.isPricesVisible;
            } else {
                location.href = apiurl.movement.saveDocument + movementId + '/' + $scope.isPricesVisible;
            }
        };

        //Mozgatás
        $scope.interMoving = function () {
            if ($scope.actualMovement.IsSendedInterWarehouse == false) {
                return;
            }
            var products = $scope.movementItems;
            var data = {
                Items: products, SenderWarehouse: {
                    Id: $scope.interWarehouse.SenderWarehouse.Id,
                    Name: ''
                }, ReceiverWarehouse: {
                    Id: $scope.interWarehouse.ReceiverWarehouse.Id,
                    Name: ''
                }, MovementId: movementId
            };

            $http.put(apiurl.interWarehouse.arrive, data).success(function () {
                toasterService.successToast("", 'Sikerült a bizonylat raktárba érkeztetése!');
                window.location.href = siteurl.stock;
            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });

        };

    }

    TemporaryMovementController.$inject = ['$scope', 'SaveTemporary', 'FinalizeTemporary', 'EditTemporary', 'siteurl', 'toasterService'];
    function TemporaryMovementController($scope, SaveTemporary, FinalizeTemporary, EditTemporary, siteurl, toasterService) {
        var movementId = window.location.href.substring(window.location.href.indexOf("?movementId=") + 12);
        $scope.actCompany = null;
        $scope.actWarehouse = null;

        // New DatePicker settings
        $scope.today = function () {
            $scope.avdate = new Date();
            $scope.avdate = $filter('date')($scope.avdate, 'yyyy.MM.dd');
        };

        $scope.clear = function () {
            $scope.avdate = null;
        };

        $scope.avdateformat = "yyyy.MM.dd";

        $scope.dtopen = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.dtopened = true;
        };

        $scope.dtoptions = {
            startingDay: 1
        };

        var parameter = movementId;
        var resultGet = EditTemporary.service(parameter);
        if (resultGet != null) {
            resultGet.success(function (data) {
                $scope.actualMovement = data.Movement;

                $scope.avdate = $scope.actualMovement.CreatedDate;

                $scope.movementItems = data.MovementItems;
                $scope.CompanyList = data.Companies;
                $scope.WarehouseList = data.Warehouses;
                $scope.actCompany = { Id: $scope.actualMovement.CompanyId, Name: $scope.actualMovement.CompanyName };

                $scope.actWarehouse = { Id: $scope.actualMovement.WarehouseId, Name: $scope.actualMovement.WarehouseName };
            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });
        }

        $scope.backButtonClick = function () {
            if (confirm("Valóban ki akar lépni?")) {
                window.location.href = siteurl.movement;
            }
        };
        $scope.temporarySave = function () {
            var message = confirm("Valóban el akarja menteni?");
            if (message) {
                $scope.actualMovement.CreatedDate = $('#VoucherDatePicker').val();
                $scope.actualMovement.CompanyId = $scope.actCompany.Id;
                $scope.actualMovement.WarehouseId = $scope.actWarehouse.Id;
                parameter = { Movement: $scope.actualMovement, MovementItems: $scope.movementItems }
                resultGet = SaveTemporary.service(parameter);
                if (resultGet != null) {
                    resultGet.success(function (data) {
                        $scope.actualMovement = data.Movement;
                        $scope.movementItems = data.MovementItems;
                        window.location.href = siteurl.movement;
                    }).error(function (data, status) {
                        toasterService.errorFromServerToast(data);
                    });
                }
            }
        };
        $scope.commitSave = function () {
            if (confirm("Valóban véglegesíteni akarja?")) {
                $scope.actualMovement.CreatedDate = $('#VoucherDatePicker').val();
                $scope.actualMovement.CompanyId = $scope.actCompany.Id;
                $scope.actualMovement.WarehouseId = $scope.actWarehouse.Id;
                parameter = { Movement: $scope.actualMovement, MovementItems: $scope.movementItems }
                resultGet = FinalizeTemporary.service(parameter);
                if (resultGet != null) {
                    resultGet.success(function (data) {
                        $scope.actualMovement = data.Movement;
                        $scope.movementItems = data.MovementItems;
                        //alert('Sikeres mentés');
                        window.location.href = siteurl.movement;
                    })
                    .error(function (data, status) {
                        toasterService.errorFromServerToast(data);
                    });
                }
            }
        };

        $scope.products = [];
        $scope.addRow = function () {
            $scope.product = {
                Name: '',
                Quantity: '',
                QuantityType: '',
                NetPrice: '',
                Vat: 27,
            };
            $scope.movementItems.push($scope.product);
        };

        $scope.deleteRow = function (index) {
            $scope.movementItems.splice(index, 1);
        };
    }

    MovementController.$inject = ['$scope', '$http', 'siteurl', 'apiurl', 'toasterService'];
    function MovementController($scope, $http, siteurl, apiurl, toasterService) {

        $scope.currentPage = 0;
        $scope.pageSize = 25;
        $scope.actualPage = 0;

        //Datepickers definitions
        $scope.format = "yyyy.MM.dd";

        $scope.openedDp = [];
        $scope.openDatePicker = function ($event, index) {
            $event.preventDefault();
            $scope.openedDp[index] = true;
        };
        $scope.DateOptions = {
            startingDay: 1
        };

        //Lapozás
        $scope.pageChanged = function () {
            $scope.currentPage = $scope.current - 1;
            $scope.SetPagesAndSearching();
        };

        $scope.tags = [];

        //Keresés
        $scope.searchParameters = {
            CompanyName: '',
            WarehouseName: '',
            MovementNumber: '',
            CreatedDateFrom: '',
            CreatedDateTo: '',
            NetValueFrom: null,
            NetValueTo: null,
            GrossValueFrom: null,
            GrossValueTo: null,
            PageIndex: 0,
            PageSize: 25,
            All: true,
            Receipt: false,
            OutGo: false,
            SentVoucher: false,
            GetVoucher: false,
            SortIndex: "",
            OrderDirection: true,
            CostCenterIdList: ""

        };

        $scope.isCollapsed = false;

        // Pager beállítása és az összetett keresés
        $scope.SetPagesAndSearching = function (sortIndex) {
            if (!angular.isUndefined(sortIndex)) {
                if (sortIndex === $scope.searchParameters.SortIndex) {
                    $scope.searchParameters.OrderDirection = !$scope.searchParameters.OrderDirection;
                }
                else {
                    $scope.searchParameters.OrderDirection = true;
                    $scope.searchParameters.SortIndex = sortIndex;
                }
            }

            $scope.searchParameters.PageIndex = $scope.currentPage;
            $scope.searchParameters.PageSize = $scope.pageSize;

            if ($scope.tags.length === 1) {
                $scope.searchParameters.CostCenterIdList = $scope.tags[0].Id;
            } else {
                angular.forEach($scope.tags, function (tg, key) {
                    if (key === $scope.tags.length - 1) {
                        $scope.searchParameters.CostCenterIdList += tg.Id;
                    } else {
                        $scope.searchParameters.CostCenterIdList += tg.Id + ",";
                    }
                });
            }

            $http.post(apiurl.movement.getMovementBySearch, $scope.searchParameters).success(function (data) {
                $scope.movements = data.Movements;
                $scope.totalItems = data.TotalItemNumber;
                $scope.searchParameters.CostCenterIdList = "";
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };

        $scope.loadCostCenters = function (query) {
            return $http.get(apiurl.stock.getCostCenterByName + '/' + query);
        };

        $scope.deleteFilter = function () {
            $scope.searchParameters.CompanyName = '';
            $scope.searchParameters.WarehouseName = '';
            $scope.searchParameters.MovementNumber = '';
            $scope.searchParameters.CreatedDateFrom = '';
            $scope.searchParameters.CreatedDateTo = '';
            $scope.searchParameters.NetValueFrom = null;
            $scope.searchParameters.NetValueTo = null;
            $scope.searchParameters.GrossValueFrom = null;
            $scope.searchParameters.GrossValueTo = null;
            $scope.searchParameters.All = true;
            $scope.searchParameters.SentVoucher = false;
            $scope.searchParameters.GetVoucher = false;
            $scope.searchParameters.OutGo = false;
            $scope.searchParameters.Receipt = false;
            $scope.searchParameters.CostCenterIdList = "";
            $scope.tags = [];
            $scope.SetPagesAndSearching();
        }

        // DataGrid kiválasztás
        $scope.setSelected = function (selectedItem) {
            if (!selectedItem.IsTemporary) {
                window.location = siteurl.openMovement + '/?movementId=' + selectedItem.Id;
            }
        };

        $scope.editVoucher = function (selectedItem) {
            window.location = siteurl.editTemporaryMovement + '/?movementId=' + selectedItem.Id;
        };

        $scope.SetPagesAndSearching();
    }

})();
