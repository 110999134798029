(function () {
    angular.module('IMasterApp')
        .controller('add_payment_invoice', add_payment_invoice);

    add_payment_invoice.$inject = ['$http', '$scope', '$filter', 'siteurl', '$window', 'apiurl', 'toasterService'];
    function add_payment_invoice($http, $scope, $filter, siteurl, $window, apiurl, toasterService) {
        var invoiceId = window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1);
        var backUrl = (/url=([^&=]+)/g).exec(location.search);
        backUrl = backUrl !== null
            ? backUrl[1]
            : siteurl.salesCertification;

        $scope.back = function () {
            goBack();
        }

        function goBack() {
            location.href = siteurl.tweak + "/" + invoiceId + '?url=' + backUrl;
        }
        //felhasználó id lekérése:
        $scope.userId = $window.sessionStorage.getItem('userid');

        //Számlához tartozó összegzősorok és befizetések lekérdezése
        $http.get(apiurl.voucher.getSumRowsByVoucherId + invoiceId).success(function (data) {
            if (data != null) {
                var i;
                if (data.SumRows != null) {
                    for (i = 0; i < data.SumRows.length; i++) {
                        $scope.sumRows = {
                            GrossValue: data.SumRows[i].GrossAmount
                        }
                    }
                }
                if (data.Payments != null) {
                    $scope.inPaymentValue = 0;
                    for (i = 0; i < data.Payments.length; i++) {
                        $scope.inPaymentValue += data.Payments[i].Amount;
                    }
                }
            }

            $scope.debitAmountValue = Number($scope.sumRows.GrossValue).toFixed(0) - Number($scope.inPaymentValue).toFixed(0); //Fennmaradó tartozás értéke
        });

        //Fizetési módok lekérdezése
        $http.get(apiurl.stock.getBaseDataForTakeOut + $scope.userId).success(function (data) {
            if (data != null) {
                $scope.PaymentTypes = data.PaymentTypes;
                $scope.PaymentType = $scope.PaymentTypes[0];
            }
        });

        //Saját cég számlaszámának lekérdezése
        $http.get(apiurl.stock.getOwnerCompanyDatas).success(function (data) {
            if (data !== "null") {
                $scope.ownerCompanyBankAccounts = data.OwnerCompanyBankAccounts;
                $scope.ownerCompanyBankAccount = data.OwnerCompanyBankAccounts[0];

            }
        });

        $scope.showBankAccount = true;
        $scope.$watch("PaymentType", function () {
            if ($scope.PaymentType.Id == 20) {
                $scope.showBankAccount = false;
            } else {
                $scope.showBankAccount = true;
            }
        });

        $scope.paymentInvoiceData = {
            DateOfPayment: '',
            Amount: '',
            TypeOfPaymentId: '',
            OwnAccountNumber: '',
            InvoiceId: invoiceId
        };

        $scope.save = function () {
            $scope.paymentInvoiceData.DateOfPayment = $filter('date')($scope.datePickers.dateOfPayment.date, 'yyyy.MM.dd');
            $scope.paymentInvoiceData.TypeOfPaymentId = $scope.PaymentType.Id;
            $scope.paymentInvoiceData.OwnAccountNumber = $scope.showBankAccount ? $scope.ownerCompanyBankAccount.BankAccount : null;

            $scope.savePayment($scope.paymentInvoiceData);
        }

        $scope.Validate = function (paymentInvoiceData) {
            var actDate = new Date();
            if (paymentInvoiceData.InvoiceId == "AddPaymentInvoice") {
                toasterService.warningToast("Figyelem", "Váratlan hiba!");
                return false;
            }
            if (paymentInvoiceData.DateOfPayment > $filter('date')(actDate, 'yyyy.MM.dd')) {
                toasterService.warningToast("Figyelem", "A befizetés dátuma nem lehet későbbi az aktuális dátumnál!");
                return false;
            }
            if (paymentInvoiceData.Amount == null || paymentInvoiceData.Amount <= 0) {
                toasterService.warningToast("Figyelem", "A befizetés összege csak pozitív szám lehet!");
                return false;
            }
            if (paymentInvoiceData.Amount > $scope.debitAmountValue) {
                toasterService.warningToast("Figyelem", "A befizetés összege nem haladhatja meg a számlához tartozó tartozás összegét!");
                return false;
            }
            if (paymentInvoiceData.Amount > Number($scope.sumRows.GrossValue.toFixed(0))) {
                toasterService.warningToast("Figyelem", "A befizetés összege nem lehet nagyobb a számla összegénél!");
                return false;
            }
            if (paymentInvoiceData.TypeOfPaymentId == null || paymentInvoiceData.TypeOfPaymentId == 0) {
                toasterService.warningToast("Figyelem", "Nem választott ki befizetési módot!");
                return false;
            }
            if ($scope.showBankAccount && paymentInvoiceData.OwnAccountNumber == null) {
                toasterService.warningToast("Figyelem", "Nem választott ki számlaszámot!");
                return false;
            }

            return true;
        }

        $scope.savePayment = function (paymentInvoiceData) {
            if ($scope.Validate(paymentInvoiceData)) {
                if (confirm('Valóban menteni akarja a befizetést?')) {
                    $scope.savePaymentPost(paymentInvoiceData);
                }
            }
        }

        $scope.savePaymentPost = function (data) {
            $http.post(apiurl.voucher.paymentInvoice, data).success(function () {
                toasterService.successToast("Sikeres befizetés!");
                $scope.back();
            }).error(function (data, status) {
                toasterService.errorFromServerToast(dataK);
            });
        };

        // New DatePicker settings
        $scope.today = function () {
            $scope.prdate2 = new Date();
            $scope.prdate2 = $filter('date')($scope.prdate2, 'yyyy.MM.dd');
        };
        $scope.today();

        $scope.clear = function () {
            $scope.prdate2 = null;
        };

        $scope.prdate2format = "yyyy.MM.dd";

        $scope.datePickers = {
            dateOfPayment: { isOpen: false, date: new Date() }
        };

        $scope.dtopen = function ($event, datePicker) {
            $event.preventDefault();
            $event.stopPropagation();

            if (!datePicker) {
                $scope.dtopened = true;

            } else {
                datePicker.isOpen = true;
            }
        };

        $scope.dtoptions = {
            startingDay: 1
        };
    }
})();
