(function () {

    angular.module('IMasterApp')
        .controller('editRemoteDeviceController', editRemoteDeviceController);

    editRemoteDeviceController.$inject = ['$scope', '$http', '$filter', '$window', 'siteurl', 'apiurl', 'toasterService'];
    function editRemoteDeviceController($scope, $http, $filter, $window, siteurl, apiurl, toasterService) {
        var remoteDeviceId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1); //eszköz id-ja
        $scope.isNewRemoteDevice = remoteDeviceId == "EditRemoteDevice" || remoteDeviceId == 0 ? true : false; //létrehozás/szerkesztés
        $scope.partnerAddressSites = [];
        $scope.remoteDeviceFamilyList = [];
        if ($scope.isNewRemoteDevice) {
            $scope.Title = "Új eszköz hozzáadása";
            $scope.actRemoteDevice = {
                Id: "",
                FamilyId: "",
                Name: "",
                SerialNumber: "",
                IDNR: "",
                Company: "",
                Address: "",
                RemoteDeviceFamily: '',
            };
        } else {
            $scope.Title = "Eszköz módosítása";
            $http.get(apiurl.remoteDevice.getRemoteDevice + remoteDeviceId).success(function (data) {
                $scope.actRemoteDevice = data;
                getAddressSiteToPartner(data.Company.Id);
            });
        }

        //partnerek lekérdezése megadott karakter után
        $scope.getPartner = function (partnerName) {
            return $http.get(apiurl.complain.getCompanyNamesUrl + partnerName).then(function (data) {
                return data.data;
            });
        };

        //adott partnerhez tartozó telephely címek lekérdezése
        var getAddressSiteToPartner = function (id) {
            if (angular.isUndefined(id) || id == 0) return;

            $http.get(apiurl.remoteDevice.getAddressSitesToPartner + id).success(function (data) {
                $scope.partnerAddressSites = data;

                if (!$scope.isNewRemoteDevice) {
                    for (var i = 0; i < $scope.partnerAddressSites.length; i++) {
                        if ($scope.partnerAddressSites[i].FullAddress === $scope.actRemoteDevice.Address) {
                            $scope.actRemoteDevice.AddressSite = $scope.partnerAddressSites[i];
                        }
                    }
                }
            }).error(function (errorData) {
                if (data.Notifications.length == 0) {
                    toasterService.errorFromServerToast(errorData.Message);
                }

                toasterService.errorFromServerToast(errorData.Notifications[0].Target);
            });
        };

        //eszköz családjának lekérdezése
        function getRemoteDeviceFamilyNames() {
            $http.get(apiurl.remoteDevice.getRemoteDeviceFamilyNames).success(function (data) {
                $scope.remoteDeviceFamilyList = data;

                if (!$scope.isNewRemoteDevice) {
                    for (var i = 0; i < $scope.remoteDeviceFamilyList.length; i++) {
                        if ($scope.remoteDeviceFamilyList[i].Id === $scope.actRemoteDevice.FamilyId) {
                            $scope.actRemoteDevice.RemoteDeviceFamily = $scope.remoteDeviceFamilyList[i];
                        }
                    }
                }
            }).error(function (errorData) {
                if (data.Notifications.length == 0) {
                    toasterService.errorFromServerToast(errorData.Message);
                }

                toasterService.errorFromServerToast(errorData.Notifications[0].Target);
            });
        };

        getRemoteDeviceFamilyNames();

        //partner kiválasztása
        $scope.partnerOnSelect = function (partner) {
            if (angular.isUndefined(partner) || partner == null) return;

            getAddressSiteToPartner(partner.Id);
        };

        //Cím kiválasztása
        $scope.partnerAddressOnSelect = function () {
            $scope.actRemoteDevice.Address = $scope.actRemoteDevice.AddressSite.FullAddress;
        }

        //eszköz családjának kiválasztása
        $scope.remoteDeviceFamilyOnSelect = function () {
            $scope.actRemoteDevice.FamilyId = $scope.actRemoteDevice.RemoteDeviceFamily.Id;
        };

        //Visszalépés
        $scope.backPage = function () {
            if (confirm("Valóban ki akar lépni mentés nélkül?")) {
                location.href = siteurl.remoteDevices;
            }
        };

        //Ellenőrzés
        var checkActRemoteDevice = function (actRemoteDevice) {
            if (angular.isUndefined(actRemoteDevice.RemoteDeviceFamily) || actRemoteDevice.RemoteDeviceFamily.Id == 0) {
                alert("A készülék családjának megadása kötelező!");
                return false;
            }
            if (actRemoteDevice.Name == "") {
                alert("A készülék típusának megadása kötelező!");
                return false;
            }
            if (actRemoteDevice.SerialNumber == "") {
                alert("A készülék gyári számának megadása kötelező!");
                return false;
            }
            if (actRemoteDevice.IDNR == "") {
                alert("A készülék INDR számának megadása kötelező!");
                return false;
            }
            if (angular.isUndefined(actRemoteDevice.Company) || actRemoteDevice.Company.Id == 0) {
                alert("A partner kiválasztása kötelező!");
                return false;
            }
            if (actRemoteDevice.Address == "") {
                alert("A telephely kiválasztása kötelező!");
                return false;
            }

            return true;
        };

        //Mentés
        $scope.saveRemoteDevice = function () {
            if (!checkActRemoteDevice($scope.actRemoteDevice)) return;
            if ($scope.isNewRemoteDevice) {
                $http.post(apiurl.remoteDevice.createRemoteDevice, $scope.actRemoteDevice).success(function () {
                    toasterService.successToast("Létrehozás", "Eszköz sikeresen létrehozva!");
                    window.location.href = siteurl.remoteDevices;
                }).error(function (data) {
                    if (data.Notifications.length == 0) {
                        toasterService.errorFromServerToast(data.Message);
                    }

                    toasterService.errorFromServerToast(data.Notifications[0].Target);
                });
            } else {
                $scope.actRemoteDevice.Id = $scope.actRemoteDevice.Id == 0 ? remoteDeviceId : $scope.actRemoteDevice.Id;
                $http.put(apiurl.remoteDevice.updateRemoteDevice, $scope.actRemoteDevice).success(function () {
                    toasterService.successToast("Módosítás", "Eszköz sikeresen módosítva!");
                    window.location.href = siteurl.remoteDevices;
                }).error(function (data) {
                    if (data.Notifications.length == 0) {
                        toasterService.errorFromServerToast(data.Message);
                    }

                    toasterService.errorFromServerToast(data.Notifications[0].Target);
                });
            }
        };
    }

})();
