(function () {
    angular.module('IMasterApp')
        .controller('ComplainCtrl', ComplainCtrl)
        .controller('EditComplainCtrl', EditComplainCtrl);

    ComplainCtrl.$inject = ['$scope', '$http', '$location', '$window', 'siteurl', 'apiurl', 'toasterService'];
    function ComplainCtrl($scope, $http, $location, $window, siteurl, apiurl, toasterService) {
        $scope.siteurl = siteurl;
        $scope.actUrl = siteurl.complain;

        //Init
        var userId = $window.sessionStorage.getItem('userid'); //felhasználó id elérése
        var allModifiedComplain = false;
        angular.forEach($window.sessionStorage.getItem('roleids').split(','), function (value, key) {
            if (value == 28) {
                allModifiedComplain = true; //felhasználónak van-e joga az összes reklamációt kezelni/látni
            }
        });

        //Új reklamáció
        $scope.newComplain = function () {
            window.location = siteurl.editcomplain;
        };

        //Reklamáció visszanyitása
        $scope.editComplain = function (index) {
            location.href = siteurl.editcomplain + "/" + index.Id;
        }

        //Email sablon létrehozása
        $scope.CreateEmailTemplate = function () {
            window.location.href = siteurl.editEmailTemplate + '?url=' + $scope.actUrl;
        };

        //Reklamáció törlése
        $scope.deleteComplain = function (value, index) {
            if (confirm("Valóban törölni akarja?")) {
                $http.delete(apiurl.complain.deleteComplainUrl + value.Id).success(function () {
                    toasterService.successToast("Törlés", "Sikeres törlés!");
                    $scope.complains.splice(index, 1);
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            }
        };

        $scope.currentPage = 0;
        $scope.pageSize = 25;
        $scope.actualPage = 0;
        $scope.selectedPageSize = 25;

        $scope.SearchObj = { sortIndex: "", orderDirection: true };

        //Szűrési dto összeállítása
        var createFilterComplainData = function () {
            return {
                sortIndex: $scope.SearchObj.sortIndex, // A rendezéshez szükséges oszlop neve
                orderDirection: $scope.SearchObj.orderDirection, // A rendezéshez szükséges irány
                UserId: allModifiedComplain == false ? userId : null
            };
        }

        //Reklamációk lekérdezése
        $scope.loadAllComplain = function (sortIndex) {
            if (!angular.isUndefined(sortIndex)) {
                if (sortIndex === $scope.SearchObj.sortIndex) {
                    $scope.SearchObj.orderDirection = !$scope.SearchObj.orderDirection;
                }
                else {
                    $scope.SearchObj.orderDirection = true;
                    $scope.SearchObj.sortIndex = sortIndex;
                }
            }

            //Szűrési Dto összeállítása
            var filterComplains = createFilterComplainData();

            loadAllComplainFinish(filterComplains);
        };

        //Reklamációk lekérdezésének befejezése
        var loadAllComplainFinish = function (filterComplains) {
            $http.post(apiurl.complain.getComplainListUrl + $scope.currentPage + '/' + $scope.pageSize, filterComplains).success(function (data) {
                $scope.complains = data.ComplainList;
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };

        $scope.loadAllComplain();

        //Reklamációk rendezése
        $scope.loadAllComplainForSort = function (sortIndex) {
            $scope.loadAllComplain(sortIndex);
        }

        //Reklamációk lapozás
        $scope.pageChanged = function () {
            $scope.currentPage = $scope.current - 1;
            $scope.loadAllComplain();
        };

        //Reklamációk beállítása 1 oldalon
        $scope.changeSelected = function () {
            if ($scope.selectedPageSize > 0) {
                $scope.pageSize = $scope.selectedPageSize;
                $scope.currentPage = 0;
                $scope.loadAllComplain();
            }
        };
    }

    EditComplainCtrl.$inject = ['$scope', '$http', '$location', '$window', '$filter', 'siteurl', 'apiurl', '$localStorage', '$sessionStorage', 'FileUploader', 'toasterService', 'WizardHandler', 'userService'];
    function EditComplainCtrl($scope, $http, $location, $window, $filter, siteurl, apiurl, $localStorage, $sessionStorage, FileUploader, toasterService, WizardHandler, userService) {
        var complainId = window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1); //reklamáció azonosítója

        //Init
        $scope.complainData = {
            Id: '',
            CompanyId: '',
            DeadlineDate: '',
            ComplainTypeId: '',
            ProcessingStatusId: '',
            CreationOwner: '',
            ModificationOwner: '',
            ContactId: '',
            Description: '',
            ProposalDescription: '',
            hasToDoOfComplain: '',
            confirmedColleagueIdList: [],
            delete_ConfirmedColleagueIdList: [],
            confirmedColleagues: []
        };
        var userId = $window.sessionStorage.getItem('userid'); //felhasználó id elérése
        var userName = $window.sessionStorage.getItem('username');
        $scope.tabClick = 'complainData';
        $scope.siteurl = siteurl;
        $scope.actUrl = siteurl.editcomplain;
        $scope.complainToAllocation = false;
        $scope.complainToProcessing = false;
        $scope.complainToSummary = false;
        var original_ProposalDescription = '';
        $scope.confirmedColleagueList = [];
        var original_ConfirmedColleagueList = [];
        var delete_ConfirmedColleagueIdList = [];
        $scope.fileObj = {
            FileDto: [],
            ImageDto: []
        };
        var confirmedUserIdList = [];
        var confirmedColleagueIdList = [];
        var complainSavedFromAllocation = false;
        var complainSavedFromProcessing = false;
        var complainSavedFromSummary = false;
        var supportedFileType = '';
        $scope.processingColleagueNameList = ''; //panaszt feldolgozó személyek névlistája

        //Támogatott file típusok lekérdezése
        var getSupportedFileType = function () {
            $http.get(apiurl.complain.getSupportedFileTypeUrl).success(function (data) {
                supportedFileType = data;
            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
                $scope.failedFileType = true;
            });
        };

        getSupportedFileType();

        //Fájl lekérdezése a feltöltés után
        function generateGetFileUrl(complainId, fileName, localChildDirectoryName) {
            return apiurl.complain.getFileUrl + '?complainId=' + complainId + '&fileName=' + fileName + '&localChildDirectoryName=' + localChildDirectoryName;
        }

        //Reklamáció típusok lekérdezése
        $scope.getCompanyTypesList = function () {
            $http.get(apiurl.complain.getComplainTypesUrl).success(function (data) {
                $scope.complainTypes = data;
                $scope.complainType = $scope.complainTypes[0];
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };

        //A localStorageben található visszaigazolt munkatársak letöltés
        var loadConfirmedUserDatasFromLocalStorage = function () {
            angular.forEach($localStorage.confirmedUserIdList, function (value) {
                confirmedUserIdList.push(value.userId);
            });
            delete $localStorage.confirmedUserIdList;

            userService.getUserListByUserIdList(confirmedUserIdList).success(function (data) {
                //Még nincs a reklamációhoz rögzítve munkatárs
                if ($scope.confirmedColleagueList.length == 0) {
                    $scope.confirmedColleagueList = data;
                } else {
                    angular.forEach(data, function (value, key) {
                        $scope.confirmedColleagueList.push(value);
                    });
                }

                angular.forEach($scope.confirmedColleagueList, function (value, key) {
                    var colleagueFullName = value.UserFirstname + ' ' + value.UserLastname;
                    $scope.processingColleagueNameList += colleagueFullName;
                    $scope.processingColleagueNameList += ", ";
                });
                if ($scope.processingColleagueNameList.lastIndexOf(",") != -1) {
                    $scope.processingColleagueNameList = $scope.processingColleagueNameList.substring(0, $scope.processingColleagueNameList.length - 2);
                }

                $scope.modifiedConfirmedColleague = false;
                //A munka kiosztása mentés gomb elrejtése/megjelenítése
                checkColleagueList();

                //Rögzítve státusz alatt van még a reklamáció
                if ($scope.complainData.ProcessingStatusId == 72) {
                    $scope.tabClick = 'allocationOfWork';
                    $scope.complainToAllocation = true;
                }
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };

        //Reklamáció lekérdezése
        var getActComplain = function () {
            //Adott reklamáció lekérdezése
            $http.get(apiurl.complain.getComplainUrl + complainId).success(function (data) {
                $scope.complainData = data.Complain;
                original_ProposalDescription = data.Complain.ProposalDescription;

                if ($scope.complainData.ProcessingStatusId == 72) {
                    if ($scope.ComplainSavedFromAllocateButton == null || $scope.ComplainSavedFromAllocateButton == false) {
                        $scope.tabClick = 'complainData';
                        $scope.complainToFixed = true;
                    }
                }

                if ($scope.complainData.ProcessingStatusId == 73) {
                    $scope.tabClick = 'allocationOfWork';
                    $scope.complainToAllocation = true;
                }

                if ($scope.complainData.ProcessingStatusId == 74) {
                    $scope.tabClick = 'processing';
                    $scope.complainToProcessing = true;
                }

                if ($scope.complainData.ProcessingStatusId == 75) {
                    $scope.tabClick = 'summary';
                    $scope.closeToComplain = true;
                }

                //Reklamációhoz tartozó fileok beállítása
                angular.forEach(data.ComplainToFileList, function (value, key) {
                    value.FileName = generateGetFileUrl($scope.complainData.Id, value.FileName, $scope.complainData.Identification);
                    if (value.FileType.indexOf('jpg') != -1 || value.FileType.indexOf('png') != -1 || value.FileType.indexOf('jpeg') != -1 || value.FileType.indexOf('bmp') != -1 || value.FileType.indexOf('gif') != -1) {
                        $scope.fileObj.ImageDto.push(value);
                    } else {
                        $scope.fileObj.FileDto.push(value);
                    }
                });

                if ($scope.complainData.ProcessingStatusId == 73 || $scope.complainData.ProcessingStatusId == 74 || $scope.complainData.ProcessingStatusId == 75) {
                    if ($scope.complainData.confirmedColleagues.length > 0) {
                        angular.forEach($scope.complainData.confirmedColleagues, function (value, key) {
                            confirmedColleagueIdList.push(value.UserId);
                        });
                        userService.getUserListByUserIdList(confirmedColleagueIdList).success(function (data) {
                            //Még nincs a reklamációhoz rögzítve munkatárs
                            if ($scope.confirmedColleagueList.length == 0) {
                                $scope.confirmedColleagueList = data;
                            } else {
                                angular.forEach(data, function (value, key) {
                                    $scope.confirmedColleagueList.push(value);
                                });
                            }
                            original_ConfirmedColleagueList = angular.copy(data);

                            angular.forEach($scope.confirmedColleagueList, function (value, key) {
                                var colleagueFullName = value.UserFirstname + ' ' + value.UserLastname;
                                $scope.processingColleagueNameList += colleagueFullName;
                                $scope.processingColleagueNameList += ", ";
                            });
                            if ($scope.processingColleagueNameList.lastIndexOf(",") != -1) {
                                $scope.processingColleagueNameList = $scope.processingColleagueNameList.substring(0, $scope.processingColleagueNameList.length - 2);
                            }
                        }).error(function (data) {
                            toasterService.errorFromServerToast(data);
                        });
                    }
                }

                userService.getUserById(userId).success(function (data) {
                    $scope.userFullName = data.UserFirstname + ' ' + data.UserLastname;
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });

                //Reklamáció típus beállítása
                $scope.complainTypes = data.ComplainTypes;
                angular.forEach($scope.complainTypes, function (value, key) {
                    if (value.Id === $scope.complainData.ComplainTypeId) {
                        $scope.complainType = value;
                    }
                });

                //Reklamáció státusz beállítása
                $scope.complainStatuses = data.ComplainStatus;
                angular.forEach($scope.complainStatuses, function (value, key) {
                    if (value.Id === $scope.complainData.ProcessingStatusId) {
                        $scope.complainStatus = value;
                    }
                });

                //Cég és kapcsolattartó beállítása
                $scope.actCompany = data.SelectedCompany;
                $scope.actContact = data.SelectedContact;

                //A reklamációhoz tartozó összegzések lekérdezése
                $scope.complainToSummaryList = data.ComplainToSummaryList;

                //A kiválasztott céghez tartozó reklamációk lekérdezése
                $scope.loadAllComplain();

                //Ha a localStorageben vannak visszaigazolt munkatársak
                if ($localStorage.confirmedUserIdList != null && $localStorage.confirmedUserIdList.length > 0) {
                    loadConfirmedUserDatasFromLocalStorage();
                }

            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };

        //reklamáció szerkesztése
        if (complainId != 'EditComplain') {
            $scope.editComplain = true;
            $scope.PageTitle = "Reklamáció szerkesztése";

            //Reklamáció lekérdezése
            getActComplain();

        } else {
            $scope.editComplain = false;
            $scope.PageTitle = "Új reklamáció";
            $scope.getCompanyTypesList();
            $scope.complainToFixed = true;
        }

        //Reklamáció mentés elkezdése
        $scope.save = function (isAllocateToComplain) {
            if (isAllocateToComplain) {
                if (complainSavedFromAllocation || $scope.editComplain == true) {
                    return;
                }
            }
            $scope.complainData.DeadlineDate = $filter('date')($scope.datePickers.dateOfComplain.date, 'yyyy.MM.dd');
            $scope.complainData.CompanyId = $scope.actCompany.Id;
            $scope.complainData.ContactId = $scope.actContact.Id;
            $scope.complainData.ComplainTypeId = $scope.complainType.Id;
            $scope.complainData.CreationOwner = userName;
            if ($scope.editComplain == true) {
                $scope.complainData.ModificationOwner = userName;
            }

            saveComplainContinue($scope.complainData, isAllocateToComplain);
        };

        //Reklamáció mentés folytatása
        var saveComplainContinue = function (complainData, isAllocateToComplain) {
            if ($scope.editComplain == false && complainSavedFromAllocation == false) {
                var message = confirm('Valóban menteni akarja a reklamációt?');
                if (!message) {
                    $scope.cancelFromAllocationOfWork();
                    return;
                }
            } else {
                var message = confirm('Valóban menteni akarja a módosításokat?');
                if (!message) {
                    $scope.cancelFromAllocationOfWork();
                    return;
                }
            }

            var result = Validate(complainData);
            if (!result) {
                return;
            }

            saveComplainFinish(complainData, isAllocateToComplain);
        }

        //Validálás
        var Validate = function (complainData) {
            if (complainData.CompanyId == null || complainData.CompanyId == 0) {
                toasterService.warningToast("Hiba történt", "A cég kiválasztása kötelező!");
                return false;
            }
            if (complainData.ContactId == null || complainData.ContactId == 0) {
                toasterService.warningToast("Hiba történt", "A kapcsolattartó kiválasztása kötelező!");
                return false;
            }
            if (complainData.DeadlineDate == null) {
                toasterService.warningToast("Hiba történt", "A határidő kiválasztása kötelező!");
                return false;
            }
            if (complainData.ComplainTypeId == null || complainData.ComplainTypeId == 0) {
                toasterService.warningToast("Hiba történt", "Típus kiválasztása kötelező!");
                return false;
            }
            if (complainData.Description == null || complainData.Description == "") {
                toasterService.warningToast("Hiba történt", "A panasz leírása kötelező!");
                return false;
            }
            if (complainData.CreationOwner == null || complainData.CreationOwner == "") {
                toasterService.warningToast("Hiba történt", "Váratlan hiba!");
                return false;
            }
            if (complainData.CreationOwner.length > 100) {
                toasterService.warningToast("Hiba történt", "Váratlan hiba!");;
                return false;
            }
            if ($scope.editComplain == true) {
                if (complainData.ModificationOwner == null || complainData.ModificationOwner == "") {
                    toasterService.warningToast("Hiba történt", "Váratlan hiba!");;
                    return false;
                }
                if (complainData.ModificationOwner.length > 100) {
                    toasterService.warningToast("Hiba történt", "Váratlan hiba!");;
                    return false;
                }

                if (complainData.ProcessingStatusId == 74) {
                    if (complainData.ProposalDescription == null || complainData.ProposalDescription == "") {
                        toasterService.warningToast("Hiba történt", "A javaslat leírása kötelező!");
                        return false;
                    }
                }
            }

            return true;
        }

        //Reklamáció mentés befejezése
        var saveComplainFinish = function (complainData, isAllocateToComplain) {
            //Módosítás
            if ($scope.editComplain == true || complainSavedFromAllocation == true) {
                complainData.Id = complainId;
                $http.put(apiurl.complain.updateComplainUrl, complainData).success(function (data) {
                    toasterService.successToast("Módosítás", "Reklamáció sikeresen módosítva!");
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            } else {
                $http.post(apiurl.complain.saveComplainUrl, complainData).success(function (data) {
                    toasterService.successToast("Mentés", "Reklamáció sikeresen létrehozva!");
                    if (isAllocateToComplain) {
                        $scope.ComplainSavedFromAllocateButton = true;
                    } else {
                        $scope.ComplainSavedFromAllocateButton = false;
                    }
                    complainSavedFromAllocation = true;
                    complainData.ProcessingStatusId = 72;
                    $scope.PageTitle = "Reklamáció szerkesztése";
                    complainId = data;
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            }
        };

        //Cégek neveinek lekérdezése adott karakterszó után
        $scope.getCompanyList = function (companyName) {
            return $http.get(apiurl.complain.getCompanyNamesUrl + companyName).then(function (data) {
                return data.data;
            });
        };

        $scope.selectActCompany = function () {
            //A kiválasztott céghez tartozó reklamációk lekérdezése
            $scope.loadAllComplain();
        };

        //Adott céghez tartozó kapcsolattartók lekérdezése adott karakterszó után
        $scope.getContactList = function (companyName, index) {
            return $http.get(apiurl.complain.getContactNamesUrl + companyName + '/' + $scope.actCompany.Id).then(function (data) {
                return data.data;
            });
        };

        //Vissza
        $scope.back = function () {
            location.href = siteurl.complain;
        };

        // New DatePicker settings
        $scope.today = function () {
            $scope.prdate2 = new Date();
            $scope.prdate2 = $filter('date')($scope.prdate2, 'yyyy.MM.dd');
        };
        $scope.today();

        $scope.clear = function () {
            $scope.prdate2 = null;
        };

        $scope.prdate2format = "yyyy.MM.dd";

        $scope.datePickers = {
            dateOfComplain: { isOpen: false, date: new Date() }
        };

        $scope.dtopen = function ($event, datePicker) {
            $event.preventDefault();
            $event.stopPropagation();

            if (!datePicker) {
                $scope.dtopened = true;

            } else {
                datePicker.isOpen = true;
            }
        };

        $scope.SearchObj = { sortIndex: "", orderDirection: true };

        //A kiválasztott céghez tartozó reklamációk lekérdezése
        $scope.loadAllComplain = function (sortIndex) {
            if (!angular.isUndefined(sortIndex)) {
                if (sortIndex === $scope.SearchObj.sortIndex) {
                    $scope.SearchObj.orderDirection = !$scope.SearchObj.orderDirection;
                }
                else {
                    $scope.SearchObj.orderDirection = true;
                    $scope.SearchObj.sortIndex = sortIndex;
                }
            }

            //Szűrési Dto összeállítása
            var filterComplains = createFilterComplainData();

            loadAllComplainFinish(filterComplains);
        };

        //A kiválasztott céghez tartozó reklamációk lekérdezésének befejezése
        var loadAllComplainFinish = function (filterComplains) {
            $http.post(apiurl.complain.getComplainListUrl + $scope.currentPage + '/' + $scope.pageSize, filterComplains).success(function (data) {
                $scope.complains = data.ComplainList;
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };

        //A kiválasztott céghez tartozó reklamációk rendezése
        $scope.loadAllComplainForSort = function (sortIndex) {
            $scope.loadAllComplain(sortIndex);
        }

        //Szűrési dto összeállítása
        var createFilterComplainData = function () {
            return {
                sortIndex: $scope.SearchObj.sortIndex, // A rendezéshez szükséges oszlop neve
                orderDirection: $scope.SearchObj.orderDirection, // A rendezéshez szükséges irány
                CompanyId: $scope.actCompany.Id //Cég azonosítója
            };
        }

        $scope.currentPage = 0;
        $scope.pageSize = 25;
        $scope.actualPage = 0;
        $scope.selectedPageSize = 25;

        //A kiválasztott céghez tartozó reklamációk lapozás
        $scope.pageChanged = function () {
            $scope.currentPage = $scope.current - 1;
            $scope.loadAllComplain();
        };

        //A kiválasztott céghez tartozó reklamációk beállítása 1 oldalon
        $scope.changeSelected = function () {
            if ($scope.selectedPageSize > 0) {
                $scope.pageSize = $scope.selectedPageSize;
                $scope.currentPage = 0;
                $scope.loadAllComplain();
            }
        };

        $scope.dtoptions = {
            startingDay: 1
        };

        //Email küldése
        $scope.SendEmail = function () {
            if ($scope.confirmedColleagueList.length == 0) {
                if ($scope.editComplain == false) {
                    window.location.href = siteurl.sendEmail + '?complainId=' + complainId + '?url=' + $scope.actUrl;
                } else {
                    window.location.href = siteurl.sendEmail + '?url=' + $scope.actUrl;
                }
            } else {
                var message = '';
                angular.forEach($scope.confirmedColleagueList, function (value, key) {
                    angular.forEach($scope.colleagues, function (value2, key2) {
                        if (value.UserId == value2.UserId) {
                            message += value.Username + " - " + value.UserFirstname + ' ' + value.UserLastname;
                        }
                    });
                });
                if (message != '') {
                    toasterService.warningToast("A következő munkatársak már hozzá lettek adva a reklamációhoz: \n" + message);
                } else {
                    if ($scope.editComplain == false) {
                        window.location.href = siteurl.sendEmail + '?complainId=' + complainId + '?url=' + $scope.actUrl;
                    } else {
                        window.location.href = siteurl.sendEmail + '?url=' + $scope.actUrl;
                    }
                }
            }
        };

        //Munkatársak/felhasználók letöltése
        $scope.loadUser = function (query) {
            return userService.getUserListByUserName(query);
        }

        //A kiválasztott felhasználókat berakom a localStoragebe, hogy eltudjam érni az email küldő felületről
        $scope.userTagAdded = function (tag) {
            if (tag.UserId != null) {
                $localStorage.checkUserList = $scope.colleagues;
            }
        };

        //A kiválasztott felhasználókat kitörlöm a localStorageből.
        $scope.userTagRemoved = function (tag) {
            if (tag.UserId != null) {
                var index = $localStorage.checkUserList.length;
                while (index > 0) {
                    index--;
                    if ($localStorage.checkUserList[index].UserId === tag.UserId) {
                        delete $localStorage.checkUserList[index]; //kitörlöm a localStorageből a kitörölt usert
                    }
                }
            }
        };

        //hozzáadott munkatársak törlése
        $scope.delConfirmedColleague = function (confirmedColleague, index) {
            if (confirm('Biztosan törli a munkatársat?')) {
                $scope.confirmedColleagueList.splice(index, 1);
                delete_ConfirmedColleagueIdList.push(confirmedColleague.UserId);
                if ($scope.complainData.ProcessingStatusId == 73) {
                    checkColleagueList();
                }
            }
        };

        //A munka kiosztása mentés gomb elrejtése/megjelenítése
        var checkColleagueList = function () {
            //Ha változott a hozzáadott munkatársak munka kiosztott státusz alatt, akkor megjelenítjük újra a mentés gombot.
            if ($scope.complainData.ProcessingStatusId == 73) {
                if (original_ConfirmedColleagueList.length != $scope.confirmedColleagueList.length) {
                    $scope.modifiedConfirmedColleague = true;
                } else {
                    $scope.modifiedConfirmedColleague = false;
                }

                if (delete_ConfirmedColleagueIdList.length > 0) {
                    $scope.modifiedConfirmedColleague = true;
                }
            }
        }

        //munka kiosztása
        $scope.allocationOfWork = function () {
            if ($scope.complainData.Description == null || $scope.complainData.Description == '') {
                alert("A panasz leírása kötelező!");
            } else {
                $scope.complainToAllocation = true; //reklamáció kiosztva státuszú
                $scope.complainToFixed = false;
                $scope.tabClick = 'allocationOfWork';
                $scope.PageTitle = "Reklamáció szerkesztése";
                $scope.save(true); //Mentünk
            }
        };

        $scope.cancelFromAllocationOfWork = function () {
            $scope.complainToFixed = true;
            $scope.complainToAllocation = false;
            $scope.complainToProcessing = false;
            $scope.tabClick = 'complainData';
        };

        //Reklamáció mentése kiosztott státuszzal
        $scope.saveToAllocation = function (IsProcessingToComplain) {
            if (IsProcessingToComplain) {
                if (($scope.modifiedConfirmedColleague == null || $scope.modifiedConfirmedColleague == false) && (complainSavedFromProcessing || $scope.complainData.ProcessingStatusId == 73)) {
                    return;
                }
            }

            $scope.complainData.ModificationOwner = userName;
            var complainData = $scope.complainData;
            complainData.confirmedColleagueIdList = confirmedUserIdList;
            complainData.delete_ConfirmedColleagueIdList = delete_ConfirmedColleagueIdList;
            complainData.hasToDoOfComplain = 1;

            if (complainSavedFromProcessing == false) {
                if ($scope.modifiedConfirmedColleague != null && $scope.modifiedConfirmedColleague == true) {
                    if (!confirm('Valóban módosítja a reklamációt?')) {
                        $scope.cancelFromProcessing();
                        return;
                    }
                } else {
                    if (!confirm('Valóban kiosztja munkára a reklamációt?')) {
                        $scope.cancelFromProcessing();
                        return;
                    }
                }
            } else {
                if ($scope.modifiedConfirmedColleague != null && $scope.modifiedConfirmedColleague == true) {
                    if (!confirm('Valóban módosítja a reklamációt?')) {
                        $scope.cancelFromProcessing();
                        return;
                    }
                }
            }

            if (!Validate(complainData)) {
                return;
            }

            $http.put(apiurl.complain.actComplainModifiedUrl, complainData).success(function (data) {
                if (complainSavedFromAllocation == true) {
                    if ($scope.modifiedConfirmedColleague != null && $scope.modifiedConfirmedColleague == true) {
                        toasterService.successToast("Módosítás", "Reklamáció sikeresen módosítva!");
                        $scope.modifiedConfirmedColleague = false;
                    }
                } else {
                    if ($scope.modifiedConfirmedColleague != null && $scope.modifiedConfirmedColleague == true) {
                        toasterService.successToast("Módosítás", "Reklamáció sikeresen módosítva!");
                        $scope.modifiedConfirmedColleague = false;
                    } else {
                        toasterService.successToast("Mentés", "Reklamáció sikeresen kiosztva munkára!");
                        $scope.complainData.ProcessingStatusId = 73;
                        $scope.modifiedConfirmedColleague = false;
                    }
                    complainSavedFromProcessing = true;
                }
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };

        //feldolgozás megkezdése
        $scope.processing = function () {
            $scope.complainToProcessing = true; //reklamáció feldolgozás megkezdése
            $scope.complainToAllocation = false;
            $scope.tabClick = 'processing';
            $scope.saveToAllocation(true);
        };

        $scope.cancelFromProcessing = function () {
            $scope.complainToAllocation = true;
            $scope.complainToProcessing = false;
            $scope.tabClick = 'allocationOfWork';
        };

        //Reklamáció mentése feldolgozott státusszal
        $scope.saveToProcessing = function (IsSummaryToComplain) {
            if (IsSummaryToComplain) {
                if (($scope.modifiedProposalDescription == null || $scope.modifiedProposalDescription == false) && (complainSavedFromSummary || $scope.complainData.ProcessingStatusId == 74)) {
                    return;
                }
            }

            $scope.complainData.ModificationOwner = userName;
            var complainData = $scope.complainData;
            complainData.hasToDoOfComplain = 2;

            if (complainSavedFromSummary == false) {
                if ($scope.modifiedProposalDescription != null && $scope.modifiedProposalDescription == true) {
                    if (!confirm('Valóban módosítja a reklamációt?')) {
                        $scope.cancelFromSummary();
                        return;
                    }
                } else {
                    if (!confirm('Valóban elkezdi feldolgozni a reklamációt?')) {
                        $scope.cancelFromSummary();
                        return;
                    }
                }
            } else {
                if ($scope.modifiedProposalDescription != null && $scope.modifiedProposalDescription == true) {
                    if (!confirm('Valóban módosítja a reklamációt?')) {
                        $scope.cancelFromSummary();
                        return;
                    }
                }
            }

            if (!Validate(complainData)) {
                return;
            }

            $http.put(apiurl.complain.actComplainModifiedUrl, complainData).success(function (data) {
                if (complainSavedFromSummary == true) {
                    if ($scope.modifiedProposalDescription != null && $scope.modifiedProposalDescription == true) {
                        toasterService.successToast("Módosítás", "Reklamáció sikeresen módosítva!");
                        $scope.modifiedProposalDescription = false;
                    }
                } else {
                    if ($scope.modifiedProposalDescription != null && $scope.modifiedProposalDescription == true) {
                        toasterService.successToast("Módosítás", "Reklamáció sikeresen módosítva!");
                        $scope.modifiedProposalDescription = false;
                    } else {
                        toasterService.successToast("Mentés", "Reklamáció feldolgozása sikeresen elkezdődött!");
                        $scope.complainData.ProcessingStatusId = 74;
                        $scope.modifiedProposalDescription = false;
                    }
                    complainSavedFromSummary = true;
                }
            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });
        };

        //Reklamáció összegzése
        $scope.summary = function () {
            if ($scope.complainData.ProposalDescription == null || $scope.complainData.ProposalDescription == "") {
                toasterService.warningToast("Figyelem", "A javaslat leírása kötelező!");
                return;
            }
            $scope.complainToSummary = true; //reklamáció összegzés megkezdése
            $scope.complainToProcessing = false;
            $scope.tabClick = 'summary';
            $scope.saveToProcessing(true);
        };

        $scope.cancelFromSummary = function () {
            $scope.complainToProcessing = true;
            $scope.complainToSummary = false;
            $scope.tabClick = 'processing';
        };

        //reklamáció lezárása
        $scope.close = function () {
            $scope.complainData.ModificationOwner = userName;
            var complainData = $scope.complainData;
            complainData.hasToDoOfComplain = 3;

            if (!confirm('Valóban lezárja a reklamációt?')) {
                return;
            }

            if (!Validate(complainData)) {
                return;
            }

            $http.put(apiurl.complain.actComplainModifiedUrl, complainData).success(function (data) {
                toasterService.successToast("Mentés", "Reklamáció sikeresen lezárva!");
                $scope.complainData.ProcessingStatusId = 75;
                $http.get(apiurl.complain.getComplainUrl + complainId).success(function (data) {
                    //A reklamációhoz tartozó összegzések lekérdezése
                    $scope.complainToSummaryList = data.ComplainToSummaryList;
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };

        //Panasz újrakezdése
        $scope.startAgain = function () {
            $scope.complainData.ModificationOwner = userName;
            var complainData = $scope.complainData;
            complainData.hasToDoOfComplain = 4;

            if (!confirm('Valóban újrakezdi a reklamációt?')) {
                return;
            }

            if (!Validate(complainData)) {
                return;
            }

            $http.put(apiurl.complain.actComplainModifiedUrl, complainData).success(function (data) {
                toasterService.successToast("Mentés", "Reklamáció sikeresen újrakezdve!");
                $scope.complainData.ProcessingStatusId = 72;
                refreshComplainData();
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };

        var refreshComplainData = function () {
            $scope.tabClick = 'complainData';
            $scope.PageTitle = "Új reklamáció";
            $scope.complainToAllocation = false;
            $scope.complainToProcessing = false;
            $scope.complainToSummary = false;
            original_ProposalDescription = '';
            $scope.confirmedColleagueList = [];
            original_ConfirmedColleagueList = [];
            delete_ConfirmedColleagueIdList = [];
            confirmedUserIdList = [];
            confirmedColleagueIdList = [];
            complainSavedFromAllocation = false;
            complainSavedFromProcessing = false;
            complainSavedFromSummary = false;
        };

        $scope.$watch("complainData.ProposalDescription", function () {
            //Ha változott a javaslat feldolgozott státusz alatt, akkor megjelenítjük újra a mentés gombot
            if ($scope.complainData.ProcessingStatusId == 74 && $scope.complainData.ProposalDescription != '') {
                if ($scope.complainData.ProposalDescription != original_ProposalDescription) {
                    $scope.modifiedProposalDescription = true;
                } else {
                    $scope.modifiedProposalDescription = false;
                }
            }
        });

        // Upload filter
        var uploadFilter = {
            name: 'imageFilter',
            fn: function (item /*{File|FileLikeObject}*/, options) {
                var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                return supportedFileType.indexOf(type) !== -1;
            }
        };

        //Fájl feltöltés alapbeállítása
        function createUploader() {
            var uploader = new FileUploader({
                url: apiurl.complain.uploadFileUrl //url cím megadása
            });
            uploader.filters.push(uploadFilter);

            //Sikeresen hozzáadtuk fileokat az uploaderhez(callback metódus)
            uploader.onAfterAddingFile = function (item) {
                if ($scope.complainData == null || $scope.complainData.Identification == null || $scope.complainData.Identification == '') {
                    toasterService.warningToast("Figyelem", "Hiba történt a reklamáció betöltése közben!");
                    item.remove();
                }
                if ($scope.fileObj.FileDto.length > 0) {
                    var error = "";
                    angular.forEach($scope.fileObj.FileDto, function (value, key) {
                        if (value.FileName == item.FileName) {
                            error += item.FileName
                            error += "\n";
                        }
                    });
                    if (error != "") {
                        toasterService.warningToast("Figyelem", "A következő file nevek már foglaltak:\n " + error);
                        item.remove();
                    }
                }
                console.info('onAfterAddingFile', item);
                item.formData.push({
                    ComplainId: complainId,
                    ComplainIdentification: $scope.complainData.Identification,
                    Name: "",
                    FileName: null,
                    Description: ""
                });
            };

            //Sikertelenül hozzáadtuk fileokat az uploaderhez(callback metódus)
            uploader.onWhenAddingFileFailed = function (item, filter, options) {
                toasterService.warningToast("Hiba", "Nem támogatott file típus!");
            }

            return uploader;
        }

        $scope.fileObj.uploader = createUploader();
        //Sikeres volt a feltöltés (callback metódus)
        $scope.fileObj.uploader.onSuccessItem = function (item, response, status, headers) {
            response.FileName = generateGetFileUrl(response.ComplainId, response.FileName, $scope.complainData.Identification);
            if (response.FileType.indexOf('jpg') != -1 || response.FileType.indexOf('png') != -1 || response.FileType.indexOf('jpeg') != -1 || response.FileType.indexOf('bmp') != -1 || response.FileType.indexOf('gif') != -1) {
                $scope.fileObj.ImageDto.push(response);
            } else {
                $scope.fileObj.FileDto.push(response);
            }
            item.remove();
        };
        //Sikertelen volt a feltöltés (callback metódus)
        $scope.fileObj.uploader.onErrorItem = function (item, response, status, headers) {
            toasterService.errorFromServerToast(response);
        };

        //File törlés
        $scope.DeleteFile = function (fileId) {
            function success(data) {
                angular.forEach($scope.fileObj.FileDto, function (value, key) {
                    if (value.Id === data.Id) {
                        $scope.fileObj.FileDto.splice(key, 1);
                    }
                });
                angular.forEach($scope.fileObj.ImageDto, function (value, key) {
                    if (value.Id === data.Id) {
                        $scope.fileObj.ImageDto.splice(key, 1);
                    }
                });
            }
            function error(data, status) {
                toasterService.errorFromServerToast(data);
            }

            $http.delete(apiurl.complain.deleteFileUrl + fileId)
            .success(success)
            .error(error);
        };

        //File letöltése
        $scope.DownloadFile = function (fileId) {
            function success(data) {
                while (data.indexOf('"') !== -1) {
                    data = data.replace("\"", '');
                }
                window.open(data);
            }
            function error(data, status) {
                toasterService.errorFromServerToast(data);
            }

            $http.get(apiurl.complain.downloadFileUrl + '?fileId=' + fileId + '&localChildDirectoryName=' + $scope.complainData.Identification)
            .success(success)
            .error(error);
        };
    }
})();
