(function () {

    angular.module('IMasterApp')
        .controller('editRemoteServiceController', editRemoteServiceController);

    editRemoteServiceController.$inject = ['$scope', '$http', '$filter', '$window', 'siteurl', 'apiurl', 'toasterService'];
    function editRemoteServiceController($scope, $http, $filter, $window, siteurl, apiurl, toasterService) {
        var remoteServiceId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1); //eszköz szervíz id-ja
        $scope.isNewRemoteService = remoteServiceId == "EditRemoteService" || remoteServiceId == 0 ? true : false; //létrehozás/szerkesztés

        var backUrl = (/url=([^&=]+)/g).exec(location.search);
        backUrl = backUrl !== null ? backUrl[1].substring(0, backUrl[1].lastIndexOf("/")) : siteurl.remoteDevice;

        if ($scope.isNewRemoteService) {
            $scope.Title = "Új szerviz hozzáadása";
            var remoteDeviceIDNR = "";
            if (location.href.indexOf("IDNR=") !== -1) {
                remoteDeviceIDNR = window.location.href.substring(window.location.href.indexOf("?IDNR=") + 6, window.location.href.indexOf("?url=").valueOf()); //eszköz IDNR számának kiszedése
            }
            $scope.actRemoteService = {
                Id: "",
                Date: "",
                IDNR: remoteDeviceIDNR,
                Activity: "",
                Status: ""
            };
        } else {
            $scope.Title = "Szervíz módosítása";
            $http.get(apiurl.remoteDevice.getRemoteService + remoteServiceId).success(function (data) {
                $scope.actRemoteDevice = data;
            });
        }

        //szervíz státuszok letöltése
        $http.get(apiurl.remoteDevice.getRemoteServiceStatuses).success(function (data) {
            $scope.serviceStatuses = data;
            if ($scope.isNewRemoteService) $scope.actRemoteService.Status = data[0];
        });


        //szervíz ellenőrzése
        var checkActRemoteService = function (actRemoteService) {
            if (actRemoteService.Date == "") {
                alert("A szervíz dátumának megadása kötelező!");
                return false;
            }
            if (actRemoteService.Activity == "") {
                alert("A szervíz tevékenységének megadása kötelező!");
                return false;
            }
            if (actRemoteService.IDNR == "") {
                alert("A készülék INDR számának megadása kötelező!");
                return false;
            }
            if (angular.isUndefined(actRemoteService.Status) || actRemoteService.Status.Id == 0) {
                alert("A státusz kiválasztása kötelező!");
                return false;
            }

            return true;

        };

        //szervíz mentése
        $scope.saveRemoteService = function () {
            $scope.actRemoteService.Date = $filter('date')($scope.datePickers.dateOfRemoteService.date, 'yyyy.MM.dd');
            if (!checkActRemoteService($scope.actRemoteService)) return;
            if ($scope.isNewRemoteService) {
                $http.post(apiurl.remoteDevice.saveRemoteService, $scope.actRemoteService).success(function (data) {
                    location.href = backUrl;
                });
            } else {
                $http.put(apiurl.remoteDevice.updateRemoteService, $scope.actRemoteService).success(function (data) {
                    location.href = backUrl;
                });
            }
        };

        //Visszalépés
        $scope.backPage = function () {
            if (confirm("Valóban ki akar lépni mentés nélkül?")) {
                location.href = backUrl;
            }
        };

        // New DatePicker settings
        $scope.today = function () {
            $scope.prdate2 = new Date();
            $scope.prdate2 = $filter('date')($scope.prdate2, 'yyyy.MM.dd');
        };
        $scope.today();

        $scope.clear = function () {
            $scope.prdate2 = null;
        };

        $scope.prdate2format = "yyyy.MM.dd";

        $scope.datePickers = {
            dateOfRemoteService: { isOpen: false, date: new Date() }
        };

        $scope.dtopen = function ($event, datePicker) {
            $event.preventDefault();
            $event.stopPropagation();

            if (!datePicker) {
                $scope.dtopened = true;

            } else {
                datePicker.isOpen = true;
            }
        };

        $scope.dtoptions = {
            startingDay: 1
        };
    }

})();
