(function () {

    angular.module('IMasterApp')
        .factory('CheckVoucher', CheckVoucher);

    function CheckVoucher() {
        return {
            Check: function (products, voucherData) {
                var therIsStrictProduct = false;
                angular.forEach(products, function (pr) {
                    if (pr.Type.Id === 28) {
                        therIsStrictProduct = true;
                    }
                });

                if (therIsStrictProduct && voucherData.VoucherType !== 49 && voucherData.VoucherType !== 48) {
                    if (voucherData.Status === 41 && (voucherData.VoucherType == 45 || voucherData.VoucherType == 47)) {
                        alert("Nyugtára és szállítólevélre nem lehet felvinni szigorú számadású terméket!");
                        return false;
                    }
                    var msg = [];
                    if (!voucherData.CustomerTaxNumber
                        && !voucherData.CustomerEUTaxNumber
                    ) {
                        msg.push("Szigorú számadású termék esetén, a vevő adószámának vagy EU adószámának kitöltése kötelező!");
                    }
                    if (!voucherData.BuyerPersonName
                        || !voucherData.BuyerPersonTaxNumber
                    ) {
                        msg.push("Szigorú számadású termék esetén, a vevő személy nevének és adóazonosító jelének kitöltése kötelező!");
                    }
                    if (msg.length) {
                        alert(msg.join("\n"));
                        return false;
                    }
                }

                return true;
            }
        }
    }

})();
