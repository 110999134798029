(function () {

    angular.module('IMasterApp')
        .controller('TaxRate_controller', TaxRate_controller)
        .controller('editTaxRate_controller', editTaxRate_controller);

    TaxRate_controller.$inject = ['$http', '$scope', '$window', 'siteurl', 'apiurl', 'toasterService', 'taxRateService'];
    function TaxRate_controller($http, $scope, $window, siteurl, apiurl, toasterService, taxRateService) {

        $scope.taxrates = [];

        function getAllTaxRate() {
            taxRateService.getAllTaxRate().success(function (data) {
                $scope.taxrates = data;
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };

        getAllTaxRate();

        $scope.newTaxRate = function () {
            location.href = siteurl.editTaxRate + "/0";
        };

        $scope.editTaxRate = function (taxrate) {
            location.href = siteurl.editTaxRate + "/" + taxrate.Id;
        };

        $scope.deleteTaxRate = function (taxrate, index) {
            if (confirm("Biztos benne, hogy törli az áfa kulcsot?")) {
                taxRateService.deleteTaxRateById(taxrate.Id).success(function () {
                    toasterService.saveSuccess();
                    $scope.taxrates.splice(index);
                });
            }
        };
    }

    editTaxRate_controller.$inject = ['$http', '$scope', '$filter', 'siteurl', 'apiurl', 'toasterService', 'taxRateService'];
    function editTaxRate_controller($http, $scope, $filter, siteurl, apiurl, toasterService, taxRateService) {
        $scope.title = "";

        var taxRateId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);

        if (taxRateId != 0) {
            $scope.title = "Áfakulcs módosítása";
            taxRateService.getTaxRateById(taxRateId).success(function (data) {
                $scope.taxrate = data;
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        } else {
            $scope.title = "Áfakulcs létrehozása";
            $scope.taxrate = {
                Id: 0,
                TaxRateName: '',
                TaxRateValue: '',
                TaxContained: '',
                TaxValidFrom: '',
                TaxValidTo: ''
            }
        }

        // New DatePicker settings
        $scope.today = function () {
            if (dtflag) {
                $scope.taxrate.TaxValidFrom = new Date();
                $scope.taxrate.TaxValidFrom = $filter('date')($scope.taxrate.TaxValidFrom, 'yyyy.MM.dd');
            } else {
                $scope.taxrate.TaxValidTo = new Date();
                $scope.taxrate.TaxValidTo = $filter('date')($scope.taxrate.TaxValidTo, 'yyyy.MM.dd');
            }
        };

        $scope.clear = function () {
            if (dtflag) {
                $scope.taxrate.TaxValidFrom = null;
            } else {
                $scope.taxrate.TaxValidTo = null;
            }
        };

        $scope.dddateformat = "yyyy.MM.dd";
        $scope.dtopened = [];

        $scope.dtopen = function ($event, $index) {
            $scope.dtflag = true;

            $event.preventDefault();

            $scope.actIndex = $index;
            $scope.dtopened[$index] = true;
        };

        $scope.dodopen = function ($event) {
            $scope.dtflag = false;

            $event.preventDefault();
            $event.stopPropagation();

            $scope.dodopened = true;
        };

        $scope.dtoptions = {
            startingDay: 1,
        };

        $scope.save = function () {
            var taxData = $scope.taxrate;
            taxData.TaxValidFrom = $filter('date')($scope.taxrate.TaxValidFrom, 'yyyy.MM.dd');
            taxData.TaxValidTo = $filter('date')($scope.taxrate.TaxValidTo, 'yyyy.MM.dd');
            if (confirm("Biztos benne, hogy menti az adatokat?")) {
                if (taxRateId == 0) {
                    taxRateService.createTaxRate(taxData).success(function () {
                        toasterService.saveSuccess();
                        goBack();
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data);
                    });
                } else {
                    taxRateService.updateTaxRate(taxData).success(function () {
                        toasterService.saveSuccess();
                        goBack();
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data);
                    });
                }
            }
        };

        $scope.back = function () {
            if (confirm("Biztos benne, hogy elveti a módosításokat és visszalép?")) {
                goBack();
            }
        };

        function goBack() {
            location.href = siteurl.taxRate;
        };
    }

})();
