(function () {

    angular.module('IMasterApp')
        .controller('statisticsController', statisticsController);

    statisticsController.$inject = ['$scope', '$rootScope', '$http', '$filter', 'apiurl', 'toasterService'];
    function statisticsController($scope, $rootScope, $http, $filter, apiurl, toasterService) {

        var remoteDeviceId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1); //eszköz id-ja
        $scope.isColor = $rootScope.isColor;
        var ctx;
        var destroyLines = function (chartName) {
            var lineName = chartName + "Line";
            if (window[lineName] !== undefined) {
                window[lineName].destroy();
            }
            return lineName;
        }
        destroyLines("blackCounterChart");
        destroyLines("colorCounterChart");

        $scope.loadChart = function (chartName, data) {
            var lineName = destroyLines(chartName);
            ctx = document.getElementById(chartName).getContext("2d");
            window[lineName] = new Chart(ctx).Line(data, {
                responsive: true
            });
        }

        $scope.blackData = [];
        $scope.blackCounterChart = {
            labels: [],
            datasets: [
                {
                    label: "Nyomtatott fekete oldal",
                    fillColor: "rgba(212,212,212,0.2)",
                    strokeColor: "rgba(200,200,200,1)",
                    pointColor: "rgba(212,212,212,1)",
                    pointStrokeColor: "#fff",
                    pointHighlightFill: "#fff",
                    pointHighlightStroke: "rgba(220,220,220,1)",
                    data: []
                }]
        };
        $scope.colorCounterChart = {
            labels: [],
            datasets: [
                {
                    label: "Nyomtatott színes oldal",
                    fillColor: "rgba(212,212,212,0.2)",
                    strokeColor: "rgba(200,200,200,1)",
                    pointColor: "rgba(212,212,212,1)",
                    pointStrokeColor: "#fff",
                    pointHighlightFill: "#fff",
                    pointHighlightStroke: "rgba(220,220,220,1)",
                    data: []
                }]
        };

        var getDataFromServer = function (searchStatistic, dataType) {
            $http.post(apiurl.remoteDevice.getStatisticDataOfDevice, searchStatistic).success(function (data) {
                if (data.length != 0) {
                    if (dataType == "blackCounterChart") {
                        $scope.blackData = data;
                    } else {
                        $scope.colorData = data;
                    }
                    getChartData(data, dataType);
                    $scope.loadChart(dataType, $scope[dataType]);
                } else {
                    toasterService.warningToast("Figyelem!", "Az adott intervallumban nem állnak rendelkezésre statisztikai adatok!");
                }
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };

        var calculateIntervalAvarage = function (array, start, interValLength, color) {
            var interValSum = 0;
            var endOfLoop = start + interValLength > array.length ? array.length : start + interValLength;
            for (var i = start; i < endOfLoop; i++) {
                interValSum += array[i][color];
            }
            return (interValSum / (endOfLoop - start == 0 ? 1 : endOfLoop - start)).toFixed(0);
        };

        var getChartData = function (array, chart) {
            var dateFormatter = "MM.dd";
            var iterator = 1;

            if (array.length > 30) {
                dateFormatter = "MM.dd";
                iterator = 7;
            }
            if (array.length > 150) {
                dateFormatter = "MMM";
                iterator = 32;
            }
            if (array.length > 366) {
                dateFormatter = "yy.MM";
                iterator = 32;
            }
            $scope[chart].labels = [];
            for (var j = 0; j < 1; j++) {
                $scope[chart].datasets[j].data = [];
                var reachedEnd = false;
                for (var i = 0; i < array.length; i += iterator) {
                    $scope[chart].labels.push($filter('date')(array[i].ActualDate, dateFormatter));
                    if (chart == "blackCounterChart") {
                        $scope[chart].datasets[j].data.push(calculateIntervalAvarage(array, i, iterator, "BlackReducing"));
                    } else {
                        $scope[chart].datasets[j].data.push(calculateIntervalAvarage(array, i, iterator, "ColorReducing"));
                    }
                    if (i + iterator > array.length && !reachedEnd) {
                        iterator = (array.length - 1) - i;
                        reachedEnd = true;
                    }
                }
            }
        };


        //Datepickers definitions
        $scope.format = "yyyy.MM.dd";
        $scope.datePickers = {};
        $scope.datePickers.blackCounter = {};
        $scope.datePickers.colorCounter = {};
        $scope.openedDp = [];
        $scope.dateOptions = { startingDay: 1 };

        $scope.openDatePicker = function ($event, index) {
            $event.preventDefault();
            $scope.openedDp[index] = true;
        };

        $scope.searchByDate = function (type) {
            var searchStatistic = {};
            var dataType = "";

            if (type === 'blackCounterChart') {
                if ($scope.datePickers.blackCounter.FromDate !== null
                    && !angular.isUndefined($scope.datePickers.blackCounter.FromDate)
                    && $scope.datePickers.blackCounter.ToDate !== null
                    && !angular.isUndefined($scope.datePickers.blackCounter.ToDate)) {
                    searchStatistic = {
                        Id: remoteDeviceId,
                        FromDate: $filter('date')($scope.datePickers.blackCounter.FromDate, $scope.format),
                        ToDate: $filter('date')($scope.datePickers.blackCounter.ToDate, $scope.format)
                    }
                    dataType = "blackCounterChart";
                }
            }

            if (type === 'colorCounterChart') {
                if ($scope.datePickers.colorCounter.FromDate !== null
                    && !angular.isUndefined($scope.datePickers.colorCounter.FromDate)
                    && $scope.datePickers.colorCounter.ToDate !== null
                    && !angular.isUndefined($scope.datePickers.colorCounter.ToDate)) {
                    searchStatistic = {
                        Id: remoteDeviceId,
                        FromDate: $filter('date')($scope.datePickers.colorCounter.FromDate, $scope.format),
                        ToDate: $filter('date')($scope.datePickers.colorCounter.ToDate, $scope.format)
                    }
                    dataType = "colorCounterChart";
                }
            }

            if (angular.isDefined(searchStatistic.FromDate) && angular.isDefined(searchStatistic.ToDate)) {
                getDataFromServer(searchStatistic, dataType);
            }
        };

        $scope.exportBlackCountersData = function () {
            var searchStatistic = {};

            if ($scope.datePickers.blackCounter.FromDate !== null
                && !angular.isUndefined($scope.datePickers.blackCounter.FromDate)
                && $scope.datePickers.blackCounter.ToDate !== null
                && !angular.isUndefined($scope.datePickers.blackCounter.ToDate)) {
                searchStatistic = {
                    Id: remoteDeviceId,
                    FromDate: $filter('date')($scope.datePickers.blackCounter.FromDate, $scope.format),
                    ToDate: $filter('date')($scope.datePickers.blackCounter.ToDate, $scope.format)
                }
            }

            if (angular.isDefined(searchStatistic.FromDate) && angular.isDefined(searchStatistic.ToDate)) {
                $http.get(apiurl.remoteDevice.getRemoteDevice + remoteDeviceId).success(function (device) {
                    $http({
                        method: 'POST',
                        url: apiurl.remoteDevice.exportBlackCountersData,
                        data: searchStatistic,
                        headers: {
                            'Content-type': 'application/json'
                        },
                        responseType: 'arraybuffer'
                    }).success(function (dataPdf) {
                        var blob = new Blob([dataPdf], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                        var actDate2 = $filter('date')(new Date(), 'yyyy.MM.dd.');
                        navigator.saveBlob = navigator.saveBlob || navigator.msSaveOrOpenBlob || navigator.mozSaveBlob || navigator.webkitSaveBlob;
                        window.saveAs = window.saveAs || window.webkitSaveAs || window.mozSaveAs || window.msSaveAs;
                        if (window.saveAs) {
                            window.saveAs(blob, actDate2 + device.Company.Name + "_" + device.Name + ".xlsx");
                        } else if (navigator.saveBlob) {
                            navigator.saveBlob(blob, actDate2 + device.Company.Name + "_" + device.Name + ".xlsx");
                        } else {
                            var url = URL.createObjectURL(blob);
                            var link = document.createElement("a");
                            link.setAttribute("href", url);
                            link.setAttribute("download", actDate2 + device.Company.Name + "_" + device.Name + ".xlsx");
                            var event = document.createEvent('MouseEvents');
                            event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                            link.dispatchEvent(event);
                        }
                    });
                });
            }
        };

        $scope.exportColorCountersData = function () {
            var searchStatistic = {};

            if ($scope.datePickers.colorCounter.FromDate !== null
                && !angular.isUndefined($scope.datePickers.colorCounter.FromDate)
                && $scope.datePickers.colorCounter.ToDate !== null
                && !angular.isUndefined($scope.datePickers.colorCounter.ToDate)) {
                searchStatistic = {
                    Id: remoteDeviceId,
                    FromDate: $filter('date')($scope.datePickers.colorCounter.FromDate, $scope.format),
                    ToDate: $filter('date')($scope.datePickers.colorCounter.ToDate, $scope.format)
                }
            }

            if (angular.isDefined(searchStatistic.FromDate) && angular.isDefined(searchStatistic.ToDate)) {

                $http.get(apiurl.remoteDevice.getRemoteDevice + remoteDeviceId).success(function (device) {
                    $http({
                        method: 'POST',
                        url: apiurl.remoteDevice.exportBlackCountersData,
                        data: searchStatistic,
                        headers: {
                            'Content-type': 'application/json'
                        },
                        responseType: 'arraybuffer'
                    }).success(function (dataPdf) {
                        var blob = new Blob([dataPdf], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                        var actDate2 = $filter('date')(new Date(), 'yyyy.MM.dd.');
                        navigator.saveBlob = navigator.saveBlob || navigator.msSaveOrOpenBlob || navigator.mozSaveBlob || navigator.webkitSaveBlob;
                        window.saveAs = window.saveAs || window.webkitSaveAs || window.mozSaveAs || window.msSaveAs;
                        if (window.saveAs) {
                            window.saveAs(blob, actDate2 + device.Company.Name + "_" + device.Name + ".xlsx");
                        } else if (navigator.saveBlob) {
                            navigator.saveBlob(blob, actDate2 + device.Company.Name + "_" + device.Name + ".xlsx");
                        } else {
                            var url = URL.createObjectURL(blob);
                            var link = document.createElement("a");
                            link.setAttribute("href", url);
                            link.setAttribute("download", actDate2 + device.Company.Name + "_" + device.Name + ".xlsx");
                            var event = document.createEvent('MouseEvents');
                            event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                            link.dispatchEvent(event);
                        }
                    });
                });
            }
        };
    }

})();
