(function () {

    angular.module('IMasterApp')
        .controller('orders_controller', orders_controller)
        .controller('EditOrderController', EditOrderController);

    orders_controller.$inject = ['$scope', 'siteurl', 'apiurl', 'toasterService', 'OrdersService'];
    function orders_controller($scope, siteurl, apiurl, toasterService, OrdersService) {
        $scope.PageSize = 25;
        $scope.PageIndex = 0;

        //Datepickers definitions
        $scope.format = "yyyy.MM.dd";

        $scope.openedDp = [];
        $scope.openDatePicker = function ($event, index) {
            $event.preventDefault();

            $scope.openedDp[index] = true;
        };
        $scope.DateOptions = {
            startingDay: 1
        };

        var searchObj = { SortIndex: '', OrderDirection: true };

        $scope.filter = {
            Supplier: '',
            Warehouse: '',
            OrderNumber: '',
            StatusDuringRecording: false,
            StatusInOrder: false,
            StatusConfirmed: false,
            FromDate: null,
            ToDate: null,
            FromNetPrice: '',
            ToNetPrice: '',
            FromVatPrice: '',
            ToVatPrice: '',
            FromGrossPrice: '',
            ToGrossPrice: '',
            PageIndex: 0,
            PageSize: 25,
            SortIndex: '',
            OrderDirection: true
        };

        function createFilterData() {
            return {
                Supplier: $scope.filter.Supplier,
                Warehouse: $scope.filter.Warehouse,
                OrderNumber: $scope.filter.OrderNumber,
                StatusDuringRecording: $scope.filter.StatusDuringRecording,
                StatusInOrder: $scope.filter.StatusInOrder,
                StatusConfirmed: $scope.filter.StatusConfirmed,
                FromDate: $scope.filter.FromDate,
                ToDate: $scope.filter.ToDate,
                FromNetPrice: $scope.filter.FromNetPrice,
                ToNetPrice: $scope.filter.ToNetPrice,
                FromVatPrice: $scope.filter.FromVatPrice,
                ToVatPrice: $scope.filter.ToVatPrice,
                FromGrossPrice: $scope.filter.FromGrossPrice,
                ToGrossPrice: $scope.filter.ToGrossPrice,
                PageIndex: $scope.PageIndex,
                PageSize: $scope.PageSize,
                SortIndex: searchObj.SortIndex,
                OrderDirection: searchObj.OrderDirection
            };
        };

        function clearFilterData() {
            return {
                Supplier: '',
                Warehouse: '',
                OrderNumber: '',
                StatusDuringRecording: false,
                StatusInOrder: false,
                StatusConfirmed: false,
                FromDate: null,
                ToDate: null,
                FromNetPrice: '',
                ToNetPrice: '',
                FromVatPrice: '',
                ToVatPrice: '',
                FromGrossPrice: '',
                ToGrossPrice: '',
                PageIndex: 0,
                PageSize: $scope.PageSize,
                SortIndex: '',
                OrderDirection: true
            };
        };

        $scope.useFilter = function () {
            $scope.loadData();
        };

        $scope.deleteSearch = function () {
            $scope.filter = clearFilterData();
            $scope.loadData();
        };

        $scope.loadData = function (sortIndex) {
            if (!angular.isUndefined(sortIndex)) {
                if (sortIndex === searchObj.SortIndex) {
                    searchObj.OrderDirection = !searchObj.OrderDirection;
                }
                else {
                    searchObj.OrderDirection = true;
                    searchObj.SortIndex = sortIndex;
                }
            }

            var filter = createFilterData();

            OrdersService.getOrdersWithPager(filter).success(function (data) {
                $scope.orders = data.OrderList;
                $scope.totalItems = data.TotalItems;
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };

        $scope.loadData();

        $scope.NewOrder = function () {
            window.location.href = siteurl.editOrder + '/0';
        };

        $scope.setSelectedOrder = function (selected) {
            window.location.href = siteurl.editOrder + '/' + selected.OrderId;
        };

        $scope.createDocument = function (selected) {
            if (selected.StatusId == 30) {
                OrdersService.updateOrderForPrint(selected.OrderId).success(function () {
                    $scope.loadData();
                    if (confirm("Nyomtatásnál látszódjon a szállítási cím?")) {
                        location.href = apiurl.order.saveDocument + '/' + selected.OrderId + "/false";
                    } else {
                        location.href = apiurl.order.saveDocument + '/' + selected.OrderId + "/true";
                    }
                });
            } else {
                if (confirm("Nyomtatásnál látszódjon a szállítási cím?")) {
                    location.href = apiurl.order.saveDocument + '/' + selected.OrderId + "/false";
                } else {
                    location.href = apiurl.order.saveDocument + '/' + selected.OrderId + "/true";
                }
            }
        };

        $scope.changeSelected = function () {
            $scope.PageIndex = 0;
            $scope.loadData();
        };

        $scope.pageChanged = function () {
            $scope.PageIndex = $scope.current - 1;
            $scope.loadData();
        };
    }


    EditOrderController.$inject = ['$scope', '$http', 'siteurl', '$window', 'apiurl', 'OrdersService', 'toasterService', 'PriceCounter', 'userService'];
    function EditOrderController($scope, $http, siteurl, $window, apiurl, OrdersService, toasterService, PriceCounter, userService) {

        var orderId = parseInt(window.location.href.substring(window.location.href.lastIndexOf("EditOrder/") + 10));

        // Initialize
        $scope.AddressDisabled = false;
        $scope.printButtonIsDisabled = true;
        $scope.withoutDeliveryAddress = false;
        $scope.format = "yyyy.MM.dd";

        // Rendelési idő Datepicker
        $scope.DispatchToday = function () {
            $scope.order.DateOfDispatch = new Date();
        };

        $scope.openDispatchDatePicker = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.openedDispatchDp = true;
        };

        $scope.DatePickerOptions = {
            startingDay: 1
        };

        // Szállítási határidő DatePicker
        $scope.dtopened = [];

        $scope.openDeliveryDeadlineDatePicker = function ($event, $index) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.dtopened[$index] = true;
        };

        // A státuszok lekérése
        var getStatuses = function (statusName) {
            OrdersService.getStatuses().success(function (data) {
                $scope.StatusList = data;
                if (angular.isDefined(statusName)) {
                    for (var i = 0; i < $scope.StatusList.length; i++) {
                        if ($scope.StatusList[i].Name === statusName) {
                            $scope.order.Status = $scope.StatusList[i];
                            break;
                        }
                    }
                } else {
                    $scope.order.Status = $scope.StatusList[0];
                }
            }).error(function () {
                toasterService.errorToast("Sikertelen adatlekérés", "A státuszok lekérése sikertelen!");
            });
        };

        // Visszanyitás vagy új létrehozása
        if (orderId !== 0) {
            OrdersService.reOpenOrderById(orderId).success(function (data) {
                $scope.printButtonIsDisabled = false;
                $scope.order = data;
                $scope.setCompany();
                getStatuses($scope.order.Status.Name);
            })
                .error(function () {
                    toasterService.errorToast("Sikertelen adatlekérés", "Nem sikerült visszanyitni a megrendelést!");
                });
        } else {
            $scope.order = {};
            $scope.order.Company = {};
            $scope.order.Company.Name = "";
            $scope.order.Warehouse = "";
            $scope.order.OrderItems = [];
            $scope.DispatchToday();
            getStatuses();
        }

        // A beszállítóra való keresés 3 karaktertől
        $scope.getCompaniesByName = function () {
            if (angular.isString($scope.order.Company) && $scope.order.Company.length > 2) {
                OrdersService.getCompaniesByName($scope.order.Company).success(function (data) {
                    $scope.CompanyList = [];
                    $scope.CompanyList = data;
                })
                    .error(function () {
                        toasterService.errorToast("Sikertelen adatlekérés", "A beszállítók lekérése sikertelen!");
                    });
            } else {
                $scope.AddressDisabled = false;
            }
        };

        // A beszállító kiválasztása
        $scope.setCompany = function () {
            $scope.AddressDisabled = true;
        };

        // A raktárra való keresés 3 karaktertől
        $scope.getWarehousesByName = function () {
            if (angular.isString($scope.order.Warehouse) && $scope.order.Warehouse.length > 2) {
                OrdersService.getWarehousesByName($scope.order.Warehouse).success(function (data) {
                    $scope.WarehouseList = [];
                    $scope.WarehouseList = data;
                })
                    .error(function () {
                        toasterService.errorToast("Sikertelen adatlekérés", "A raktárak lekérése sikertelen!");
                    });
            }
        };

        $scope.saveOrder = function () {
            if (confirm("Biztosan elakarja menteni?")) {
                if (orderId === 0) {
                    OrdersService.saveOrder($scope.order).success(function () {
                        toasterService.successToast("Mentés", "Sikeres mentés!");
                        window.location.href = siteurl.orders;
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data);
                    });
                } else {
                    OrdersService.updateOrder($scope.order).success(function () {
                        toasterService.successToast("Mentés", "Sikeres mentés!");
                        window.location.href = siteurl.orders;
                    }).
                        error(function (data) {
                            toasterService.errorFromServerToast(data);
                        });
                }
            }
        };

        $scope.addRow = function () {

            $scope.orderItem = {
                Name: '',
                Quantity: '',
                QuantityType: '',
                NetPrice: '',
                Vat: ''
            };
            $scope.order.OrderItems.push($scope.orderItem);
        };

        $scope.deleteRow = function (index) {
            $scope.order.OrderItems.splice(index, 1);
        };

        $scope.createDocument = function (selected) {
            location.href = apiurl.order.saveDocument + '/' + selected.Id + "/" + $scope.withoutDeliveryAddress;
        };

        $scope.backbuttonClick = function () {
            if (confirm("Valóban vissza akar lépni?")) {
                window.location.href = siteurl.orders;
            }
        };

        // Termék keresése név alapján
        $scope.getProduct = function (productName) {
            if (productName.length < 3) {
                return [];
            }
            var searchObj = {
                ProductName: productName,
                CompanyId: $scope.order.Company.Id || 0
            };
            return OrdersService.getProductsOfSupplier(searchObj).error(function (data) {
                toasterService.errorFromServerToast(data);
            }).then(function (data) {
                if (data.data.length > 200) {
                    toasterService.warningToast("Terméknév hiba", "Kérem pontosítsa a termék nevét");
                    return [];
                } else {
                    return data.data;
                }
            });
        };

        //A kiválasztott termék értékeinek beállítása
        $scope.setProduct = function (model, index) {
            $scope.order.OrderItems[index].Name = model.CompanyProductName == null || model.CompanyProductName == '' ? model.Name : model.CompanyProductName;
            $scope.order.OrderItems[index].Id = model.Id;
            $scope.order.OrderItems[index].NetPrice = model.NetPurchasePrice;
            $scope.order.OrderItems[index].Vat = model.Vat;
            $scope.order.OrderItems[index].QuantityType = model.QuantityType;
            $scope.order.OrderItems[index].Quantity = 1;
            $scope.order.OrderItems[index].DeliveryDeadline = new Date();
            $scope.countRow(index);
        };

        // Ha átírjuk az adott termék mennyiségét akkor újra kell számolni az értékeket
        $scope.countRow = function (index) {
            $scope.order.OrderItems[index].GrossPrice = PriceCounter.GrossPrice($scope.order.OrderItems[index].NetPrice, $scope.order.OrderItems[index].Vat);
            $scope.order.OrderItems[index].NetValue = PriceCounter.NetValue($scope.order.OrderItems[index].NetPrice, $scope.order.OrderItems[index].Quantity);
            $scope.order.OrderItems[index].VatValue = PriceCounter.VatValue($scope.order.OrderItems[index].NetPrice, $scope.order.OrderItems[index].Quantity, $scope.order.OrderItems[index].Vat);
            $scope.order.OrderItems[index].GrossValue = PriceCounter.GrossValue($scope.order.OrderItems[index].NetPrice, $scope.order.OrderItems[index].Quantity, $scope.order.OrderItems[index].Vat);
        };

        //Regisztrált felhasználók letöltése
        var users = [];
        var actualUser;
        var getAllUser = function () {
            $http.get(apiurl.user.getAllUser).success(function (data) {
                var actUserId = $window.sessionStorage.getItem('userid');
                for (var i = 0; i < data.length; i++) {
                    if (data[i].UserId == actUserId) {
                        actualUser = {
                            Name: data[i].UserFirstname + ' ' + data[i].UserLastname,
                            UserName: data[i].Username,
                            Id: data[i].UserId
                        };
                    }

                    users.push({
                        Id: data[i].UserId,
                        Name: data[i].UserFirstname + ' ' + data[i].UserLastname,
                        UserName: data[i].Username
                    });
                }
                //ilyen esetben adminnal vagyok belépve
                if (angular.isUndefined($scope.actualUser) || $scope.actualUser === '') {
                    userService.getUserById(actUserId).success(function (data2) {
                        actualUser = {
                            Name: data2.UserFirstname + ' ' + data2.UserLastname,
                            UserName: data2.Username,
                            Id: data2.UserId
                        }
                        users.push(actualUser); //ez a felhasználó az admin, szóval nem kell rá ellenőrzés, hogy a lista tartalmazza-e
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data);
                    });
                }
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };

        getAllUser();

        //felhasználók közötti keresés
        $scope.searchUsers = function (nameValue) {
            if (users.length < 1) return;

            return Enumerable.From(users).Where(function (x) { return x.UserName.toLowerCase().indexOf(nameValue) != -1 || x.Name.toLowerCase().indexOf(nameValue) != -1 }).Select(function (x) { return x }).ToArray();
        };
    }

})();
