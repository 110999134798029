(function () {

    angular.module('IMasterApp')
        .controller('warehouseCtrl', warehouseCtrl)
        .controller('editWarehouseCtrl', editWarehouseCtrl)
        .filter('statusConv', statusConv);

    warehouseCtrl.$inject = ['$scope', '$http', 'siteurl', 'toasterService', 'apiurl', 'warehouseService'];
    function warehouseCtrl($scope, $http, siteurl, toasterService, apiurl, warehouseService) {
        $scope.statusFilter = true;
        $scope.my_tree = tree = {};
        $scope.treeArray = [];

        //Raktár lista generálása szűrés alapján
        function generateList() {
            $scope.treeArray = [];
            angular.forEach($scope.warehouses, function (warehouse) {
                if ($scope.statusFilter === warehouse.Status) {
                    if (warehouse.ParentId == null) {
                        $scope.treeArray.push({ label: warehouse.Name, data: { description: warehouse.Id }, children: [] });
                    } else {
                        find($scope.treeArray, warehouse.ParentId, warehouse);
                    }
                }
                if (angular.isUndefined($scope.statusFilter)) {
                    if (warehouse.ParentId == null) {
                        $scope.treeArray.push({ label: warehouse.Name, data: { description: warehouse.Id }, children: [] });
                    } else {
                        find($scope.treeArray, warehouse.ParentId, warehouse);
                    }
                }
            });
        }

        //List rendezése
        function find(array, id, warehouse) {
            if (typeof array != 'undefined') {
                for (var i = 0; i < array.length; i++) {
                    if (array[i].data.description == id) {
                        array[i].children.push({ label: warehouse.Name, data: { description: warehouse.Id }, children: [] });
                        return;
                    }

                    find(array[i].children, id, warehouse);
                }
            }
        }

        //Státusz gomb figylése
        $scope.statusButtonChanged = function () {
            generateList();
        };

        //Raktár lista lekérése
        warehouseService.getAllWarehouse().success(function (data) {
            $scope.warehouses = data;

            for (var key in $scope.warehouses) {
                $scope.warehouses[key].BillableStatusText = "";
                if ($scope.warehouses[key].BillableStatus) {
                    $scope.warehouses[key].BillableStatusText = "(Továbbszámlázás)";
                }
            }

            generateList();

            $scope.isVisibleDiv = false;
            $scope.my_tree_handler = function (branch) {
                $scope.isVisibleDiv = true;

                angular.forEach($scope.warehouses, function (warehouse) {
                    if (warehouse.Id === branch.data.description) {
                        $scope.SelectWarehouse = {};
                        $scope.SelectWarehouse.Id = warehouse.Id;
                        $scope.SelectWarehouse.Name = warehouse.Name;
                        $scope.SelectWarehouse.Status = warehouse.Status;
                        $scope.SelectWarehouse.CreationDate = warehouse.CreationDate;
                        $scope.SelectWarehouse.BillableStatusText = warehouse.BillableStatusText;
                    }
                });
            };
        });

        $scope.newWarehouse = function () {
            location.href = siteurl.editWarehouse + "/0";
        }
        $scope.editWarehouse = function (select) {
            location.href = siteurl.editWarehouse + "/" + select.Id;
        }
        $scope.deleteWarehouse = function (select) {
            if (!select.Status) {
                toasterService.warningToast("Figyelem", "A raktár már törölve van!");
            } else {
                if (confirm("A raktár törlés után inaktív állapotba kerül, így nem tud a raktárba bevételezni.\n\nBiztosan törli?")) {
                    warehouseService.deleteWarehouse(select.Id).success(function (data) {
                        warehouseService.getAllWarehouse().success(function (data) {
                            $scope.warehouses = data;
                            generateList();
                            $scope.isVisibleDiv = false;
                        });
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data);
                    });
                }

            }
        }
    }

    editWarehouseCtrl.$inject = ['$scope', '$http', 'siteurl', 'CostCenterService', 'warehouseService', 'toasterService'];
    function editWarehouseCtrl($scope, $http, siteurl, CostCenterService, warehouseService, toasterService) {
        var warehouseId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
        $scope.sites = [];

        warehouseService.getAllWarehouse().success(function (data) {
            $scope.AllWarehouse = data;
            if (warehouseId != 0) {
                warehouseService.getWarehouseById(warehouseId).success(function (data) {
                    $scope.actWarehouse = data;
                    angular.forEach($scope.AllWarehouse, function (value, key) {
                        if ($scope.actWarehouse.ParentId === value.Id) {
                            $scope.ParentWarehouse = { Id: value.Id, Name: value.Name, ParentId: value.ParentId };
                            return;
                        }
                    });

                    getOwnerCompanyAddresses();
                });
            } else {
                getOwnerCompanyAddresses();
                $scope.actWarehouse = { Name: '', Address: '', Status: true, ParentId: '' };
            }
        });

        function getOwnerCompanyAddresses() {
            warehouseService.getOwnerCompanyAddresses().success(function (response) {
                $scope.sites = response;
            });
        }

        $scope.ParentWarehouse = {
            Id: '',
            Name: '',
            ParentId: ''
        }

        $scope.checkParent = function () {
            if ($scope.ParentWarehouse.Id === $scope.actWarehouse.Id) {
                toasterService.warningToast("Figyelem", "Nem lehet a raktár saját maga szülője!");
                $scope.ParentWarehouse = {
                    Id: '',
                    Name: "",
                    ParentId: ''
                }
            }
        }

        $scope.loadCostCenter = function (query) {
            return CostCenterService.GetCostCenterByName(query);
        }

        $scope.backWarehouses = function () {
            location.href = siteurl.warehouse;
        }

        function setWarehouseAddress() {
            if ($scope.actWarehouse.OwnerCompanySiteId == null) {
                $scope.actWarehouse.Address = 'Nincs cím';
                return;
            }

            angular.forEach($scope.sites, function (site) {
                if (site.Id == $scope.actWarehouse.OwnerCompanySiteId) {
                    $scope.actWarehouse.Address = site.FullAddress;
                }
            });
        }

        $scope.saveWarehouse = function () {
            if (confirm("Valóban el akarja menteni?")) {
                $scope.actWarehouse.ParentId = $scope.ParentWarehouse.Id;
                setWarehouseAddress();
                //Update
                if (warehouseId != 0) {
                    warehouseService.updateWarehouse($scope.actWarehouse).success(function () {
                        $scope.backWarehouses();
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data);
                    });
                    //Create
                } else {
                    warehouseService.createWarehouse($scope.actWarehouse).success(function () {
                        $scope.backWarehouses();
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data);
                    });
                }
            }
        }
    }

    function statusConv() {
        return function (input) {
            return input ? "Aktív" : "Inaktív";
        }
    }

})();
